import { createRelatedObjects } from "@/models/RelatedObjects";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import SubjectClassSelectionModal from "@/views/modals/SubjectClassSelectionModal/SubjectClassSelectionModal.vue";
import ProvisionSelectionModal from "@/views/modals/ProvisionSelectionModal/ProvisionSelectionModal.vue";
import ObjectDeletionModal from "@/views/modals/ObjectDeletionModal";

import PurchaseObjectDetails from "@/api/PurchaseObjectDetails";

import FormDeclarationBuilder from "@/form-declarations/purchaseObjectDetailed";
import router from "@/router";
import { ObjectStatus, ObjectStatusStrings, LawType } from "@/models/enums";
import EventBus from "@/EventBus";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import { PurchaseObjectDetail } from '@/models';

@Component({ components: { FormBase, SubjectClassSelectionModal, ProvisionSelectionModal, ObjectDeletionModal } })
export default class PlanObjectDetailedDetails extends Vue {
    public source: PurchaseObjectDetail | null = null;

    @Prop() private objectId!: string;
    private formDeclaration = FormDeclarationBuilder(true);

    private setSource(response: PurchaseObjectDetail) {
        this.source = response;
        this.formDeclaration.header =
            "Детализированная закупка - " + response.regNumber + " - " + ObjectStatusStrings[response.status];

        this.formDeclaration.relatedObjects = createRelatedObjects(response);
    }

    public mounted() {
        this.formDeclaration.header = "Детализированная закупка";
        PurchaseObjectDetails.getById(this.objectId).then(response => this.setSource(response));
    }

    public async onAction(action: string) {
        switch (action) {
            case "edit":
                router.push(`/plan-objects/detailed/${this.objectId}/edit`);
                break;
            case "delete":
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Удаление",
                    description:
                        "При удалении объекта закупки будут удалены все связанные с ним детализированные объекты закупок и лоты, продолжить?",
                });
                if (result.isOk && result.getResult()) {
                    PurchaseObjectDetails.delete(this.objectId).then(() => router.push("/"));
                }
                break;
            case "clone":
                router.push(`/plan-objects/detailed/${this.objectId}/clone`);
                break;
            case "approve":
                PurchaseObjectDetails.setStatus(this.source!.id!, ObjectStatus.APPROVED).then(response => this.setSource(response));
                break;
            case "create-lot":
                const law = this.source!.object.law === LawType.F44 ? "44" : "223";
                router.replace(`/plan-objects/create${law}Lot?detailedObjectId=${this.objectId}`);
                break;
            case "close":
                router.back();
                break;
        }
    }
}
