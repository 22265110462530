import { Component, Prop, Vue } from "vue-property-decorator";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import { formatNumber } from "@/NumberFormatting";
import { Procedure, ProcedureParticipant } from '@/models/Procedure';
import { ProcedureParticipantStatus } from '@/models/json/IDtoProcedure';
import { Participant } from '@/models';
import Decimal from 'decimal.js';
import EventBus from '@/EventBus';
import ContractCreationModal from '@/views/modals/ContractCreationModal/ContractCreationModal';
import { ContractCreationModalArgs } from '@/views/modals/ModalArgs';
import { LawType } from '@/models/enums';

interface WrappedParticipant {
    participant: Participant
    lotId: string
    status: ProcedureParticipantStatus
    place: string
    price: Decimal
}

@Component({ components: { DataTable } })
export default class SalesResultSection extends Vue {
    @Prop() private state!: FieldState;
    @Prop() private source!: Procedure;

    private processParticipants(lotId: string) {
        const rawParticipants = 
            this.source.participants.filter(y => y.lotId === lotId)
                .sort((a, b) => a.price.comparedTo(b.price));
        const participants: WrappedParticipant[] = [];

        let place = 1;
        for (const participant of rawParticipants) {
            const actualPlace = 
                participant.status === ProcedureParticipantStatus.REQUIREMENTS_FAILED
                    ? "-"
                    : place === 1
                        ? "1 - Победитель"
                        : place === 2
                            ? "2 - Второй номер"
                            : place.toString();

            participants.push({
                participant: participant.participant,
                place: actualPlace,
                status: participant.status,
                price: participant.price,
                lotId: participant.lotId
            });

            if (participant.status !== ProcedureParticipantStatus.REQUIREMENTS_FAILED)
                place++;
        }

        return participants;
    }

    public lots = this.source.lots.map(x => ({
        subject: x.subject,
        participants: this.processParticipants(x.id!),
        expanded: true
    })).filter(x => x.participants.length > 0);

    public itemAction(item: WrappedParticipant, action: string) {
        switch (action) {
            case "create-contract":
                EventBus.callModal<ContractCreationModalArgs, null>(ContractCreationModal, {
                    tin: item.participant.tin,
                    law: this.source.law,
                    procedureId: this.source.id!,
                    lotId: item.lotId
                });
                break;
        }
    }

    public lotParticipantsConfig: IRowConfiguration<WrappedParticipant> = {
        highlight(p) { return p.status === ProcedureParticipantStatus.WINNER ? "#dff0d8" : null },
    };

    public lotParticipantsHeaders: ITableColumn<WrappedParticipant>[] = [
        {
            title: "Номер в журнале регистрации",
            getter(p, i) { return (i + 1).toString() },
            itemAlignment: "center",
            size: "auto"
        },
        {
            title: "Наименование участника",
            getter(p) { return p.participant.fullName },
            size: "minmax(min-content, 1fr)"
        },
        {
            title: "Статус допуска",
            getter(p) { return p.status === ProcedureParticipantStatus.REQUIREMENTS_FAILED 
                ? "Не соответствует требованиям"
                : "Соответствует требованиям"; },
                size: "auto"
        },
        {
            title: "Предлагаемая цена",
            getter(p) { return formatNumber(p.price) },
            itemAlignment: "center",
            size: "auto"
        },
        {
            title: "Порядковый номер",
            getter(p) { return p.place },
            itemAlignment: "center",
            size: "auto"
        },
        {
            title: (this.source.law === LawType.F223 ? "Формирование договора" : "Формирование контракта"),
            getter(p) { return "" },
            action: (p) => {
                switch (p.status) {
                    case ProcedureParticipantStatus.WINNER:
                        return {
                            id: "create-contract",
                            title: (this.source.law === LawType.F223 ? "Формирование договора" : "Формирование контракта")
                        };
                    case ProcedureParticipantStatus.WINNER_CONTRACT_PENDING:
                        return {
                            id: "cancel-contract",
                            title: (this.source.law === LawType.F223 ? "Отказ участника от заключения договора" : "Отказ участника от заключения контракта"),
                            color: "red"
                        };
                    default:
                        return null;
                }
            },
            size: "auto"
        }
    ];
}