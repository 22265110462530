import { IDtoSourceUsage } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";
import { UsedSource } from "./UsedSource";

export class SourceUsage implements IJsonFormattable<IDtoSourceUsage> {
    constructor(public source: UsedSource, public usedAmount: Decimal, public assumeFull: boolean = false) {}

    public toJson(): IDtoSourceUsage {
        return {
            source: this.source.toJson(),
            usedAmount: this.usedAmount.toString(),
            assumeFull: this.assumeFull
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSourceUsage): SourceUsage {
        return Object.assign(Object.create(SourceUsage.prototype), dto, {
            source: UsedSource.fromJson(dto.source),
            usedAmount: new Decimal(dto.usedAmount),
            assumeFull: dto.assumeFull || false
        });
    }
}
