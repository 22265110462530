import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoHistoryEntry } from './json/IDtoHistoryEntry';
import { User } from './User';
import { ObjectStatus } from './enums';

export class HistoryEntry implements IJsonFormattable<IDtoHistoryEntry> {
    constructor(
        public user: User,

        public date: Date,
        public newStatus: ObjectStatus
    ) {}
    
    public toJson(): IDtoHistoryEntry {
        return {
            newStatus: this.newStatus,
            date: this.date.toISOString(),
            user: this.user.toJson()
        };
    }
    
    public static fromJson(dto: IDtoHistoryEntry): HistoryEntry {
        return Object.assign(Object.create(HistoryEntry.prototype), {
            newStatus: dto.newStatus,
            date: new Date(dto.date),
            user: User.fromJson(dto.user)
        });
    }
}
