











































































































































































































import {
    LawType,
    ObjectStatus,
    SelectOption,
    LotContractTypeStrings,
    toSelectOptions,
    ProviderSelectionTypeStrings,
} from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Lot, Participant } from "@/models";
import Auth from '@/api/Auth';

@Component
export default class GeneralProperties extends Vue {
    @Prop() public source!: Lot;
    @Prop() public law!: LawType;
    @Prop() public objectId!: string | null;
    @Prop() public trade!: boolean;
    @Prop() public planning!: boolean;
    @Prop() public status!: ObjectStatus;
    @Prop() public singleProviderReasonOptions!: SelectOption<string>[];
    @Prop() public provider!: Participant;

    public get customer() { 
        return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName;
    }

    public get contractOptions() {
        return toSelectOptions(LotContractTypeStrings, true, "Выберите тип контракта");
    }

    public get selectionOptions() {
        return toSelectOptions(ProviderSelectionTypeStrings, true, "Выберите способ определения поставщика");
    }
}
