import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from './EventBus';
import LoginModal from './LoginModal.vue';
import "./RegisterPage.ts";
import { ReactWrapper } from 'vuera';
import { RegisterForm } from './views/Register/RegisterForm';

@Component({ components: { 'react-wrapper': ReactWrapper } })
export default class App extends Vue {
    public registerForm = RegisterForm;

    public showLoginModal() {
        EventBus.callModal(LoginModal, null);
    }
}
