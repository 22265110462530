import { Component, Prop, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";

import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, ITableAction } from "@/components/TableAbstractions";
import router from "@/router";
import { SchedulePlan, PurchasePlan } from "@/models";
import ApiPlans from "@/api/Plans";
import { ObjectStatusStrings, LawType, PurchasePlanStatusStrings, SchedulePlanStatusStrings } from '@/models/enums';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import { FilterValueType, FilterConditionType } from '@/api/http';

interface IPlanSmall {
    id: string
    planVersion: string
    period: string
    status: string
    relevance: string
}

interface IPurchasePlanSource {
    totalCount: number
    from: number
    to: number
    items: unknown[]
}

@Component({ components: { PageHeader, DataTable } })
export default class Plans extends Vue {
    public addNewPurchasePlan() {
        router.push("/plans/purchase-plans/new");
    }

    public addNewSchedulePlan223() {
        router.push("/plans/schedule-plans/new223");
    }

    public addNewSchedulePlan44() {
        router.push("/plans/schedule-plans/new44");
    }

    public actions: ITableAction[] = [
        {
            id: "open",
            title: "Просмотр"
        },
    ];

    public schedulePlans44: PaginationContext<SchedulePlan> | null = null;
    public schedulePlans223: PaginationContext<SchedulePlan> | null = null;
    public purchasePlans: PaginationContext<PurchasePlan> | null = null;

    public async mounted() {
        this.schedulePlans44 = await createPaginationContext(
            (n, sz, f) => ApiPlans.getSchedulePlans(n, sz, {
                ...f,
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: LawType.F44,
                }]
            }), 10);
        this.schedulePlans223 = await createPaginationContext(
            (n, sz, f) => ApiPlans.getSchedulePlans(n, sz, {
                ...f,
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: LawType.F223,
                }]
            }), 10);
        this.purchasePlans = await createPaginationContext(
            (n, sz, f) => ApiPlans.getPurchasePlans(n, sz, f), 10);
    }

    public purchasePlansTableHeaders: ITableColumn<PurchasePlan>[] = [
        {
            title: "Версия плана",
            getter: x => x.orderNumber,
            url: x => `/plans/purchase-plans/${x.id}`,
        },
        {
            title: "Период",
            getter: x => `${x.periodStartYear} - ${x.periodStartYear + 2}`,
        },
        {
            title: "Статус",
            getter: x => PurchasePlanStatusStrings[x.status],
        },
        {
            title: "Признак актуальности",
            getter: x => x.isActual ? "Актуален" : "Не актуален"
        },
    ];

    public schedulePlansTableHeaders: ITableColumn<SchedulePlan>[] = [
        {
            title: "Версия плана",
            getter: x => x.orderNumber,
            url: x => `/plans/schedule-plans/${x.id}`,
        },
        {
            title: "Год",
            getter: x => x.year,
        },
        {
            title: "Статус",
            getter: x => SchedulePlanStatusStrings[x.status],
        },
        {
            title: "Признак актуальности",
            getter: x => x.isActual ? "Актуален" : "Не актуален",
        },
    ];

    public onPurchasePlanAction(item: PurchasePlan, action: string) {
        if (action === "open") {
            router.push(`/plans/purchase-plans/${item.id}`);
        }
    }

    public onSchedulePlanAction(item: PurchasePlan, action: string) {
        if (action === "open") {
            router.push(`/plans/schedule-plans/${item.id}`);
        }
    }
}
