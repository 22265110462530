import { IJsonFormattable } from "./IJsonFormattable";
import { LawType, ObjectStatus } from './enums';
import { IDtoSchedulePlan } from './json/IDtoSchedulePlan';
import { HistoryEntry } from './HistoryEntry';

export class SchedulePlan implements IJsonFormattable<IDtoSchedulePlan> {
    constructor(
        public id: string,
        public orderNumber: number,
        public year: number,
        public status: ObjectStatus,
        public approvalDate: Date | null,
        public publishDate: Date | null,
        public grbsAgreementDate: Date | null,
        public isActual: boolean,

        public history: HistoryEntry[],
        public law: LawType,
    ) {}

    public toJson(): IDtoSchedulePlan {
        return {
            ...this,
            approvalDate: this.approvalDate ? this.approvalDate.toISOString() : null,
            publishDate: this.publishDate ? this.publishDate.toISOString() : null,
            grbsAgreementDate: this.grbsAgreementDate ? this.grbsAgreementDate.toISOString() : null,

            history: [] // NOT NECESSARY
        };
    }

    public static fromJson(dto: IDtoSchedulePlan): SchedulePlan {
        return Object.assign(Object.create(SchedulePlan.prototype), dto, {
            approvalDate: dto.approvalDate ? new Date(dto.approvalDate) : null,
            publishDate: dto.publishDate ? new Date(dto.publishDate) : null,
            grbsAgreementDate: dto.grbsAgreementDate ? new Date(dto.grbsAgreementDate) : null,
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : []
        });
    }
}
