import _ from "./SubjectDeclarationSelectionModal.vue";

import { ISubjectDeclarationSelectionModalArgs } from "../ModalArgs";
import { Specification } from "@/models";
import { ModalVue } from "@/view-models/ModalRequest";
import { VueConstructor } from "vue";

const __ = _ as VueConstructor<ModalVue<ISubjectDeclarationSelectionModalArgs, Specification>>;

export default __;
