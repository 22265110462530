import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoProcedure, IDtoProcedureParticipant, ProcedureParticipantStatus } from './json/IDtoProcedure';
import { Lot } from './Lot';
import { ProviderSelectionType, LawType, ObjectStatus } from './enums';
import Decimal from 'decimal.js';
import { Currency } from './enums/Currency';
import { PublishTerms } from './PublishTerms';
import { Participant } from './Participant';
import { ProcedureDocument } from './Documents';
import { RelatedObjectsItem, IRelatedObject } from './RelatedObjects';
import { HistoryEntry } from './HistoryEntry';

export class ProcedureParticipant implements IJsonFormattable<IDtoProcedureParticipant> {
    constructor(
        public price: Decimal,
        public lotId: string,
        public status: ProcedureParticipantStatus,
        public registrationDate: Date,
        public participant: Participant,
        public history: HistoryEntry[]
    ) {}

    public toJson(): IDtoProcedureParticipant {
        return {
            ...this,
            price: this.price.toString(),
            registrationDate: this.registrationDate.toISOString(),
            participant: this.participant.toJson(),
            history: [] // NOT NECESSARY
        };
    }

    public static fromJson(dto: IDtoProcedureParticipant): ProcedureParticipant {
        return Object.assign(Object.create(ProcedureParticipant.prototype), dto, {
            price: new Decimal(dto.price),
            registrationDate: new Date(dto.registrationDate),
            participant: Participant.fromJson(dto.participant)
        });
    }
}

export class Procedure implements IJsonFormattable<IDtoProcedure> {
    constructor(
        public id: string | null,
        public regNumber: string,
        public status: ObjectStatus,
        public law: LawType,

        public subject: string,
        public providerSelectionType: ProviderSelectionType,

        public marketplace: number | null,
        public documentationPreparer: number | null,
        public procedurePerformer: { equalToDocPreparer: true } | { equalToDocPreparer: false; organization: number | null },

        public lots: Lot[],
        public decisionNumber: string,
        public decisionDate: Date | null,

        public documents: ProcedureDocument[],

        public documentationOrder: {
            address: string
            room: string
            email: string
            phone: string
            fax: string
            contactPerson: string
            orderDescription: {
                default: boolean
                text: string | null
            }
        },
        public requestAcceptanceOrder: {
            address: string
            room: string
            email: string
            phone: string
            fax: string
            contactPerson: string
            copies: number
            orderDescription: {
                default: boolean
                text: string | null
            }
        },

        public paymentOrder: {
            paymentRequired: boolean
            volume: string
            currency: Currency
            taxRequired: boolean
            taxDescription: string

            account: {
                code: string
                bankId: string
                bankName: string
                bankCity: string
            }

            orderDescription: string
        },

        public publishTerms: PublishTerms | null,
        public participants: ProcedureParticipant[],

        public relatedObjects: IRelatedObject[],
        public history: HistoryEntry[]
    ) {}

    public readonly initialLots: Lot[] = [...this.lots];

    public toJson(): IDtoProcedure {
        return {
            ...this,
            // marketplace: this.marketplace.toJson(),
            // documentationPreparer: this.documentationPreparer.toJson(),
            procedurePerformer:
                this.procedurePerformer.equalToDocPreparer
                    ? { equalToDocPreparer: true }
                    : { equalToDocPreparer: false, organization: this.procedurePerformer.organization }, // organization: this.procedurePerformer.organization.toJson() },
            lots: this.lots.map(x => x.toJson()),
            documents: this.documents.map(x => x.toJson()),
            decisionDate: this.decisionDate && this.decisionDate.toISOString(),
            publishTerms: this.publishTerms ? this.publishTerms.toJson() : null,
            participants: this.participants.map(x => x.toJson()),
            history: [] // NOT NECESSARY
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoProcedure): Procedure {
        return Object.assign(Object.create(Procedure.prototype), dto, {
            // marketplace: ServiceOrganization.fromJson(dto.marketplace),
            // documentationPreparer: ServiceOrganization.fromJson(dto.documentationPreparer),
            procedurePerformer:
                dto.procedurePerformer.equalToDocPreparer
                    ? { equalToDocPreparer: true }
                    : { equalToDocPreparer: false, organization: dto.procedurePerformer.organization },
                    // : { equalToDocPreparer: false, organization: ServiceOrganization.fromJson(dto.procedurePerformer.organization) },
            lots: dto.lots.map(x => Lot.fromJson(x)),
            initialLots: dto.lots.map(x => Lot.fromJson(x)),
            documents: dto.documents.map(x => ProcedureDocument.fromJson(x)),
            decisionDate: dto.decisionDate && new Date(dto.decisionDate),
            publishTerms: dto.publishTerms ? PublishTerms.fromJson(dto.publishTerms) : null,
            participants: dto.participants.map(x => ProcedureParticipant.fromJson(x)),
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : []
        });
    }

    public static createEmpty(law: LawType | null) {
        return new Procedure(
            null,
            "",
            ObjectStatus.CREATING,
            law || LawType.F44,
            "",
            ProviderSelectionType.E_AUC,
            null,
            null,
            { equalToDocPreparer: false, organization: null },
            [],
            "",
            null!,
            [],
            { address: "", email: "", phone: "", room: "", fax: "", contactPerson: "", orderDescription: { default: true, text: null } },
            { address: "", email: "", phone: "", room: "", fax: "", contactPerson: "", copies: 123, orderDescription: { default: true, text: null } },
            {
                paymentRequired: false,
                volume: "",
                currency: null!,
                taxRequired: false,
                taxDescription: "",

                account: {
                    code: "",
                    bankId: "",
                    bankName: "",
                    bankCity: ""
                },

                orderDescription: ""
            },
            null,
            [],
            [],
            []
        );
    }
}
