













import Lots from "@/api/Lots";
import CardModal from "@/components/CardModal.vue";
import { Lot } from "@/models";
import { File, LotDocument } from "@/models/Documents";
import { LotDocumentType } from "@/models/enums/Documents";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ApiError } from '../../../api/ApiError';
import { ObjectStatus } from "@/models/enums";

@Component({ components: { CardModal } })
export default class HintModal extends Vue {
    @Prop() private prop!: { text: string; header: string };

    public reason = "";

    public ok() {
        this.$emit('close-modal', ModalResult.ok(null));
    }
}
