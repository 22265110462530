











































































import { ITableColumn } from "@/components/TableAbstractions";
import { BankAccount } from "@/models/BankAccount";
import { LawType, ObjectStatus, SelectOption } from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Lot } from "@/models";

@Component
export default class AdditionalRequirements extends Vue {
    @Prop() public source!: Lot;
    @Prop() public trade!: boolean;
}
