import { Component, Prop } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";

import FormDeclarationBuilder from "@/form-declarations/purchaseObjectDetailed";
import PurchaseObjectDetailedSpecModal from "@/views/modals/PurchaseObjectDetailedSpecModal";
import { ISpecificationCreationModalArgs } from "@/views/modals/ModalArgs";

import router from "@/router";

import PurchaseObjects from "@/api/PurchaseObjects";
import PurchaseObjectDetails from "@/api/PurchaseObjectDetails";
import { PurchaseObjectDetail, Specification } from "@/models";
import { LawType, ObjectStatus, TermsDuration } from "@/models/enums";
import EventBus from "@/EventBus";
import { RoutedModalReceiver } from "@/routed-modals";
import { IStartPriceCalculationModalArgs, ObjectActionType } from "../RoutedModalArgs";
import { mixins } from "vue-class-component";
import { IDtoPurchaseObjectDetail } from "@/models/json";
import { createRelatedObjects } from '@/models/RelatedObjects';

@Component
class RoutedModalReceiverT extends RoutedModalReceiver<IDtoPurchaseObjectDetail> {}

@Component({ components: { FormBase, PurchaseObjectDetailedSpecModal } })
export default class CreatePurchaseObjectDetailed extends mixins(RoutedModalReceiverT) {
    @Prop({ default: null }) private objectId!: string | null;
    @Prop({ default: null }) private detailedObjectId!: string | null;
    @Prop({ default: false }) private clone!: boolean;

    public source: PurchaseObjectDetail = null!;

    public formDeclaration = FormDeclarationBuilder(false);

    public mounted() {
        this.formDeclaration.actions = this.formDeclaration.actions;

        if (this.routedResult) {
            this.source = PurchaseObjectDetail.fromJson(this.routedResult);
        } else {
            if (this.objectId) {
                PurchaseObjects.getById(this.objectId).then(response => {
                    this.source = PurchaseObjectDetail.createEmpty(response);
                });
            } else {
                PurchaseObjectDetails.getById(this.detailedObjectId!).then(response => {
                    this.source = response;
                    this.formDeclaration.header =
                        (this.clone ? "Копирование" : "Редактирование") +
                        " детализированного объекта закупки";

                    this.formDeclaration.relatedObjects = createRelatedObjects(response);
                });
            }
        }
    }

    public async pickFormValue(property: string, value: undefined | { action: string; item: Specification }) {
        switch (property) {
            case "specification":
                switch (
                    value ? value.action : "edit" // todo: typesafe
                ) {
                    case "edit":
                        const d =  this.source.object.terms.beginDate!;
                        d.setMonth(d.getMonth() + 1);
                        d.setDate(0);
                        const prop: ISpecificationCreationModalArgs = {
                            purchaseDate: this.source.object.law === LawType.F223 ? null : d,
                            needActionClass: this.source.object.law === LawType.F223,
                            existingSpecification: value ? value.item : null,
                            subjectClass: this.source.object.subjectClass!,
                            isPerItemPurchase: this.source.isPerItemPurchase,
                            canAutoAddStage: this.source.object.terms.duration === TermsDuration.ONE_TIME,
                            law: this.source.object.law
                        };

                        const result = await EventBus.callModal<ISpecificationCreationModalArgs, Specification>(
                            PurchaseObjectDetailedSpecModal,
                            prop,
                        );

                        if (result.isOk && !this.source.specifications.includes(result.getResult()))
                            this.source.specifications = [...this.source.specifications, result.getResult()];

                        break;
                    case "calculate":
                        const spec = value!.item as Specification;
                        router.pushRoutedModal<IStartPriceCalculationModalArgs>("/start-price-calc", {
                            object: this.source.toJson(),
                            specIndex: this.source.specifications.indexOf(spec),
                            specification: spec.toJson(),
                            actionType: this.clone
                                ? ObjectActionType.CLONE
                                : this.detailedObjectId
                                ? ObjectActionType.EDIT
                                : ObjectActionType.CREATE,
                        });
                        break;
                }
        }
    }

    public onAction(action: string) {
        switch (action) {
            case "save":
                this.source!.status = ObjectStatus.CREATING;

                for (const s of this.source.sourceUsage) {
                    if (s.assumeFull && s.usedAmount.eq(0)) {
                        s.assumeFull = false;
                        s.usedAmount = this.source.totalStartPrice;
                    }
                }

                if (this.clone || this.objectId) {
                    PurchaseObjectDetails.create(this.source).then(response =>
                        router.replace(`/plan-objects/detailed/${response.id}`),
                    );
                } else {
                    PurchaseObjectDetails.update(this.source).then(response =>
                        router.replace(`/plan-objects/detailed/${response.id}`),
                    );
                }
                break;
            case "close":
                router.back();
                break;
        }
    }
}
