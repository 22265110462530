import { EnumStrings } from "@/models/enums/EnumStrings";

export const enum TermsDuration {
    ONE_TIME = "ONE_TIME",
    REPEATING = "REPEATING",
}

export const TermsDurationStrings: EnumStrings = {
    [TermsDuration.ONE_TIME]: "Единовременно",
    [TermsDuration.REPEATING]: "Периодически",
};

export const enum TermsPeriod {
    UNDEFINED = "UNDEFINED",
    YEAR = "YEAR",
    QUARTER = "QUARTER",
    MONTH = "MONTH",
    WEEK = "WEEK",
    DAY = "DAY",
    WORKDAY = "WORKDAY",
}

export const TermsPeriodStrings: EnumStrings = {
    [TermsPeriod.UNDEFINED]: "Не определен",
    [TermsPeriod.YEAR]: "Год",
    [TermsPeriod.QUARTER]: "Квартал",
    [TermsPeriod.MONTH]: "Месяц",
    [TermsPeriod.WEEK]: "Неделя",
    [TermsPeriod.DAY]: "День",
    [TermsPeriod.WORKDAY]: "Рабочий день",
};
