import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { TableSectionData } from "@/views/FormAbstractions";
import { isThisHour } from 'date-fns';

@Component({ components: { DataTable } })
export default class TableSection extends Vue {
    @Prop() private data!: TableSectionData<unknown, unknown>;
    @Prop() private source!: unknown;

    public realDataset: unknown[] = [];

    public created() {
        const d = this.data.getter(this.source);
        if (d instanceof Array) {
            this.realDataset = d;
        } else {
            d.then(x => this.realDataset = x).catch(() => {});
        }
    }
}
