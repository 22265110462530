




















import Lots from "@/api/Lots";
import CardModal from "@/components/CardModal.vue";
import { Lot } from "@/models";
import { File, LotDocument } from "@/models/Documents";
import { LotDocumentType } from "@/models/enums/Documents";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { Component, Prop } from "vue-property-decorator";
import { ApiError } from '../../../api/ApiError';
import { ObjectStatus } from "@/models/enums";

@Component({ components: { CardModal } })
export default class LotGrbsConfirmModal extends ModalVue<string, Lot> {
    @Prop() private prop!: string;

    public confirm() {
        const file = (this.$refs.file as HTMLInputElement).files![0];
        const id = this.prop;

        const finish = (result: Lot | ApiError) => {
            this.$emit("close-modal", ModalResult.ok(result));
        };

        if (file) {
            Lots.sendToGrbs(
                id,
                new LotDocument(new File(null!, file.size, file.name, ""), LotDocumentType.PURCHASE_APPROVE_REQUEST, false),
            ).then(finish, finish);
        } else {
            Lots.setStatus(id, ObjectStatus.GRBS_AGREEMENT_PENDING).then(finish, finish);
        }
    }
}
