import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { IColumn } from "@/components/vue/form-table/FormFields";
import { ITableColumn, SingleSelection } from "@/components/TableAbstractions";
import { PaginationContext, createPaginationContext } from "@/api/Pagination";

import { ModalVue, ModalResult } from "@/view-models/ModalRequest";

import { formatNumber } from "@/NumberFormatting";
import FinancialSources from "@/api/FinancialSources";
import { FinancialActionSource, FinancialSource, UsedSource } from "@/models";
import { FinancialSourceType, createYearSelection, LawType } from "@/models/enums";
import { Decimal } from "decimal.js";
import { FilterValueType, FilterConditionType } from '@/api/http';

interface ISourceSelection {
    type: FinancialSourceType
    amount: Decimal
    year: number
    selectedSource: SingleSelection<FinancialSource>
    existingSelection: UsedSource | null
}

@Component({ components: { CardModal, TreeView, FormTable } })
export default class ProvisionSelectionModal extends ModalVue<UsedSource | LawType, UsedSource> {
    @Prop() private prop!: UsedSource | LawType;

    private createSelection(): ISourceSelection {
        let year = 2019;
        let type = FinancialSourceType.FIN_ACTIONS;

        const reload = () => {
            if (this.context === null) return;

            this.context.filter = {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: year,
                }],
                type: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: type,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: this.lawType,
                }]
            };

            this.context.load(0);
        };

        return {
            get type() { return type },
            set type(t) {
                if (type !== t) {
                    type = t;
                    reload();
                }
            },
            amount: new Decimal(0),
            get year() { return year },
            set year(y) {
                if (year !== y) {
                    year = y;
                    reload();
                }
            },
            selectedSource: { tag: "SINGLE", item: null },
            existingSelection: null,
        };
    }

    private readonly selection: ISourceSelection = this.createSelection();
    
    public get headers() {
        switch (this.selection.type) {
            case FinancialSourceType.FIN_ACTIONS:
                return this.finActionsHeaders;
            default:
                return this.finActionsHeaders; // lul
        }
    }

    private finActionsHeaders: ITableColumn<FinancialActionSource>[] = [
        {
            title: "КВР",
            getter(row) {
                return row.expenseKind;
            },
        },
        {
            title: "КОСГУ",
            getter(row) {
                return row.govOperationClass;
            },
        },
        {
            title: "КВФО",
            getter(row) {
                return row.provisionCode;
            },
        },
        {
            title: "Сумма лимита",
            getter(row) {
                return formatNumber(row.total);
            },
        },
        {
            title: "Запланировано",
            getter(row) {
                return formatNumber(row.used);
            },
        },
        {
            title: "Остаток",
            getter(row) {
                return formatNumber(row.total.sub(row.used));
            },
        },
    ];

    public formColumns: IColumn<ISourceSelection>[] = [
        {
            rows: [
                {
                    title: "Тип финансирования",
                    required: true,
                    type: "RADIO_SELECT",
                    selectOptions: [
                        { key: FinancialSourceType.LIMIT, desc: "Бюджет города Москвы", disabled: true },
                        { key: "?", desc: "Федеральные средства", disabled: true },
                        { key: FinancialSourceType.FIN_ACTIONS, desc: "ПФХД" },
                        { key: FinancialSourceType.OWN_FUNDS, desc: "Собственные средства", disabled: true },
                    ],
                    groupName: "financialSourceType",

                    getter(src) {
                        return src.type;
                    },
                    setter(src, type: FinancialSourceType) {
                        src.type = type;
                    },
                },
                {
                    title: "Сумма закупки",
                    type: "DECIMAL",
                    required: true,

                    getter(src) {
                        return src.amount;
                    },
                    setter(src, val: Decimal) {
                        src.amount = val;
                    },
                },
            ],
        },
        {
            rows: [
                {
                    title: "Год",
                    required: true,
                    type: "SELECT",
                    readonly: p => p.existingSelection !== null,

                    selectOptions: createYearSelection(false, 2015),

                    getter(src) {
                        return src.year;
                    },
                    setter(src, year: number) {
                        src.year = year;
                    },
                },
                {
                    title: "Текущий источник",
                    type: "TEXT",
                    editable: false,
                    required: true,

                    getter(src) {
                        return src.selectedSource.item ? src.selectedSource.item.format(true) : "";
                    },
                    setter() {},
                },
            ],
        },
    ];

    private lawType!: LawType;
    public context: PaginationContext<FinancialSource> | null = null;

    public async mounted() {
        if (this.prop instanceof UsedSource) {
            this.selection.type = this.prop.source.type;
            this.selection.amount = this.prop.amount;
            this.selection.selectedSource = { tag: "SINGLE", item: this.prop.source };
            this.selection.existingSelection = this.prop;
            this.selection.year = this.prop.source.year;

            this.lawType = 
                this.prop.source instanceof FinancialActionSource
                    ? this.prop.source.law
                    : LawType.F44;

        } else {
            this.lawType = this.prop;
        }

        this.context = await createPaginationContext(
            (n, sz, f) => FinancialSources.get(n, sz, f), 10, {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: this.selection.year,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: this.lawType,
                }]
            }
        );
    }

    public selectItemAndExit() {
        if (!this.isOk) return;

        let sel = this.selection.existingSelection;

        if (sel) {
            sel.source = this.selection.selectedSource.item!;
            sel.amount = this.selection.amount;
        } else {
            sel = new UsedSource(this.selection.selectedSource.item!, this.selection.amount);
        }

        this.$emit("close-modal", ModalResult.ok(sel));
    }

    private get isOk(): boolean {
        return this.selection.amount &&
            this.selection.amount.gt(0) &&
            this.selection.selectedSource.item !== null;
    }
}
