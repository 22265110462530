import { setHours, setMinutes, addDays } from "date-fns";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import CardModal from "@/components/CardModal.vue";
import { PublishTerms } from "@/models/PublishTerms";
import { DateTimeRange } from "@/models/DateTimeRange";
import { ProviderSelectionType } from "@/models/enums";

const at0900 = () => {
    return setMinutes(setHours(new Date(), 9), 0);
};

const emptyTerms = (terms: PublishTerms | null, type: ProviderSelectionType) => {
    if (terms)
        return new PublishTerms(
            terms.publishDate,
            new DateTimeRange(terms.requestAcceptRange.from, terms.requestAcceptRange.to),
            terms.documentExplainationRange
                ? new DateTimeRange(terms.documentExplainationRange.from, terms.documentExplainationRange.to)
                : null,
            terms.firstRequestConsiderationFinishDate,
            terms.secondRequestConsiderationFinishDate,
            terms.executionDate,
            terms.finalOffersDate,
        );

    switch (type) {
        case ProviderSelectionType.E_AUC:
            return new PublishTerms(
                new Date(),
                new DateTimeRange(at0900(), at0900()),
                new DateTimeRange(new Date(), new Date()),
                new Date(),
                null,
                new Date(),
                null,
            );
        case ProviderSelectionType.E_EXAM:
            return new PublishTerms(
                new Date(),
                new DateTimeRange(at0900(), at0900()),
                new DateTimeRange(new Date(), new Date()),
                new Date(),
                new Date(),
                null,
                new Date(),
            );
        default:
            return new PublishTerms(new Date(), new DateTimeRange(at0900(), at0900()));
    }
};

@Component({ components: { CardModal } })
export default class ProcedurePublishDateModal extends ModalVue<
    { publishTerms: PublishTerms | null; type: ProviderSelectionType },
    PublishTerms
> {
    @Prop() public prop!: { publishTerms: PublishTerms | null; type: ProviderSelectionType };

    public get type() {
        return this.prop.type;
    }

    public fill = false;

    public terms = emptyTerms(this.prop.publishTerms!, this.type);

    public created() {
        if (!this.prop.publishTerms) this.setPublishDate(this.terms.publishDate);
    }

    public get isOk() {
        return true;
    }

    public get preciseFirstFinish() {
        return this.type === ProviderSelectionType.E_EXAM;
    }

    public get needDocExplanation() {
        return this.type === ProviderSelectionType.E_EXAM || this.type === ProviderSelectionType.E_AUC;
    }

    public get needFirstReq() {
        return this.type === ProviderSelectionType.E_EXAM || this.type === ProviderSelectionType.E_AUC;
    }

    public get needSecondReq() {
        return this.type === ProviderSelectionType.E_EXAM;
    }

    public get needFinalOffers() {
        return this.type === ProviderSelectionType.E_EXAM;
    }

    public get needAuc() {
        return this.type === ProviderSelectionType.E_AUC;
    }

    @Watch("terms.publishDate") private setPublishDate(d: Date) {
        d = setMinutes(setHours(d, 9), 0);

        this.terms.requestAcceptRange.from = d;
        this.terms.requestAcceptRange.to = d;

        if (this.needDocExplanation) {
            this.terms.documentExplainationRange!.from = d;
            this.terms.documentExplainationRange!.to =
                this.type === ProviderSelectionType.E_EXAM ? addDays(d, 13) : addDays(d, 2);
        }
    }

    @Watch("terms.requestAcceptRange.to") private setReqAcceptRangeEnd(d: Date) {
        d = setMinutes(setHours(d, 9), 0);

        if (this.needFirstReq) this.terms.firstRequestConsiderationFinishDate = d;
    }

    @Watch("terms.firstRequestConsiderationFinishDate") private setFirstReqDate(d: Date) {
        d = setMinutes(setHours(d, 9), 0);

        if (this.needAuc) this.terms.executionDate = addDays(d, 1);
        if (this.needFinalOffers) this.terms.finalOffersDate = addDays(d, 2);
        if (this.needSecondReq) this.terms.secondRequestConsiderationFinishDate = addDays(d, 2);
    }

    public selectItemAndExit() {
        if (this.isOk) {
            this.$emit("close-modal", ModalResult.ok(this.terms));
        }
    }
}
