import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import DataTable from "@/components/vue/DataTable.vue";

import PurchaseObjectDetails from "@/api/PurchaseObjectDetails";
import { PurchaseObjectDetail } from "@/models";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { ISubjectDeclarationSelectionModalArgs } from "../ModalArgs";
import { ITableColumn, MultiSelection } from "@/components/TableAbstractions";
import Decimal from "decimal.js";
import { formatNumber } from "@/NumberFormatting";
import { LawType, ObjectStatus } from '@/models/enums';
import { FilterValueType, FilterConditionType } from '@/api/http';

@Component({ components: { CardModal, DataTable } })
export default class PurchaseObjectDetailSelectionModal extends ModalVue<LawType, PurchaseObjectDetail[]> {
    @Prop() private prop!: LawType;

    public selected: MultiSelection<PurchaseObjectDetail> = { items: [], tag: "MULTI" };

    public mounted() {
        PurchaseObjectDetails.get(0, 20, {
            law: [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: this.prop,
            }], 
            lotId: [{
                type: FilterValueType.GUID,
                conditionType: FilterConditionType.EQUAL,
                guid: null
            }], 
            status: [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.NOT_EQUAL,
                string: ObjectStatus.CREATING
            }]
        }).then(response => (this.entries = response.items));
    }

    public entries: PurchaseObjectDetail[] = [];

    public headers: ITableColumn<PurchaseObjectDetail>[] = [
        {
            title: "Реестровый номер",
            getter: v => v.regNumber,
        },
        {
            title: "КПГЗ",
            getter: v => v.object.subjectClass!.toString(),
        },
        {
            title: "Описание",
            getter: v => v.subject,
        },
        {
            title: "Код финансирования",
            getter: v => v.object.financialSources.map(x => x.source.format(false)).join("; "),
        },
        {
            title: "Стоимость",
            getter: v => formatNumber(v.specifications.reduce((p, x) => p.add(x.startPrice!.multiplied), new Decimal(0))),
        },
    ];

    public selectItemAndExit() {
        this.$emit("close-modal", ModalResult.ok(this.selected.items));
    }

    public openInfo() {
        const routeData = this.$router.resolve("/reference-info/subject-declarations");
        window.open(routeData.href, "_blank");
    }
}
