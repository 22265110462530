










































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { StartPriceMethodStrings, StartPriceMethod } from '@/models/enums/StartPriceMethod';
import { StartPrice, Specification, Participant, PurchaseObjectDetail } from '@/models';
import { SingleSelection, EditType, IRowConfiguration, ITableColumn } from '@/components/TableAbstractions';
import { formatNumber } from '@/NumberFormatting';
import Decimal from 'decimal.js';
import Tariffs from '@/api/Tariffs';
import EventBus from '@/EventBus';
import CalculationImbalanceModal from './CalculationImbalanceModal.vue';
import { Tariff } from '@/models/Tariff';
import { SelectOption } from '@/models/enums';

interface Method {
    id: StartPriceMethod
    applicability: "APPLICABLE" | "NOT_APPLICABLE"
    optRatio: "APPLICABLE" | "NOT_APPLICABLE"
    externalCalcStart?: () => void
    externalCalcResult?: () => Decimal | null
}

type TariffArray = Array<{ supplier: Participant; items: Array<{ zone: Tariff["zone"]; items: Tariff[] }> }>;
type TariffSelectOptions = SelectOption<{ supplier: Participant; opts: SelectOption<Tariff[]>[] }>[];

@Component({components: {}})
export default class CommonPage extends Vue {
    @Prop() public detail!: PurchaseObjectDetail;
    @Prop() public specification!: Specification;
    @Prop() public startPrice!: StartPrice;
    @Prop() public marketPricesResult!: Decimal | null;

    public resetStartPrice(sel: Method) {
        if (this.methodSelection.item !== sel) {
            this.startPrice.total = new Decimal(0);
            this.startPrice.ratio = new Decimal(1);
        }

        this.startPrice.method = sel.id;
    }

    public created() {
        if (!this.specification.subjectDeclaration) {
            return;
        }

        Tariffs.getForSubjectDeclaration(this.specification.subjectDeclaration.id)
            .then(this.processTariffs);
    }

    private processTariffs(tariffs: Tariff[]) {
        const specWithSupplier = this.detail.specifications.filter(x => x.startPrice && x.startPrice.supplier)[0];
        const supplier = specWithSupplier && specWithSupplier.startPrice && specWithSupplier.startPrice.supplier || null;

        this.tariffs =
            tariffs.reduce((groups, tariff) => {
                let existing = groups.find(x => x.supplier.id === tariff.supplier.id);

                if (!existing) {
                    existing = { supplier: tariff.supplier, items: [] };
                    groups.push(existing);
                }

                let innerExisting = existing.items.find(x => x.zone.id === tariff.zone.id);
                if (!innerExisting) {
                    innerExisting = { zone: tariff.zone, items: [] };
                    existing.items.push(innerExisting);
                }

                innerExisting.items.push(tariff);

                return groups;
            },
            [] as TariffArray)
            .filter(x => !supplier || x.supplier.id === supplier.id)
            .map(x => ({
                key: {
                    supplier: x.supplier,
                    opts: x.items.map(y => ({
                        key: y.items,
                        desc: y.zone.name
                    }))
                },
                desc: x.supplier.shortName
            }));

        if (supplier && this.tariffs.length === 0) {
            this.tariffs = [{
                key: {
                    supplier,
                    opts: []
                },
                desc: supplier.shortName
            }];
        }

        if (supplier) {
            this.fixedSupplier = true;
            this.selectedTariffGroup = this.tariffs[0].key || null;
        }
    }

    private createMethodsHeaders(): ITableColumn<Method>[] {
        const that = this;

        return [
            {
                title: "Метод",
                getter: (v) => StartPriceMethodStrings[v.id],
                slot: "methodName",
                url: (v) => v.externalCalcStart ? v.externalCalcStart : null
            },
            {
                title: "НМЦ Рассчитанная",
                getter: (v) => {
                    if (v.applicability === "NOT_APPLICABLE")
                        return "не применим";

                    if (v.externalCalcResult)
                        return v.externalCalcResult() || new Decimal(0);

                    if (this.methodSelection.item === v) {
                        return this.startPrice.total;
                    } else {
                        return new Decimal(0);
                    }
                },
                setter: (_, d: Decimal) => this.startPrice.total = d,
                editable: v => v.applicability === "APPLICABLE" && !v.externalCalcResult,
                type: EditType.DECIMAL,
                precision: 2,
                precisionMode: "INITIAL",
                disabled: v => !this.methodSelection.item || this.methodSelection.item.id !== v.id,
            },
            {
                title: "Коэффициент оптимизации заказчика",
                getter: (v) => {
                    if (v.applicability === "APPLICABLE" && v.optRatio === "APPLICABLE") {
                        if (this.methodSelection.item === v) {
                            return this.startPrice.ratio;
                        } else {
                            return new Decimal(1);
                        }
                    } else {
                        return "не применим";
                    }
                },
                setter: (_, d: Decimal) => this.startPrice.ratio = d,
                editable: v => v.applicability === "APPLICABLE" && v.optRatio === "APPLICABLE",
                disabled: v => !this.methodSelection.item || this.methodSelection.item.id !== v.id,
                precision: 2,
                maxValue: new Decimal(1),
                precisionMode: "INITIAL",
                type: EditType.DECIMAL,
            },
            {
                title: "НМЦ Итоговая",
                getter: v => formatNumber(this.calculateResultPrice(v).mul(this.startPrice.ratio))
            },
        ];
    }

    private calculateResultPrice(v: Method) {
        const result = v.applicability === "APPLICABLE" && this.methodSelection.item && this.methodSelection.item.id === v.id
            ? v.externalCalcResult
                ? v.externalCalcResult() && v.externalCalcResult() || new Decimal(0)
                : this.startPrice.total
            : new Decimal(0);

        return result.toNearest(0.01, Decimal.ROUND_DOWN);
    }

    private createSelectedMethodHeaders(): ITableColumn<SingleSelection<Method>>[] {
        const that = this;

        return [
            {
                title: "Метод",
                getter: m => m.item && StartPriceMethodStrings[m.item.id]
            },
            {
                get title() {
                    return that.selectedTax && that.selectedTax > 0 ? "НМЦ (с учетом НДС)" : "НМЦ";
                },
                getter: m => m.item && formatNumber(this.calculateResultPrice(m.item))
            },
            {
                title: "Ставка НДС",
                getter: _ => this.selectedTax,
                setter: (_, v: number) => this.selectedTax = v,
                editable: true,
                type: EditType.SELECT,

                selectOptions: [
                    { key: null, desc: "Выберите ставку НДС" },
                    { key: 0, desc: "0%" },
                    { key: 10, desc: "10%" },
                    { key: 20, desc: "20%" }
                ],
            },
            {
                title: "Сумма НДС",
                getter: (m: SingleSelection<Method>) => {
                    const tax = this.selectedTax;
                    const calculated = m.item && tax && this.calculateResultPrice(m.item);
                    return calculated && tax
                        ? formatNumber(calculated.mul(tax).div(100))
                        : "0,00";
                },
            },
            {
                title: "Валюта",
                getter: _ => "Российский рубль"
            },
            {
                title: "Ед. изм.",
                getter: _ => this.specification.measurementUnit?.name ?? "",
            },
        ];
    }

    private createMethods(): Method[] {
        const that = this;

        return [
            {
                id: StartPriceMethod.MARKET_PRICES,
                applicability: "APPLICABLE",
                externalCalcStart: () => this.$emit("switch-page", "MARKET_PRICES"),
                externalCalcResult: () => this.marketPricesResult,
                optRatio: "APPLICABLE"
            },
            {
                id: StartPriceMethod.CONTRACT_REGISTRY_PRICES,
                applicability: "NOT_APPLICABLE",
                optRatio: "NOT_APPLICABLE"
            },
            {
                id: StartPriceMethod.STANDARD,
                applicability: "NOT_APPLICABLE",
                optRatio: "NOT_APPLICABLE"
            },
            {
                id: StartPriceMethod.TARIFF,
                get applicability() { return that.tariffs.length ? "APPLICABLE" : "NOT_APPLICABLE" },
                externalCalcResult: () => this.tariffResult,
                optRatio: "NOT_APPLICABLE"
            },
            {
                id: StartPriceMethod.RELATIVE_RATE,
                applicability: "NOT_APPLICABLE",
                optRatio: "NOT_APPLICABLE"
            },
            {
                id: StartPriceMethod.ESTIMATE,
                applicability: "APPLICABLE",
                optRatio: "APPLICABLE"
            },
            {
                id: StartPriceMethod.COST_BASED,
                applicability: "APPLICABLE",
                optRatio: "APPLICABLE"
            },
            {
                id: StartPriceMethod.ALTERNATIVE_PRICES,
                applicability: "APPLICABLE",
                optRatio: "APPLICABLE"
            },
            {
                id: StartPriceMethod.PARAMETRIC,
                applicability: "APPLICABLE",
                optRatio: "APPLICABLE"
            },
            {
                id: StartPriceMethod.OTHER,
                applicability: "APPLICABLE",
                optRatio: "APPLICABLE"
            },
        ];
    }

    public tariffs: TariffSelectOptions = [];
    public fixedSupplier = false;
    public selectedTariffGroup: { supplier: Participant; opts: SelectOption<Tariff[]>[] } | null = null;
    public selectedZonedTariffGroup: Tariff[] | null = null;

    public get tariffResult() {
        return this.selectedZonedTariffGroup &&
            this.selectedZonedTariffGroup.reduce(
                (r, v) => v.priceWithTax.add(r),
                new Decimal(0))
            .mul(this.specification.volume);
    }

    public methods = this.createMethods();
    public methodsHeaders = this.createMethodsHeaders();
    public methodSelection: SingleSelection<Method> = { tag: "SINGLE", item: this.methods.find(x => x.id === this.startPrice.method) || null };
    public methodsConfig: IRowConfiguration<Method> = {
        selection: {
            showSelectionColumn: true,
            predicate: i => i.applicability === 'APPLICABLE' &&
                (!i.externalCalcStart || !i.externalCalcResult || !!i.externalCalcResult())
        }
    };

    public selectedMethodDataset = [this.methodSelection];
    public selectedMethodHeaders = this.createSelectedMethodHeaders();

    public selectedTax: number | null = null;

    public get reasonRequired() {
        return !!this.methodSelection.item &&
            (this.methodSelection.item.id === StartPriceMethod.OTHER ||
                this.methodSelection.item.id === StartPriceMethod.COST_BASED);
    }

    public get reasonDesc() {
        return this.methodSelection.item && this.methodSelection.item.id === StartPriceMethod.OTHER
            ? "Обоснование выбора иного метода расчета:"
            : "Обоснование выбора затратного метода расчета:";
    }

    public async saveCalculation() {
        if (!this.methodSelection.item) return;
        if (this.selectedTax === null) return;

        this.startPrice.total = this.calculateResultPrice(this.methodSelection.item);
        if (this.startPrice.multiplied.eq(0) || this.startPrice.multiplied.isNaN()) {
            alert("Не указана сумма");
            return;
        }

        this.startPrice.perItem = this.startPrice.multiplied.div(this.specification.volume).toNearest(0.01, Decimal.ROUND_DOWN);

        if (!this.startPrice.perItem.mul(this.specification.volume).eq(this.startPrice.multiplied)) {
            const r = await EventBus.callModal(CalculationImbalanceModal, {
                price: this.startPrice,
                volume: this.specification.volume
            });

            if (r.isOk) {
                if (!r.getResult()) {
                    if (!this.startPrice.perItem.mul(this.specification.volume).eq(this.startPrice.multiplied)) return;
                    if (this.startPrice.multiplied.eq(0)) return;
                }
            } else return;
        }

        this.startPrice.taxPercent = new Decimal(this.selectedTax);

        if (this.methodSelection.item.id === StartPriceMethod.TARIFF) {
            this.startPrice.supplier = this.selectedTariffGroup!.supplier;
        }

        this.$emit("back", true);
    }

    public cancel() {
        this.$emit("back", false);
    }
}
