import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, IRowConfiguration, MultiSelection } from "@/components/TableAbstractions";
import EventBus from '@/EventBus';
import { IFieldProps, FieldState } from '@/components/vue/form-table/FieldBase';
import { SimpleRow } from '@/components/vue/form-table/FormFields';
import { File } from '@/models/Documents';
import { VueClass } from 'vue-class-component/lib/declarations';
import { EnumStrings } from '@/models/enums';

export declare interface SourcedDocument<T, TDocumentType, TDoc extends { type: TDocumentType; file: File }> {
    source: T
    document: TDoc
}

interface IdentifiedDoc<T, TDocumentType, TDoc extends { type: TDocumentType; file: File }> extends SourcedDocument<T, TDocumentType, TDoc> {
    id: string
}

export declare interface FileTableInfo<T, TDocumentType, TDocument extends { type: TDocumentType; file: File }> {
    groups: Array<{ source: T; documents: TDocument[] }>
    additionalHeadersBefore?: ITableColumn<SourcedDocument<T, TDocumentType, TDocument>>[]
    additionalHeadersAfter?: ITableColumn<SourcedDocument<T, TDocumentType, TDocument>>[]
    creationModal?: VueClass<Vue>
    creationModalProp?: unknown
    strings: EnumStrings | ((_: TDocument, __: T) => string)
    itemEnabled?(_: TDocument, __: T): boolean
}

@Component({ components: { DataTable } })
export default class FileTableRow extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<unknown>>;

    public get state() { return this.props.state }
    public get editable() {
        if (this.state === FieldState.READONLY) return false;

        switch (typeof this.props.row.readonly) {
            case "boolean": return !this.props.row.readonly;
            case "function": return !this.props.row.readonly(this.props.source);
            default: return true;
        }
    }

    public selection: MultiSelection<IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>> = { tag: "MULTI", items: [] };

    private get info() { return this.props.row.getter(this.props.source) as FileTableInfo<unknown, unknown, { type: unknown; file: File }>}

    private get additionalHeadersBefore(): ITableColumn<IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>>[] {
        return this.info.additionalHeadersBefore || [];
    }

    private get additionalHeadersAfter(): ITableColumn<IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>>[] {
        return this.info.additionalHeadersAfter || [];
    }

    public headers: ITableColumn<IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>>[] = [
        ...this.additionalHeadersBefore,
        {
            title: "Тип документа",
            getter: (r) => typeof this.info.strings === "function"
                ? this.info.strings(r.document, r.source)
                : this.info.strings[r.document.type as string]
        },
        {
            title: "Примечание",
            getter(r) {
                return r.document.file.comment;
            },
        },
        {
            title: "Документ",
            getter(r) {
                return r.document.file.fileName;
            },
        },
        ...this.additionalHeadersAfter,
        {
            title: "Размер",
            getter(r) {
                return r.document.file.length.toString();
            },
        },
    ];

    // private get docs(): IDocument[] {
    //     const s = this.props.row.getter(this.props.source) as FileTableInfo<any>
    //     return s.groups.flatMap(x => x.documents)
    // }

    public get identifiedSource(): IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>[] {
        return this.info.groups.flatMap(x => x.documents.map(y => ({ document: y, id: y.file.fileName, source: x.source })));
    }

    public tableConfig: IRowConfiguration<IdentifiedDoc<unknown, unknown, { type: unknown; file: File }>> = {
        selection: {
            showSelectionColumn: false,
            background: this.editable ? "#ccc" : undefined
        },
        showJoinedRow: "last",
        isRawJoinedRow: () => true,
    };

    public get totalSize() {
        return this.identifiedSource.reduce((p, v) => p + v.document.file.length, 0).toString();
    }

    public async addDocument() {
        if (!this.info.creationModal) return;

        const result = await EventBus.callModal<unknown, { type: unknown; file: File }>(this.info.creationModal, this.info.creationModalProp || null);

        if (result.isOk) {
            if (this.info.groups.length !== 1) throw new Error("Can not add document to grouped FileTable");
            this.info.groups[0].documents.push(result.getResult());
        }
    }

    public deleteDocuments() {
        if (this.info.groups.length !== 1) throw new Error("Can not add document to grouped FileTable");

        for (const doc of this.selection.items) {
            const index = this.info.groups[0].documents.findIndex(x => x === doc.document);
            this.info.groups[0].documents.splice(index, 1);
        }

        this.selection.items = [];
    }

    public get canDelete() {
        return this.selection.items.length > 0 &&
            (!this.info.itemEnabled || this.selection.items.every(x => this.info.itemEnabled!(x.document, x.source)));
    }
}
