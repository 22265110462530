import { Component, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { ModalVue } from '@/view-models/ModalRequest';
import EventBus from '@/EventBus';
import ParticipantSelectionModal from '../ParticipantSelectionModal';
import { Participant } from '@/models';
import router from '@/router';
import { ContractCreationModalArgs } from '../ModalArgs';
import Contracts from '@/api/Contracts';

@Component({ components: { CardModal, TreeView, FormTable, DataTable } })
export default class ContractCreationModal extends ModalVue<ContractCreationModalArgs, null> {
    @Prop() private prop!: ContractCreationModalArgs;

    public participant: Participant | null = null;
    public creating = false;
    
    public async selectParticipant() {
        const result = await EventBus.callModal<string, Participant>(ParticipantSelectionModal, this.prop.tin);
        if (result.isOk) {
            this.participant = result.getResult();
        }
    }

    public close() {
        if (!this.creating)
            this.$emit('close-modal');
    }

    public async createContract() {
        try {
            this.creating = true;

            const contract = await Contracts.create(this.prop.law, this.prop.procedureId, this.prop.lotId, this.participant!.id);
            
            this.$emit("close-modal");
            router.push(`/contracts/${contract.id}`);
        } catch (e) {
            this.creating = false;
            console.error(e);
        }
    }
}
