



















































































































import { LawType, ObjectStatus, SelectOption } from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Lot } from "@/models";
import { BankAccount } from "@/models/BankAccount";
import { formatNumber } from "@/NumberFormatting";
import Auth from '@/api/Auth';

@Component
export default class RequestProvision extends Vue {
    @Prop() public source!: Lot;
    @Prop() public trade!: boolean;
    @Prop() public accountOptions!: SelectOption<BankAccount>[];
    @Prop() public disabled!: boolean;

    public get customerFull() { 
        return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.fullName;
    }
    
    public get customerShort() { 
        return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName;
    }

    public get requestProvisionString() {
        return this.source.requestProvision.used && this.source.requestProvision.percent
            ? `${this.source.requestProvision.percent.toString()}% - ${formatNumber(
                  this.source.requestProvision.percent.mul(this.source.totalStartPrice.div(100)),
              )} Российский рубль`
            : "";
    }
}
