import { IJsonFormattable } from "./IJsonFormattable";
import { IDtoPurchasePlan } from "./json/IDtoPurchasePlan";
import { ObjectStatus } from './enums';
import { HistoryEntry } from './HistoryEntry';

export class PurchasePlan implements IJsonFormattable<IDtoPurchasePlan> {
    constructor(
        public id: string,
        public orderNumber: number,
        public periodStartYear: number,
        public status: ObjectStatus,
        public approvalDate: Date | null,
        public publishDate: Date | null,
        public grbsAgreementDate: Date | null,
        public isActual: boolean,

        public history: HistoryEntry[],
    ) {}

    public toJson(): IDtoPurchasePlan {
        return {
            ...this,
            approvalDate: this.approvalDate ? this.approvalDate.toISOString() : null,
            publishDate: this.publishDate ? this.publishDate.toISOString() : null,
            grbsAgreementDate: this.grbsAgreementDate ? this.grbsAgreementDate.toISOString() : null,

            history: [] // NOT NECESSARY
        };
    }

    public static fromJson(dto: IDtoPurchasePlan): PurchasePlan {
        return Object.assign(Object.create(PurchasePlan.prototype), dto, {
            approvalDate: dto.approvalDate ? new Date(dto.approvalDate) : null,
            publishDate: dto.publishDate ? new Date(dto.publishDate) : null,
            grbsAgreementDate: dto.grbsAgreementDate ? new Date(dto.grbsAgreementDate) : null,
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : []
        });
    }
}
