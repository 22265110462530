


















import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { IObjectDeletionModalArgs } from "@/views/modals/ModalArgs";
import { PurchaseObject, Lot } from '../../../models';
import { LotStatusStrings } from '../../../models/enums';

@Component({ components: { CardModal } })
export default class ObjectCancellationModal extends ModalVue<Lot, null> {
    @Prop() public prop!: Lot;

    public mode: null | "correct" | "no-correct" = null;

    public confirm() {
        this.$emit("close-modal", ModalResult.ok(true));
    }

    public get title() {
        return `Отмена лота - ${this.prop.regNumber} - ${LotStatusStrings[this.prop.status]}`;
    }
}
