
















































import {Component, Vue, Watch} from "vue-property-decorator";
import EventBus, {showModal} from "@/EventBus";
import Modal from "@/components/Modal.vue";
import Auth from "@/api/Auth";
import router from './router';
import {ForgotPasswordModal} from "@/views/Register/forgot/ForgotPasswordModal";

@Component({ components: { Modal } })
export default class LoginModal extends Vue {
    public username = "";
    public password = "";

    public hasError = false;

    @Watch("username") @Watch("password") public resetError() { 
        this.hasError = false;
    }

    private handleModalEvent($event: Event) {
        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }

    public async login() {
        try {
            await Auth.login(this.username, this.password);
            this.$emit("close-modal");
            router.resetTo("/");
        } catch {
            this.hasError = true;
        }
    }

    public register() {
        this.$emit("close-modal");
        router.resetTo("/register");
    }

    public forgot() {
        showModal(ForgotPasswordModal, {});
        this.$emit("close-modal");
    }
}
