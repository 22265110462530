import { createRelatedObjects } from "@/models/RelatedObjects";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import SubjectClassSelectionModal from "@/views/modals/SubjectClassSelectionModal/SubjectClassSelectionModal.vue";
import ProvisionSelectionModal from "@/views/modals/ProvisionSelectionModal/ProvisionSelectionModal.vue";
import ObjectDeletionModal from "@/views/modals/ObjectDeletionModal";
import ObjectCancellationModal from "@/views/modals/ObjectCancellationModal";

import { FormDeclarationBuilder } from "@/form-declarations/purchaseObject";
import router from "@/router";

import PurchaseObjects from "@/api/PurchaseObjects";
import { PurchaseObject, UsedSource } from "@/models";
import { ObjectStatus, PurchaseObjectStatusStrings } from "@/models/enums";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import EventBus from "@/EventBus";

@Component({ components: { FormBase, SubjectClassSelectionModal, ProvisionSelectionModal, ObjectDeletionModal } })
export default class PurchaseObjectDetails extends Vue {
    public source: PurchaseObject | null = null;

    @Prop() private objectId!: string;
    private formDecl = FormDeclarationBuilder(true, false, false);

    private setSource(object: PurchaseObject) {
        this.source = object;
        this.formDecl.header =
            "Объект закупки - " + object.regNumber + " - " + PurchaseObjectStatusStrings[object.status];

        this.formDecl.relatedObjects = createRelatedObjects(object);
    }

    public mounted() {
        this.formDecl.header = "Объект закупки";
        PurchaseObjects.getById(this.objectId).then(object => this.setSource(object));
    }

    public async onAction(action: string) {
        switch (action) {
            case "edit":
                router.push(`/plan-objects/${this.objectId}/edit`);
                break;
            case "delete": {
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Удаление",
                    description:
                        "При удалении объекта закупки будут удалены все связанные с ним детализированные объекты закупок и лоты, продолжить?",
                });
                if (result.isOk && result.getResult()) {
                    PurchaseObjects.delete(this.objectId).then(response => router.push("/"));
                }
                break;
            }
            case "clone":
                router.push(`/plan-objects/${this.objectId}/clone`);
                break;
            case "approve":                
                PurchaseObjects.setStatus(this.source!.id!, ObjectStatus.APPROVED).then(response => this.setSource(response));
                break;
            case "cancel": {
                const result = await EventBus.callModal(ObjectCancellationModal, null);
                    
                if (result.isOk && result.getResult())
                    PurchaseObjects.setStatus(this.source!.id!, ObjectStatus.CANCELED).then(response => this.setSource(response));
                break;
            }
            case "detail":
                router.replace(`/plan-objects/${this.objectId}/new-detailed`);
                break;
            case "close":
                router.replace("/plan-objects");
                break;
        }
    }
}
