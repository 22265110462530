import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { SupplyStage, Lot } from "@/models";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { ISupplyStageModalArgs } from "../ModalArgs";
import { ITableColumn, MultiSelection } from '@/components/TableAbstractions';
import { LawTypeStrings, SmpTypeStrings, ProviderSelectionType } from '@/models/enums';
import { formatNumber } from '@/NumberFormatting';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import Lots from '@/api/Lots';
import { FilterValueType, FilterConditionType } from '@/api/http';

@Component({ components: { CardModal } })
export default class LotSelectionModal extends ModalVue<ProviderSelectionType, SupplyStage> {
    @Prop() private prop!: ProviderSelectionType;

    public context: PaginationContext<Lot> | null = null;

    public selected: MultiSelection<Lot> = { items: [], tag: "MULTI" };

    public async created() {
        this.context = await createPaginationContext((n, sz, f) => Lots.get(n, sz, {
            ...f,
            procedureId: [{
                type: FilterValueType.GUID,
                conditionType: FilterConditionType.EQUAL,
                guid: null,
            }],
            providerSelectionType: [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: this.prop,
            }]
        }), 20);
    }

    public headers: ITableColumn<Lot>[] = [
        {title: 'Номер лота', getter(n) { return n.regNumber }, size: '2fr' },
        {title: 'Закон-основание', getter(n) { return LawTypeStrings[n.law] } },
        {title: 'Наименование лота', getter(n) { return n.subject }, size: '4fr' },
        {title: 'НМЦ', getter(n) { return formatNumber(n.totalStartPrice) } },
        {title: 'Размещение среди СМП/МСП', getter(n) { return SmpTypeStrings[n.smpType] } }
    ];

    public selectItemAndExit() {
        if (this.isOk) {
            // const result = Object.assign(this.stage || Object.create(SupplyStage.prototype), this.selection)
            // this.$emit("close-modal", ModalResult.ok(result))
            this.$emit("close-modal", ModalResult.ok(this.selected.items));
        }
    }
    public get isOk(): boolean {
        return this.selected.items.length > 0;
    }
}
