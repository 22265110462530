import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { PurchaseObjectDetail } from "@/models/PurchaseObjectDetail";
import { ObjectStatus } from '@/models/enums';

export default class {

    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<PurchaseObjectDetail>> {
        return convertPaged(
            await http.getPaged("/api/purchaseObjectDetails", {
                from: from,
                count: count,
                filters
            }),
            PurchaseObjectDetail,
        );
    }

    public static async getById(id: string): Promise<PurchaseObjectDetail> {
        return PurchaseObjectDetail.fromJson(await http.get(`/api/purchaseObjectDetails/${id}`));
    }

    public static async create(obj: PurchaseObjectDetail): Promise<PurchaseObjectDetail> {
        return PurchaseObjectDetail.fromJson(await http.post(`/api/purchaseObjectDetails/create/${obj.object.id}`, obj.toJson()));
    }

    public static async update(obj: PurchaseObjectDetail): Promise<PurchaseObjectDetail> {
        return PurchaseObjectDetail.fromJson(await http.put(`/api/purchaseObjectDetails/${obj.id}`, obj.toJson()));
    }

    public static async delete(id: string): Promise<void> {
        await http.delete(`/api/purchaseObjectDetails/${id}`);
    }

    public static async setStatus(id: string, status: ObjectStatus): Promise<PurchaseObjectDetail> {
        return PurchaseObjectDetail.fromJson(await http.put(`/api/purchaseObjectDetails/${id}/Status/${status}`, null));
    }
}
