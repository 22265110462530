import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import { FormDeclarationBuilder } from '@/form-declarations/procedure';
import { Procedure } from '@/models/Procedure';
import router from '@/router';
import Procedures from '@/api/Procedures';
import Lots from '@/api/Lots';
import Participants from '@/api/Participants';
import { LawType } from '@/models/enums';
import EventBus from '@/EventBus';
import { createRelatedObjects } from '@/models/RelatedObjects';

@Component({ components: { FormBase } })
export default class CreateProcedure extends Vue {
    @Prop({ default: null }) private lawType!: LawType | null;
    @Prop() private objectId!: string;
    @Prop() private lotId!: string;
    @Prop() private clone!: boolean;

    public source = Procedure.createEmpty(this.lawType);

    public formDecl = FormDeclarationBuilder(false, { 
        specialParticipants: Participants.getSpecial(0, 100).then(x => x.items),
        creating: true
    });

    public mounted() {
        this.source.providerSelectionType = null!;

        if (this.objectId) {
            const word = this.clone ? "Копирование" : "Редактирование";
            this.formDecl.header = word + " процедуры";
            Procedures.getById(this.objectId).then(response => {
                this.source = response;
                this.lawType = response.law;
                
                this.formDecl.relatedObjects = createRelatedObjects(response, x => x.type !== "PROCEDURE");
            });
        } else if (this.lotId) {
            Lots.getById(this.lotId).then(response => {
                this.source.lots.push(response);
                this.source.providerSelectionType = response.providerSelectionType!;
                this.source.subject = response.subject;
            });
        }
    }
    
    public onAction(action: string) {
        switch (action) {
            case "close": 
                router.back();
                break;
            case "save":
                if (!this.source.subject || this.source.subject.trim().length === 0) {
                    EventBus.raiseError("Заполните \"Описание закупки\"");
                    break;
                }
                if (!this.source.providerSelectionType) {
                    EventBus.raiseError("Заполните \"Способ определения поставщика\"");
                    break;
                }

                if (!this.clone && this.objectId) {
                    Procedures.update(this.source).then(response => router.replace(`/procedures/${response.id}`));
                } else {
                    Procedures.create(this.source).then(response => router.replace(`/procedures/${response.id}`));
                }
                break; 
        }
    }
}
