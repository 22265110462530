








































import { Component } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { Terms } from "@/models";
import { TermsDuration, TermsPeriod } from "@/models/enums";
import { mixins } from "vue-class-component";
import DecimalField from "@/components/vue/form-table/DecimalField.vue";

@Component({ components: { DecimalField } })
export default class TermsField extends mixins(MutatingFieldBase) {
    private get terms(): Terms {
        return this.row.getter(this.source) as Terms;
    }

    private get isRepeating(): boolean {
        return this.terms.duration === TermsDuration.REPEATING;
    }

    private change(duration: TermsDuration, beginDate: Date, period: TermsPeriod, count: number) {
        this.$emit("set-value", { value: new Terms(duration, beginDate, period, count), row: this.row });
    }
}
