var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-card',{attrs:{"title":"Информация","isLargeHeader":true,"padding":30}},[_c('div',{staticClass:"info-table"},[_c('div',{staticClass:"info-line"},[_c('div',{staticClass:"field-label"},[_vm._v("СПГЗ:")]),_c('div',{staticClass:"info-input"},[_c('x-textbox',{attrs:{"value":_vm.specification.subjectDeclaration.subject,"readonly":""}})],1)]),_c('div',{staticClass:"info-line"},[_c('div',{staticClass:"field-label"},[_vm._v("Количество:")]),_c('div',{staticClass:"info-input"},[_c('x-textbox',{attrs:{"value":_vm.specification.volume,"readonly":""}})],1)]),_c('div',{staticClass:"info-line"},[_c('div',{staticClass:"field-label"},[_vm._v("Ед. изм:")]),_c('div',{staticClass:"info-input"},[_c('x-textbox',{attrs:{"value":_vm.specification.measurementUnit.name,"readonly":""}})],1)]),_c('div',{staticClass:"info-line"},[_c('div',{staticClass:"field-label"},[_vm._v("Ориентировочная цена:")]),_c('div',{staticClass:"info-input"},[_c('x-textbox',{attrs:{"readonly":""}})],1)]),_c('div',{staticClass:"info-line"},[_c('div',{staticClass:"field-label"},[_vm._v("Дата расчета:")]),_c('x-datepicker',{staticClass:"datetime"})],1)])]),_c('x-card',{attrs:{"title":"НМЦ расчетная","isLargeHeader":true,"padding":30}},[_c('d-table',{attrs:{"dataset":_vm.methods,"headers":_vm.methodsHeaders,"selection":_vm.methodSelection,"config":_vm.methodsConfig},on:{"selected":_vm.resetStartPrice},scopedSlots:_vm._u([{key:"methodName",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.id === 'TARIFF')?_c('div',{staticClass:"tariff-cell"},[_c('div',[_vm._v("Тарифный")]),(_vm.tariffs.length && _vm.methodSelection.item && _vm.methodSelection.item.id === 'TARIFF')?[_c('div',[_vm._v("Поставщик:")]),_c('x-combobox',{attrs:{"options":_vm.tariffs,"allowNullValue":true,"disabled":_vm.fixedSupplier},model:{value:(_vm.selectedTariffGroup),callback:function ($$v) {_vm.selectedTariffGroup=$$v},expression:"selectedTariffGroup"}}),_c('div',[_vm._v("Тарифная зона:")]),_c('x-combobox',{attrs:{"options":_vm.selectedTariffGroup && _vm.selectedTariffGroup.opts || [],"disabled":!_vm.selectedTariffGroup,"allowNullValue":true},model:{value:(_vm.selectedZonedTariffGroup),callback:function ($$v) {_vm.selectedZonedTariffGroup=$$v},expression:"selectedZonedTariffGroup"}})]:_vm._e()],2):(item.externalCalcStart)?_c('div',{style:({textDecoration: 'underline', cursor: 'pointer'}),on:{"click":item.externalCalcStart}},[_vm._v(" "+_vm._s(value)+" ")]):_c('div',[_vm._v(" "+_vm._s(value)+" ")])]}}])}),(_vm.reasonRequired)?_c('f-grid',{staticClass:"reason",attrs:{"state":"enabled","ratio":[1, 2]}},[_c('f-field',[_c('f-label',[_vm._v(" "+_vm._s(_vm.reasonDesc)+" "),_c('f-req')],1),_c('f-text',{attrs:{"multiline":true,"lines":3},model:{value:(_vm.startPrice.justification),callback:function ($$v) {_vm.$set(_vm.startPrice, "justification", $$v)},expression:"startPrice.justification"}})],1),_c('f-field',[_c('f-label',[_vm._v("Подтверждающий документ:")]),_c('input',{attrs:{"type":"file"}})],1)],1):_vm._e()],1),_c('x-card',{attrs:{"title":"НМЦ","padding":30,"isLargeHeader":true,"buttons":[
            {
                eventName: 'save',
                icon: {
                    type: 'CLASS',
                    name: 'save',
                },
            },
            {
                eventName: 'close',
                icon: {
                    type: 'CLASS',
                    name: 'close',
                },
            } ]},on:{"save":_vm.saveCalculation,"close":_vm.cancel}},[_c('d-table',{attrs:{"dataset":_vm.selectedMethodDataset,"headers":_vm.selectedMethodHeaders}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }