




















import { Component, Vue, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Subcriteria } from '@/models';

@Component({ components: { CardModal } })
export default class EditCriteriaProp extends Vue {
    @Prop() public prop!: { actual: Subcriteria; prop: "assessmentOrder" | "content" };

    public value = this.prop.actual[this.prop.prop];
    public name = this.prop.prop === "content" ? "Содержание" : "Порядок оценки";

    public saveAndClose() {
        this.prop.actual[this.prop.prop] = this.value;
        this.$emit("close-modal", ModalResult.ok(this.prop.actual));
    }
}
