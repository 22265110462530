var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.readonly)?_c('div',{staticClass:"field"},[_c('x-combobox',{attrs:{"value":_vm.terms.duration,"disabled":!_vm.enabled,"options":[{ key: 'ONE_TIME', desc: 'Единовременно' }, { key: 'REPEATING', desc: 'Периодически' }]},on:{"change":function (e) { return _vm.change(e, _vm.terms.beginDate, _vm.terms.period, _vm.terms.count); }}}),_c('div',{staticClass:"input-label",staticStyle:{"width":"80px"}},[_vm._v(_vm._s(_vm.isRepeating ? "Начиная с:" : "Дата:"))]),_c('x-datepicker',{attrs:{"value":_vm.terms.beginDate},on:{"change":function (e) { return _vm.change(_vm.terms.duration, e, _vm.terms.period, _vm.terms.count); }}}),_c('div',{staticClass:"input-label",class:[_vm.isRepeating ? '' : 'hidden']},[_vm._v("Период:")]),_c('x-combobox',{class:[_vm.isRepeating ? '' : 'hidden'],attrs:{"value":_vm.terms.period,"disabled":!_vm.enabled,"options":[
            { key: null, desc: 'Выберите период' },
            { key: 'UNDEFINED', desc: 'Не определен' },
            { key: 'YEAR', desc: 'Год' },
            { key: 'QUARTER', desc: 'Квартал' },
            { key: 'MONTH', desc: 'Месяц' },
            { key: 'WEEK', desc: 'Неделя' },
            { key: 'DAY', desc: 'День' },
            { key: 'WORKDAY', desc: 'Рабочий день (кроме выходных и праздничных дней' } ]},on:{"change":function (e) { return _vm.change(_vm.terms.duration, _vm.terms.beginDate, e, _vm.terms.count); }}}),_c('div',{staticClass:"input-label",class:[_vm.isRepeating ? '' : 'hidden']},[_vm._v("Раз:")]),_c('input',{class:[_vm.isRepeating ? '' : 'hidden'],attrs:{"type":"number","disabled":!_vm.enabled},domProps:{"value":_vm.terms.count},on:{"change":function (e) { return _vm.change(_vm.terms.duration, _vm.terms.beginDate, _vm.terms.period, Number(e.target.value)); }}})],1):_c('div',[_vm._v("USE SEPARATE FIELDS PLEASE")])}
var staticRenderFns = []

export { render, staticRenderFns }