import { FilterConditionType, FilterValueType } from '@/api/http';
import PurchaseObjectDetails from '@/api/PurchaseObjectDetails';
import { IRow } from '@/components/vue/form-table/FormFields';
import { formatDate } from '@/DateFormatting';
import {FinancialSource, PurchaseObject, PurchaseObjectDetail, SubjectClass, Terms} from '@/models';
import {
    createYearSelection,
    FinancialSourceTypeStrings,
    GoalType,
    GoalTypeStrings,
    LawType,
    ObjectStatus,
    PurchaseObjectStatusStrings,
    SmallVolumeType,
    SmallVolumeTypeStrings,
    SpecialPurchaseType,
    SpecialPurchaseTypeStrings,
    TermsDuration,
    toSelectOptions,
} from '@/models/enums';
import { EditReasonType, EditReasonTypeStrings } from '@/models/enums/EditReasonType';
import { formatNumber } from '@/NumberFormatting';
import ChangeHistorySection from '@/views/form-renderers/ChangeHistorySection';
import FieldSection from '@/views/form-renderers/FieldSection/FieldSection.vue';
import ProvisionSection from '@/views/form-renderers/ProvisionSection/ProvisionSection.vue';
import TableSection from '@/views/form-renderers/TableSection/TableSection.vue';
import { FormStyle, IForm, ISection, TableSectionData } from '@/views/FormAbstractions';
import Decimal from 'decimal.js';
import {
    actionL,
    actionR,
    classIcon,
    commonReadonlyActionsL,
    commonReadonlyActionsR,
    commonReadonlyNoEditActionsL,
    makeFormHelpers,
    merge,
} from './helpers';
import { nonEmpty, nonEmptyString, nonNull } from './validators';
import Auth from '@/api/Auth';

const h = makeFormHelpers<PurchaseObject>();

export function FormDeclarationBuilder(readonly: boolean, edit: boolean, clone: boolean): IForm<PurchaseObject> {
    const subjectClassField: IRow<PurchaseObject> = {
        id: "subjectClass",
        title: "КПГЗ",
        type: "PICK",
        style: 'SHORT',
        visible: p => p.smallVolume === SmallVolumeType.NO,
        required: true,
        hint: {
            text:
                "Поле для выбора элемента из классификатора предметов государственного заказа, определяющего предмет закупки",
            link: {
                title: "П.3 часть 2 статья 17 глава 2 ФЗ – 44",
                href:
                    "http://www.consultant.ru/cons/cgi/online.cgi?req=doc;base=LAW;n=157037;div=LAW;dst=101694;rnd=0.6302542458288372#05735342883270671",
            },
        },

        getter: p => readonly && p.subjectClass ? p.subjectClass.toLongString() : p.subjectClass,
        validate: p =>
            p.subjectClass && nonEmptyString(p.subjectClass.code)
                ? p.subjectClass.code.split(".").length > 1
                    ? { error: false }
                    : { error: true, description: "Может быть выбрана позиция КПГЗ 2-го уровня и ниже" }
                : { error: true, description: "КПГЗ не выбран" }
    };

    const discussionFields: IRow<PurchaseObject>[] = [
        {
            title:
                "Не требуется в связи с применением закрытых способов определения поставщиков (подрядчиков, исполнителей)",
            type: "CHECKBOX",
            visible: p => p.isDiscussionRequired,

            getter: p => p.isPublicDiscussionNotApplicableDueToClosedMethod,
            setter: (p, v: boolean) => (p.isPublicDiscussionNotApplicableDueToClosedMethod = v),
        },
        {
            title: "Не требуется в связи с проведением закупки у единственного поставщика",
            type: "CHECKBOX",
            visible: p => p.isDiscussionRequired,

            getter: p => p.isPublicDiscussionNotApplicableDueToSingleProvider,
            setter: (p, v: boolean) => (p.isPublicDiscussionNotApplicableDueToSingleProvider = v),
        },
    ];

    const expectedResultField: IRow<PurchaseObject> = {
        title: "Ожидаемый результат:",
        type: "MULTILINE_TEXT",
        required: true,
        visible: p => p.goal.type === GoalType.EVENT_EXECUTION,
        hint: {
            text: "Ожидаемый результат реализации мероприятия государственной программы города Москвы.",
        },

        getter: p => p.goal.expectedResult,
        validate: p => nonEmpty(p.goal.expectedResult),
        setter(p, v) { p.goal.expectedResult = v },
    };

    const eventsField: IRow<PurchaseObject> = {
        title: "Мероприятия  программы:",
        type: "MULTILINE_TEXT",
        required: true,
        visible: p => p.goal.type === GoalType.EVENT_EXECUTION,
        hint: {
            text: "Наименование мероприятия государственной программы города Москвы",
        },

        getter: p => p.goal.programEvents,
        validate: p => nonEmpty(p.goal.programEvents),
        setter: (p, v: string) => (p.goal.programEvents = v),
    };

    const pt7InfoField: IRow<PurchaseObject> = {
        title: "Информация в соответствии с п.7 ч.2 ст.17:",
        type: "MULTILINE_TEXT",
        required: true,
        visible: p => p.goal.type === GoalType.EVENT_EXECUTION,

        getter: p => p.goal.pt7Info,
        validate: p => nonEmpty(p.goal.pt7Info),
        setter: (p, v: string) => (p.goal.pt7Info = v),
    };

    const onlyEvExecFields: IRow<PurchaseObject>[] = [expectedResultField, eventsField, pt7InfoField];

    const goalSection: ISection<PurchaseObject> = {
        type: "FIELDS",
        name: "Цель",
        component: FieldSection,
        visible: p => p.smallVolume === SmallVolumeType.NO && p.law === LawType.F44,
        data: {
            columnCount: readonly ? 1 : 2,
            columns: [
                {
                    rows: [
                        {
                            title: "Цель закупки:",
                            type: "RADIO_SELECT",
                            groupName: "goalType",
                            required: true,
                            hint: {
                                text: "Поле для указания цели закупки",
                                link: {
                                    title: "44-ФЗ:Глава 1, статья 13",
                                    href:
                                        "http://www.consultant.ru/cons/cgi/online.cgi?req=doc;base=LAW;n=157037;div=LAW;dst=100111;rnd=0.49889100645668805#04945507943836873",
                                },
                            },

                            selectOptions: toSelectOptions(GoalTypeStrings),

                            getter: p => p.goal.type,
                            setter: (p, v: GoalType) => p.goal.type = v
                        },
                    ],
                },
                {
                    rows: [expectedResultField],
                },
                {
                    rows: [
                        {
                            title: "Наименование программы:",
                            type: "MULTILINE_TEXT",
                            required: true,

                            getter: p => p.goal.programName,
                            validate: p => nonEmpty(p.goal.programName),
                            setter: (p, v: string) => (p.goal.programName = v),
                        },
                        eventsField,
                        {
                            title: "Обоснование соответствия объекта закупки мероприятию программы:",
                            type: "MULTILINE_TEXT",
                            required: true,

                            getter: p => p.goal.purchaseReason,
                            validate: p => nonEmpty(p.goal.purchaseReason),
                            setter: (p, v: string) => (p.goal.purchaseReason = v),
                        },
                        {
                            title:
                                "Полное наименование, дата принятия и номер утвержденных в соответствии со ст. 19 нормативных правовых (правовых) актов, или указание на отсутствие такого акта для соответствующего объекта и (или) соответствующих объектов закупки:",
                            type: "MULTILINE_TEXT",
                            required: true,

                            getter: p => p.goal.legalActs,
                            validate: p => nonEmpty(p.goal.legalActs),
                            setter: (p, v: string) => (p.goal.legalActs = v),
                        },
                        pt7InfoField,
                    ],
                },
            ],
        },
    };

    let termsSection: ISection<PurchaseObject>;

    if (readonly) {
        const singleTerm = h.explicit().roText("Дата закупки", p => formatDate(p.terms.beginDate!), {
            visible: p => p.terms.duration === TermsDuration.ONE_TIME
        });

        const periodicTerm: IRow<PurchaseObject> = {
            title: "Периодичность закупки",
            type: "TEXT",
            visible: p => p.terms.duration === TermsDuration.REPEATING,

            getter: p => `${p.terms.count} раз начиная с ${formatDate(p.terms.beginDate!, true)}`
        };

        termsSection = {
            type: "FIELDS",
            name: "Срок осуществления закупки",
            component: FieldSection,
            visible: p => p.smallVolume === SmallVolumeType.NO && p.law === LawType.F44,
            data: {
                columnCount: 1,
                columns: [
                    {
                        rows: [singleTerm, periodicTerm],
                    },
                ],
            },
        };
    } else {
        termsSection = {
            type: "FIELDS",
            name: "Срок осуществления закупки",
            visible: p => p.smallVolume === SmallVolumeType.NO && p.law === LawType.F44,
            component: FieldSection,
            required: true,
            data: {
                columnCount: 1,
                columns: [
                    {
                        rows: [
                            {
                                title: "Периодичность",
                                type: "TERMS",
                                hint: {
                                    text: "Поле для указания срока или периодичности осуществления закупки",
                                    link: {
                                        title: "П.5 часть 2 статья 17 глава 2 ФЗ – 44",
                                        href:
                                            "http://www.consultant.ru/cons/cgi/online.cgi?req=doc;base=LAW;n=157037;div=LAW;dst=100145;rnd=0.9274810992646962#016038874362352518",
                                    },
                                },

                                validate({ terms: { beginDate, duration, count, period } }) {
                                    if (duration === TermsDuration.REPEATING) {
                                        if (!beginDate) return { error: true, description: 'Укажите дату начала осуществления закупки.' };
                                        if (!period) return { error: true, description: 'Не указан период закупки' };
                                        if (!count) return { error: true, description: 'Не указано количество повторений' };
                                    }

                                    if (!beginDate) return { error: true, description: 'Укажите срок закупки.' };

                                    return { error: false };
                                },

                                getter: p => p.terms,
                                setter: (p, v: Terms) => (p.terms = v),
                            },
                        ],
                    },
                ],
            },
        };
    }

    const discussionSection: ISection<PurchaseObject> = {
        type: "FIELDS",
        name: "Общественное обсуждение",
        component: FieldSection,
        visible: p => p.smallVolume === SmallVolumeType.NO && p.law === LawType.F44,
        hint: {
            text:
                "Согласно 20 статье 2 главы 44-ФЗ процедура общественного обсуждения необходима при закупке свыше 1 000 000 000руб",
            link: {
                title: "статья 20 Глава 2 44-ФЗ",
                href:
                    "http://www.consultant.ru/cons/cgi/online.cgi?req=doc;base=LAW;n=157037;div=LAW;dst=100184;rnd=0.027654005214571953#09227484808202129",
            },
        },
        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Обязательность общественного обсуждения",
                            type: "TEXT",
                            editable: false,

                            getter: p => (p.isDiscussionRequired ? "Да" : "Нет")
                        },
                        ...discussionFields,
                    ],
                },
            ],
        },
    };

    const specialPurchaseField: IRow<PurchaseObject> = {
        title: "Особая закупка",
        type: "RADIO_SELECT",
        groupName: "specialPurchase",
        visible: p => p.smallVolume === SmallVolumeType.NO && p.law === LawType.F44,

        selectOptions: toSelectOptions(SpecialPurchaseTypeStrings),
        getter: p => p.specialPurchaseType,
        setter: (p, v: SpecialPurchaseType) => (p.specialPurchaseType = v),
    };

    const subjectRow: IRow<PurchaseObject> = {
        title: "Предмет закупки",
        type: "MULTILINE_TEXT",
        required: true,
        hint: {
            text: "Предмет закупки",
        },

        getter: p => p.subject,
        validate: p => nonEmpty(p.subject),
        setter: (p, v: string) => (p.subject = v),
    };

    const secondColumnRows: IRow<PurchaseObject>[] = [
        {
            title: "Планируемый год размещения извещения о закупке",
            type: "SELECT",
            required: true,

            style: 'SHORT',

            selectOptions: createYearSelection(true, undefined, ""),

            getter: p => p.year,
            setter: (p, v: number) => p.year = v === null ? null : v,
            validate: p => nonNull(p.year)
        },
        specialPurchaseField,
        {
            title: "ИКЗ",
            type: "TEXT",
            visible(p) { return p.law === LawType.F44 },
            editable: false,

            getter: p => p.purchaseId || "не сгенерирован"
        },
    ];

    const isOk = (p: PurchaseObject) => {
        return (
            p.financialSources.length > 0 &&
            p.year !== null &&
            nonEmptyString(p.subject) &&
            (p.smallVolume !== SmallVolumeType.NO ||
                (p.subjectClass !== null &&
                    p.subjectClass.code.split(".").length > 1 &&
                    (p.law === LawType.F223 ||
                        (nonEmptyString(p.goal.programName) &&
                        nonEmptyString(p.goal.purchaseReason) &&
                        nonEmptyString(p.goal.legalActs) &&
                        (p.goal.type !== GoalType.EVENT_EXECUTION ||
                            (nonEmptyString(p.goal.expectedResult) &&
                                nonEmptyString(p.goal.programEvents) &&
                                nonEmptyString(p.goal.pt7Info)))))))
        );
    };

    const nonReadonlyActions = edit ? [
        actionL("save", "blue", classIcon("save"), isOk),
        actionR("close", "red", classIcon("close"))
    ] : [
        actionL("save", "blue", classIcon("save"), isOk),
        actionL("save-and-continue", "blue", classIcon("save-thin"), isOk),
        actionR("close", "red", classIcon("close"))
    ];

    const detailedObjectsSection: ISection<PurchaseObject> = {
        type: "TABLE",
        component: TableSection,
        name: "Детализированные объекты закупок",
        data: {
            getter(v) {
                return PurchaseObjectDetails.get(0, 1000, {
                    objectId: [{
                        type: FilterValueType.GUID,
                        conditionType: FilterConditionType.EQUAL,
                        guid: v.id
                    }]
                }).then(x => x.items);
            },
            headers: [{
                title: "Код",
                getter(p) { return p.regNumber },
                url(p) { return `/plan-objects/detailed/${p.id}` },
                size: "1.7fr"
            }, {
                title: "КПГЗ",
                getter(p) {
                    const classes = p.specifications.map(x => x.subjectDeclaration?.subjectClass)
                        .filter(Boolean) as SubjectClass[];
                    if (classes.length === 0) return "";

                    const shortest = classes.reduce((r, x) => r.parents.length > x.parents.length ? x : r);
                    const segments = [...shortest.parents, { id: shortest.id, code: shortest.code, name: shortest.description }];

                    let mostCommon = -1;

                    for (let i = 0; i < segments.length; i++) {
                        const segment = segments[i];

                        outer:
                        for (const klass of classes) {
                            if (klass.id === segment.id) continue;

                            for (const parent of klass.parents) {
                                if (parent.id === segment.id) continue outer;
                            }
                        }

                        mostCommon = i;
                    }

                    if (mostCommon === -1) return "";

                    const code = segments[mostCommon].code;
                    const desc = segments.map(x => x.name).join("/");

                    return `${code} ${desc}`;
                }
            }, {
                title: "Описание",
                getter(p) { return p.subject }
            }, {
                title: "Сумма, руб.",
                getter(p) { return formatNumber(p.totalStartPrice) }
            }, {
                title: "Статус",
                getter(p) { return PurchaseObjectStatusStrings[p.status] }
            }],
            emptyDescription: "Детализированных закупок нет"
        } as TableSectionData<PurchaseObject, PurchaseObjectDetail> as never
    };

    const reservedFundsSection: ISection<PurchaseObject> = {
        type: "TABLE",
        component: TableSection,
        name: "Зарезервированные средства",
        visibleInNavPanel: false,
        data: {
            getter(v) {
                return PurchaseObjectDetails.get(0, 1000, {
                    objectId: [{
                        type: FilterValueType.GUID,
                        conditionType: FilterConditionType.EQUAL,
                        guid: v.id
                    }]
                }).then(x => {
                    const used = new Map<number, { source: FinancialSource; total: Decimal; used: Decimal }>();

                    for (const src of v.financialSources) {
                        used.set(src.source.id, {
                            source: src.source,
                            total: src.amount,
                            used: new Decimal(0)
                        });
                    }

                    for (const detail of x.items) {
                        for (const usage of detail.sourceUsage) {
                            const existing = used.get(usage.source.source.id) || {
                                source: usage.source.source,
                                total: usage.source.amount,
                                used: new Decimal(0)
                            };

                            existing.used = existing.used.add(usage.usedAmount);
                            used.set(usage.source.source.id, existing);
                        }
                    }

                    return [...used.values()];
                });
            },
            headers: [{
                title: "Год",
                getter(p) { return p.source.year },
            }, {
                title: "Тип",
                getter(p) { return FinancialSourceTypeStrings[p.source.type] }
            }, {
                title: "Код",
                getter(p) { return p.source.code() }
            }, {
                title: "Сумма лимита, руб.",
                getter(p) { return formatNumber(p.source.total) }
            }, {
                title: "Зарезервировано по лимиту, руб.",
                getter(p) { return formatNumber(p.source.used) }
            }, {
                title: "Остаток по лимиту, руб.",
                getter(p) { return formatNumber(p.source.total.sub(p.source.used)) }
            }, {
                title: "Не включено в ДОЗ, руб.",
                getter(p) { return formatNumber(p.total.sub(p.used)) }
            }, {
                title: "Включено в ДОЗ и нет контракта, руб.",
                getter(p) { return formatNumber(p.used) }
            }, {
                title: "Включено в контракты, руб.",
                getter(p) { return "0,00" }
            }, {
                title: "Итого, руб.",
                getter(p) { return formatNumber(p.total) }
            }],
            emptyDescription: ""
        } as TableSectionData<PurchaseObject, { source: FinancialSource; total: Decimal; used: Decimal }> as never
    };

    const reasonSection = h.visible(p => !readonly && !clone && p.isEditReasonRequired).fields("Причина изменения", FormStyle.HORIZONTAL, 1, {
        rows: [
            h.required().select("Причина изменения", "editReasonType", toSelectOptions(EditReasonTypeStrings, true, "Выберите причину изменения")),
            h.required().visible(x => x.editReasonType === EditReasonType.OTHER)
                .multiline("Описание причины внесения изменений", "editReasonInfo", {
                    lines: 5
                })
        ]
    });

    reasonSection.visibleInNavPanel = false;

    const decl: IForm<PurchaseObject> = {
        header: "Новый объект закупки",
        stats: undefined,
        actions(p) {
            if (readonly) {
                switch (p.status) {
                    case ObjectStatus.CREATING:
                        return merge(
                            commonReadonlyActionsL,
                            commonReadonlyActionsR,
                            actionR("approve", "green", "Утвердить"));
                    case ObjectStatus.APPROVED:
                        return merge(
                            commonReadonlyActionsL,
                            commonReadonlyActionsR,
                            actionR("detail", "green", "Детализировать объект"));
                    case ObjectStatus.GRBS_AGREEMENT_PENDING:
                        return merge(
                            commonReadonlyNoEditActionsL,
                            commonReadonlyActionsR);
                    case ObjectStatus.PUBLISHED:
                        if (p.law === LawType.F223)
                            return merge(
                                commonReadonlyNoEditActionsL,
                                commonReadonlyActionsR,
                                actionL("edit", "blue", classIcon("edit")),
                                actionL("delete", "red", classIcon("delete")),
                                actionR("detail", "green", "Детализировать объект"));
                        else
                            return merge(
                                commonReadonlyNoEditActionsL,
                                commonReadonlyActionsR,
                                actionL("edit", "blue", classIcon("edit")),
                                actionR("detail", "green", "Детализировать объект"),
                                actionR("cancel", "red", "Отмена"));
                    case ObjectStatus.CANCELED:
                        return [
                            actionL("clone", "blue", classIcon("clone")),
                            actionL("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close"))
                        ];
                    default:
                        return merge(commonReadonlyActionsL, commonReadonlyActionsR);
                }
            } else {
                return nonReadonlyActions;
            }
        },
        pages: [{
            id: "main",
            showNavigationHeader: true,
            sections: [
                reasonSection,
                {
                    type: "FIELDS",
                    name: "Содержание закупки",
                    component: FieldSection,
                    data: {
                        columnCount: 2,
                        columns: [
                            {
                                rows: [
                                    {
                                        title: "Закон-основание",
                                        type: "TEXT",
                                        editable: false,

                                        getter: p => (p.law === LawType.F44 ? "44-ФЗ" : "223-ФЗ"),
                                        setter: (p, v: LawType) => (p.law = v),
                                    },
                                    {
                                        title: "Заказчик",
                                        type: "TEXT",
                                        editable: false,
                                        required: true,
                                        hint: {
                                            text:
                                                "Поле для выбора заказчика, в план закупок которого будет включен объект закупки",
                                            link: {
                                                title: "Часть 1 статья 17 глава 2 44-ФЗ",
                                                href:
                                                    "http://www.consultant.ru/cons/cgi/online.cgi?req=doc;base=LAW;n=157037;div=LAW;dst=100139",
                                            },
                                        },

                                        getter: () =>
                                            readonly
                                                ? Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.fullName
                                                : Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName
                                    },
                                    {
                                        title: "Закупка малого объема",
                                        type: "RADIO_SELECT",
                                        groupName: "smallVolume",
                                        required: true,
                                        visible(p) { return p.law === LawType.F44 },

                                        selectOptions: toSelectOptions(SmallVolumeTypeStrings),
                                        getter: p => p.smallVolume,
                                        setter: (p, v: SmallVolumeType) => {
                                            p.smallVolume = v;
                                        },
                                    },
                                    {
                                        title: "Закупка малого объема (агрегированная закупка)",
                                        type: "RADIO_SELECT",
                                        groupName: "smallVolume",
                                        required: true,
                                        readonly: true,
                                        visible(p) { return p.law === LawType.F223 },

                                        selectOptions: [{key: false, desc: "Нет"}, {key: true, desc: "Да"}],
                                        getter: _ => false,
                                        setter: (_1, _2: SmallVolumeType) => {},
                                    },
                                    subjectClassField,
                                    {
                                        title: "ОКПД-2",
                                        type: "TEXT",
                                        editable: false,
                                        visible: p => !!(p.subjectClass && p.subjectClass.okpd2),

                                        getter: p => p.subjectClass && p.subjectClass.okpd2
                                            ? p.subjectClass.okpd2.code + " " + p.subjectClass.okpd2.name
                                            : ""
                                    },
                                    ...(readonly ? [subjectRow, ...secondColumnRows] : [])
                                ],
                            },
                            ...(!readonly ? [
                                { rows: [subjectRow] },
                                { rows: secondColumnRows }
                            ] : [])
                        ],
                    },
                },
                {
                    type: "UNTYPED",
                    name: "Финансовое обеспечение закупки",
                    component: ProvisionSection,
                    required: true,
                    data: undefined,
                },
                reservedFundsSection,
                goalSection,
                termsSection,
                discussionSection,
                detailedObjectsSection, {
                    type: "UNTYPED",
                    name: "История изменений",
                    component: ChangeHistorySection,
                    data: undefined,
                    visibleInNavPanel: false
                }
            ],
        }]
    };

    return decl;
}
