import React, {useEffect, useState} from "react";
import styles from "./Navbar2.module.css";
import {Expandee} from "@/components/layouts";
import {j} from "@/reactUtils";

function NavbarLink(x: { id: string; name: string; onChange: (v: string) => void; activeId: string; href?: string}) {
    return <a className={j(styles.link, x.activeId === x.id ? styles.active : undefined)} onClick={() => x.onChange(x.id)} href={x.href} title={x.name}>
        { x.name }
    </a>;
}

export function Navbar2(x: { onRouteChange: (id: string) => void; route: string }) {
    const [mobile, setMobile] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const cb = () => {
            if (window.innerWidth < 992) {
                if (x.route === "login") x.onRouteChange("news");
                setMobile(true);
            } else {
                setMobile(false);
            }
        };
        cb();
        window.addEventListener("resize", cb);
        return () => window.removeEventListener("resize", cb);
    }, []);

    return <>
        <nav className={styles.navbar2}>
            <div className={styles.content}>
                <a className={styles.logoWrapper}>
                    <img src="./nav-logo.png" />
                </a>
                {
                    !mobile
                        ? <div className={styles.links}>
                            <NavbarLink id="news" name="Новости" onChange={x.onRouteChange} activeId={x.route} />
                            <NavbarLink id="webinar" name="Вебинары" onChange={() => {}} activeId={x.route} href={"/webinars"} />
                            {
                                !mobile && <NavbarLink id="login" name="Тренажер" onChange={x.onRouteChange} activeId={x.route} />
                            }
                        </div>
                        : <div className={styles.togglerWrap} onClick={() => setOpen(!open)}>
                                <div className={styles.toggler}>
                                    <div className={styles.burger} />
                                    <div className={styles.burger} />
                                    <div className={styles.burger} />
                            </div>
                        </div>
                }
            </div>
        </nav>
        { mobile && <div className={styles.burgerOpen} style={!open ? { height: 0, display: "none" } : undefined}>
            <div>
                <NavbarLink id="news" name="Новости" onChange={x.onRouteChange} activeId={x.route} />
            </div>
            <div>
                <NavbarLink id="webinar" name="Вебинары" onChange={() => {}} activeId={x.route} href={"/webinars"} />
            </div>
        </div> }
        </>;
}
