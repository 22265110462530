import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn, MultiSelection, ITableAction, EditType } from "@/components/TableAbstractions";
import { Lot, PurchaseStage, PurchaseObjectDetail, LotDetail } from "@/models";
import { formatNumber } from "@/NumberFormatting";
import Decimal from "decimal.js";
import EventBus from "@/EventBus";
import PurchaseObjectDetailSelectionModal from "@/views/modals/PurchaseObjectDetailSelectionModal";
import PurchaseStageModal from "@/views/modals/PurchaseStageModal/PurchaseStageModal.vue";
import { IPurchaseStageModalArgs } from "@/views/modals/ModalArgs";
import { formatDate } from "@/DateFormatting";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import { FormStyle } from "@/views/FormAbstractions";
import { IRow } from '@/components/vue/form-table/FormFields';
import { SmpTypeStrings, toSelectOptions, SmpType, LawType } from '@/models/enums';

interface IIndexedLotDetail {
    id: string
    detail: PurchaseObjectDetail
    smpPercent: Decimal
}

@Component({ components: { DataTable, FormTable } })
export default class SpecificationsSection extends Vue {
    @Prop() private source!: Lot;
    @Prop() public formStyle!: FormStyle;
    @Prop() public state!: FieldState;
    @Prop({ default: false }) private tradeMode!: boolean;

    public get lotDetails(): IIndexedLotDetail[] { 
        return this.source.purchaseObjectDetails!.map(x => ({
            id: x.detail.id!,
            get detail() { return x.detail },
            get smpPercent() { return x.smpPercent },
            set smpPercent(v: Decimal) { x.smpPercent = v }
        }));
    }

    private nonStagedDate: Date | null = null;
    private stages: PurchaseStage[] = [];

    private createTerms() {
        let x = this.source.paymentTerms;
        if (!x.staged) this.nonStagedDate = x.date;
        else this.stages = x.stages;

        const that = this;

        return {
            get isInnovationProduct() {
                return that.source.isInnovationProduct;
            },
            set isInnovationProduct(s) {
                that.source.isInnovationProduct = s;
            },
            get lawType() {
                return that.source.law;
            },

            get staged() {
                return x.staged;
            },
            set staged(s) {
                that.source.paymentTerms = x = s
                    ? { staged: true, stages: that.stages }
                    : { staged: false, date: that.nonStagedDate };
            },

            get date() {
                return x.staged ? that.nonStagedDate : x.date;
            },
            set date(v: Date | null) {
                that.nonStagedDate = x.staged ? v : (x.date = v);
            },
        };
    }

    public terms = this.createTerms();

    @Watch("source.paymentTerms") public updTerms() {
        this.terms = this.createTerms();
    }

    public smpTypeField: IRow<Lot> = {
        title: (l) => l.law === LawType.F44 ? 'Размещение среди СМП' : 'Размещение среди МСП',
        type: 'SELECT',

        selectOptions: toSelectOptions(SmpTypeStrings),
        getter: p => p.smpType,
        setter: (p, v: SmpType) => {
            p.smpType = v;

            for (const d of this.lotDetails) {
                d.smpPercent = v === SmpType.FULL ? new Decimal(100) : new Decimal(0);
            }
        }
    };

    public detailsSelection: MultiSelection<IIndexedLotDetail> = { items: [], tag: "MULTI" };

    private realPercent(userPercent: Decimal) {
        switch (this.source.smpType) {
            case SmpType.NO: return new Decimal(0);
            case SmpType.FULL: return new Decimal(100);
            default: return userPercent;
        }
    }

    private isSmpValid(v: IIndexedLotDetail) {
        return v.smpPercent.isFinite() && 
            (this.source.smpType !== SmpType.SUBCONTRACTING || (v.smpPercent.gte(1) && v.smpPercent.lte(99)));
    }

    public detailsHeaders: ITableColumn<IIndexedLotDetail>[] = [
        {
            title: "Реестровый номер",
            getter: v => v.detail.regNumber,
        },
        {
            title: "КПГЗ",
            getter: v => v.detail.object.subjectClass!.toString(),
        },
        {
            title: "Описание",
            getter: v => v.detail.subject,
        },
        {
            title: "Код финансирования",
            getter: v => v.detail.object.financialSources.map(x => x.source.format(false)).join("; "),
        },
        {
            title: "Стоимость",
            getter: v => formatNumber(v.detail.totalStartPrice),
        },
        {
            title: "Доля СМП/МСП",
            editable: () => this.source.smpType === SmpType.SUBCONTRACTING,
            type: EditType.DECIMAL,

            getter: v => this.source.smpType === SmpType.SUBCONTRACTING ? v.smpPercent : formatNumber(this.realPercent(v.smpPercent)),
            setter(v, val: Decimal) { v.smpPercent = val },
            validate: (v) => { 
                return this.isSmpValid(v)
                    ? { error: false }
                    : { error: true, description: "Значение не может быть меньше 1 или превышать 99" };
            }
        },
        {
            title: "Сумма СМП/МСП",
            getter: v => formatNumber(this.isSmpValid(v) 
                ? v.detail.totalStartPrice.mul(this.realPercent(v.smpPercent)).div(100) 
                : new Decimal(0)),
        },
    ];

    public stagesHeaders: ITableColumn<PurchaseStage>[] = [
        {
            title: "Номер",
            getter: (_, i) => (i + 1).toString(),
            size: "auto",
        },
        {
            title: "Дата платежа",
            getter: v => formatDate(v.date!),
        },
        {
            title: "Процент от НМЦ контракта",
            getter: v => formatNumber(v.percent) + "%",
        },
        {
            title: "Сумма платежа",
            getter: v => formatNumber(v.percent.mul(this.source.totalStartPrice).div(100)),
        },
    ];

    public stagesActions: ITableAction[] = [
        {
            id: "edit",
            title: "Редактировать",
        },
        {
            id: "delete",
            title: "Удалить",
        },
    ];

    public async addDetail() {
        const r = await EventBus.callModal<LawType, PurchaseObjectDetail[]>(PurchaseObjectDetailSelectionModal, this.source.law);
        if (r.isOk) {
            for (const pod of r.getResult()) {
                if (!this.source.purchaseObjectDetails!.find(x => x.detail.id === pod.id))
                    this.source.purchaseObjectDetails!.push(new LotDetail(pod, new Decimal(0)));
            }
        }
    }

    public async removeDetails() {
        for (const sel of [...this.detailsSelection.items]) {
            const ix = this.source.purchaseObjectDetails!.findIndex(x => x.detail.id === sel.id);
            this.source.purchaseObjectDetails!.splice(ix, 1);
        }
        this.detailsSelection.items = [];
    }

    public async addStage(existing: PurchaseStage | null = null) {
        const r = await EventBus.callModal<IPurchaseStageModalArgs, PurchaseStage>(PurchaseStageModal, {
            lot: this.source,
            selectedStage: existing,
        });
        if (r.isOk) {
            const result = r.getResult();
            if (!this.stages.includes(result)) this.stages.push(result);
        }
    }

    public handleStagesAction(item: PurchaseStage, action: string) {
        switch (action) {
            case "edit": // todo
                this.addStage(item);
                break;
            case "delete":
                this.stages = this.stages.filter(x => x !== item);
                break;
        }
    }
}
