import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { PurchasePlan, SchedulePlan } from "@/models";
import { ObjectStatus, LawType } from '@/models/enums';

export default class {
    public static async getPurchasePlans(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<PurchasePlan>> {
        return convertPaged(
            await http.getPaged("/api/PurchasePlans", {
                from: from,
                count: count,
                filters: filters
            }),
            PurchasePlan,
        );
    }

    public static async getPurchasePlanById(id: string): Promise<PurchasePlan> {
        return PurchasePlan.fromJson(await http.get(`/api/PurchasePlans/${id}`));
    }

    public static async createPurсhasePlan(periodStartYear: number): Promise<PurchasePlan> {
        return PurchasePlan.fromJson(await http.post(`/api/PurchasePlans/Create?periodStartYear=${periodStartYear}`, null));
    }

    public static async setPurchasePlanStatus(id: string, status: ObjectStatus): Promise<PurchasePlan> {
        return PurchasePlan.fromJson(await http.put(`/api/PurchasePlans/${id}/Status/${status}`, null));
    }

    public static async deletePurchasePlan(id: string): Promise<void> {
        await http.delete(`/api/PurchasePlans/${id}`);
    }
    
    public static async getSchedulePlans(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<SchedulePlan>> {
        return convertPaged(
            await http.getPaged("/api/SchedulePlans", {
                from: from,
                count: count,
                filters: filters
            }),
            SchedulePlan,
        );
    }

    public static async getSchedulePlanById(id: string): Promise<SchedulePlan> {
        return SchedulePlan.fromJson(await http.get(`/api/SchedulePlans/${id}`));
    }

    public static async createSchedulePlan(year: number, lawType: LawType): Promise<SchedulePlan> {
        return SchedulePlan.fromJson(await http.post(`/api/SchedulePlans/Create?year=${year}&law=${lawType}`, null));
    }

    public static async setSchedulePlanStatus(id: string, status: ObjectStatus): Promise<SchedulePlan> {
        return SchedulePlan.fromJson(await http.put(`/api/SchedulePlans/${id}/Status/${status}`, null));
    }

    public static async deleteSchedulePlan(id: string): Promise<void> {
        await http.delete(`/api/SchedulePlans/${id}`);
    }
}
