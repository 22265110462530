import { render, staticRenderFns } from "./DocumentationSection.vue?vue&type=template&id=40b466a1&scoped=true&"
import script from "./DocumentationSection.ts?vue&type=script&lang=ts&"
export * from "./DocumentationSection.ts?vue&type=script&lang=ts&"
import style0 from "./DocumentationSection.vue?vue&type=style&index=0&id=40b466a1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b466a1",
  null
  
)

export default component.exports