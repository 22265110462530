import { File } from "@/models/Documents";
import { ProcedureDocumentType } from "@/models/enums/Documents";
import { IDtoProcedureDocument } from '@/models/json/Documents';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ProcedureDocument implements IJsonFormattable<IDtoProcedureDocument> {
    constructor(
        public file: File,
        public type: ProcedureDocumentType,
        public sendToEis: boolean
    ) {}

    public toJson(): IDtoProcedureDocument {
        return {
            file: this.file.toJson(),
            type: this.type,
            sendToEis: this.sendToEis
        };
    }

    public static fromJson(dto: IDtoProcedureDocument): ProcedureDocument {
        return Object.assign(Object.create(ProcedureDocument.prototype), {
            file: File.fromJson(dto.file),
            type: dto.type,
            sendToEis: dto.sendToEis
        });
    }
}
