import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoCriteria } from './json/IDtoCriteria';

export declare interface Subcriteria {
    name: string
    value: number | null
    content: string | null
    assessmentOrder: string | null
}

export class Criteria implements IJsonFormattable<IDtoCriteria>, Subcriteria {
    constructor(
        public name: string,
        public value: number | null,
        public content: string | null,
        public assessmentOrder: string | null,
        public canAddSubcriteria: boolean,
        public subcriteria: Subcriteria[]
    ) {}
    
    public toJson(): IDtoCriteria {
        return {
            ...this
        };
    }
    
    public static fromJson(dto: IDtoCriteria): Criteria {
        return Object.assign(Object.create(Criteria.prototype), {
            ...dto
        });
    }
}
