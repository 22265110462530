import _ from "./PurchaseObjectDetailedTermAddModal.vue";

import { LawType } from "@/models/enums";

import { ModalVue } from "@/view-models/ModalRequest";
import { VueConstructor } from "vue";
import { IObjectDeletionModalArgs } from "../ModalArgs";
import PurchaseObjectDetailedTerm from "@/models/PurchaseObjectDetailedTerm";

const __ = _ as VueConstructor<ModalVue<IObjectDeletionModalArgs, PurchaseObjectDetailedTerm>>;

export default __;
