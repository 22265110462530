import { ISection, IconType, IForm, FormStyle, IAction } from "@/views/FormAbstractions";
import FieldSection from "@/views/form-renderers/FieldSection/FieldSection.vue";
import SourceUsageSection from "@/views/form-renderers/SourceUsageSection/SourceUsageSection.vue";
import AddressSection from "@/views/form-renderers/AddressSection/AddressSection.vue";
import DetailedSpecificationsSection from "@/views/form-renderers/DetailedSpecificationsSection/DetailedSpecificationsSection.vue";
import { PurchaseObjectDetail } from "@/models/PurchaseObjectDetail";
import { LawType, SmallVolumeType, ObjectStatus } from "@/models/enums";
import { nonEmptyString } from './validators';
import ChangeHistorySection from '@/views/form-renderers/ChangeHistorySection';
import { commonReadonlyActionsR, commonReadonlyActionsL, merge, actionR, actionL, classIcon, commonReadonlyNoEditActionsL } from './helpers';
import Auth from '@/api/Auth';

export default function(readonly: boolean): IForm<PurchaseObjectDetail> {
    const generalSection: ISection<PurchaseObjectDetail> = {
        type: "FIELDS",
        name: "Содержание закупки",
        component: FieldSection,
        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Закон-основание",
                            type: "TEXT",
                            editable: false,

                            getter: p => (p.object.law === LawType.F44 ? "44-ФЗ" : "223-ФЗ"),
                            setter: (p, v: LawType) => (p.object.law = v),
                        },
                        {
                            title: "Заказчик",
                            type: "TEXT",
                            editable: false,

                            getter: () => Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.fullName
                        },
                        {
                            title: "Предмет закупки",
                            type: "MULTILINE_TEXT",
                            editable: true,
                            required: true,

                            getter: p => p.subject,
                            setter: (p, v: string) => (p.subject = v),
                        },
                        {
                            title: "Закупка на единицу продукции",
                            type: "CHECKBOX",
                            visible: p => p.object.smallVolume === SmallVolumeType.NO,

                            getter: p => p.isPerItemPurchase,
                            setter: (p, v: boolean) => (p.isPerItemPurchase = v),
                        },
                    ],
                },
            ],
        },
    };

    const financingSection: ISection<PurchaseObjectDetail> = {
        type: "UNTYPED",
        name: "Финансирование",

        component: SourceUsageSection,
        data: undefined,
    };

    const addressSection: ISection<PurchaseObjectDetail> = {
        type: "UNTYPED",
        name: "Адрес поставки",
        component: AddressSection,
        allowComponentOverflow: !readonly,
        data: undefined,
        visible(p) { return p.object.smallVolume === SmallVolumeType.NO }
    };

    function isOk(p: PurchaseObjectDetail) {
        return (
            nonEmptyString(p.subject) &&
            p.specifications.every(v => v.startPrice !== null) &&
            (p.object.smallVolume !== SmallVolumeType.NO || p.address.isNotEmpty)
        );
    }

    const form: IForm<PurchaseObjectDetail> = {
        header: "Новый детализированный объект закупки",
        stats: undefined,
        formStyle: FormStyle.HORIZONTAL,
        showFooterActions: !readonly,
        actions(p: PurchaseObjectDetail) {
            if (readonly) {
                switch (p.status) {
                    case ObjectStatus.CREATING:
                        return merge(
                            commonReadonlyActionsL, 
                            commonReadonlyActionsR,
                            actionR("approve", "green", "Утвердить"));
                    case ObjectStatus.APPROVED:
                        if (p.lotId === null) {
                            return merge(
                                commonReadonlyActionsL, 
                                commonReadonlyActionsR,
                            actionR("create-lot", "green", "Создать лот"));
                        } else 
                            return merge(commonReadonlyActionsL, commonReadonlyActionsR);
                    case ObjectStatus.CANCELED:
                        return merge(commonReadonlyNoEditActionsL, commonReadonlyActionsR,
                            actionL("edit", "blue", classIcon("edit")));
                    default:
                        return merge(commonReadonlyActionsL, commonReadonlyActionsR);
                }
            } else {
                return [
                    actionL("save", "blue", classIcon("save")),
                    actionR("close", "red", classIcon("close"))
                ];
            }
        },
        pages: [{
            id: "main",
            showNavigationHeader: true,
            sections: [
                generalSection,
                {
                    type: "UNTYPED",
                    name: "Спецификации",
                    component: DetailedSpecificationsSection,
                    data: undefined,
                    isRawComponent: true,
                    visible(p) { return p.object.smallVolume === SmallVolumeType.NO }
                },
                ...(readonly ? [addressSection, financingSection] : [financingSection, addressSection]),
                {
                    type: "UNTYPED",
                    name: "История изменений",
                    component: ChangeHistorySection,
                    data: undefined,
                    visibleInNavPanel: false
                }
            ]
        }],
    };

    return form;
}
