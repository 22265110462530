import { IDtoUsedSource } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { FinancialSource } from "@/models/FinancialSource";
import Decimal from "decimal.js";

export class UsedSource implements IJsonFormattable<IDtoUsedSource> {
    constructor(public source: FinancialSource, public amount: Decimal) {}

    public toJson(): IDtoUsedSource {
        return {
            ...this,
            source: this.source.toJson(),
            amount: this.amount.toString(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoUsedSource): UsedSource {
        return Object.assign(Object.create(UsedSource.prototype), dto, {
            source: FinancialSource.fromJson(dto.source),
            amount: new Decimal(dto.amount),
        });
    }
}
