import _ from "./PurchaseObjectDetailedSpecModal.vue";

import { ISpecificationCreationModalArgs } from "../ModalArgs";
import { Specification } from "@/models";
import { ModalVue } from "@/view-models/ModalRequest";
import { VueConstructor } from "vue";

const __ = _ as VueConstructor<ModalVue<ISpecificationCreationModalArgs, Specification>>;

export default __;
