import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { IColumn, ISetValueEvent } from "@/components/vue/form-table/FormFields";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { PurchaseStage } from "@/models";
import { Decimal } from "decimal.js";
import { IPurchaseStageModalArgs } from "../ModalArgs";
import { formatNumber } from "@/NumberFormatting";
import { validationResult } from '@/components/TableAbstractions';

@Component({ components: { CardModal, TreeView, FormTable, DataTable } })
export default class PurchaseStageModal extends ModalVue<IPurchaseStageModalArgs, {}> {
    @Prop() private prop!: IPurchaseStageModalArgs;

    private get startPrice(): Decimal {
        return this.prop.lot.totalStartPrice;
    }

    private source = this.prop.selectedStage || new PurchaseStage(null, new Decimal(0));

    public formColumns: IColumn<PurchaseStage>[] = [
        {
            rows: [
                {
                    title: "Дата платежа",
                    type: "MONTH_FIELD",

                    getter(src) {
                        return src.date;
                    },
                    setter(src, v: Date) {
                        src.date = v;
                    },
                },
                {
                    title: "Процент от НМЦ контракта",
                    type: "DECIMAL",

                    getter(src) {
                        return src.percent;
                    },
                    setter(src, val: Decimal) {
                        src.percent = val;
                    },
                    validate(src) {
                        return validationResult(src.percent.gte(1) && src.percent.lte(100), "Процент не может быть меньше 1 или превышать 100");
                    }
                },
                {
                    title: "Сумма платежа",
                    type: "TEXT",
                    editable: false,

                    getter: src => formatNumber(this.startPrice.mul(src.percent).div(100)) + " Российский Рубль",
                    setter() {},
                },
            ],
        },
    ];

    public get isOk() {
        return this.source.date !== null && this.source.percent.gte(1) && this.source.percent.lte(100);
    }

    public selectItemAndExit() {
        this.$emit("close-modal", ModalResult.ok(this.source));
    }
}
