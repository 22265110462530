import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/vue/Card.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import { createYearSelection, ObjectStatus, LawTypeStrings, ProviderSelectionTypeStrings, SmpTypeStrings, LawType } from '@/models/enums';
import router from '@/router';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import { Lot } from '@/models';
import { FilterValueType, FilterConditionType } from '@/api/http';
import Lots from '@/api/Lots';
import { formatDate } from '@/DateFormatting';
import Plans from '@/api/Plans';
import Decimal from 'decimal.js';
import EventBus from '@/EventBus';

@Component({ components: { Card, FormTable } })
export default class CreateSchedulePlan extends Vue {
    @Prop() private lawType!: LawType;
    public get lawTypeString() { return LawTypeStrings[this.lawType] }

    public year = 2019;
    public yearVolume: Decimal | null = null;
    public years = createYearSelection(false);

    public context: PaginationContext<Lot> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) =>
            Lots.get(n, sz, {
                ...f,
                status: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.APPROVED,
                }, {
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.PUBLISHED,
                }, {
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.INCLUDED_IN_PLAN,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: this.lawType,
                }]
            }), 10);
    }

    private lotTableHeaders: ITableColumn<Lot>[] = [
        {
            title: "Регистровый номер",
            getter(v) { return v.regNumber },
        },
        {
            title: "Закон-основание",
            getter(v) { return LawTypeStrings[v.law] },
        },
        {
            title: "Наименование лота",
            getter(v) { return v.subject },
        },
        {
            title: "НМЦ контракта",
            getter(v) { return v.totalStartPrice },
        },
        {
            title: "Способ определения поставщика",
            getter(v) { return ProviderSelectionTypeStrings[v.providerSelectionType!] },
        },
        {
            title: "Дата начала закупки",
            getter(v) { return formatDate(v.contractTerms!) },
        },
        {
            title: "Размещение среди СМП/МСП",
            getter(v) { return SmpTypeStrings[v.smpType] },
        },
        {
            title: "Замечание",
            getter(v) { return "" },
        },
    ];

    public close() {
        router.back();
    }

    public createPlan() {
        if (this.yearVolume === null || this.yearVolume.isNaN()) {
            EventBus.raiseError("Необходимо указать совокупный годовой объем закупок.");
            return;
        }

        Plans.createSchedulePlan(this.year, this.lawType)
            .then(r => router.replace('/plans/schedule-plans/' + r.id));
    }
}
