import { IDtoPurchaseStage } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";

export class PurchaseStage implements IJsonFormattable<IDtoPurchaseStage> {
    constructor(public date: Date | null, public percent: Decimal) {}

    public toJson(): IDtoPurchaseStage {
        return {
            date: this.date!.toISOString(),
            percent: this.percent.toString(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoPurchaseStage): PurchaseStage {
        return Object.assign(Object.create(PurchaseStage.prototype), dto, {
            date: new Date(dto.date),
            percent: new Decimal(dto.percent),
        });
    }
}
