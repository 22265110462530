import { ProviderSelectionType, LawType, ObjectStatus } from '../enums';
import { IDtoLot } from './IDtoLot';
import { Currency } from '../enums/Currency';
import { IDtoPublishTerms } from './IDtoPublishTerms';
import { IDtoParticipant } from './IDtoParticipant';
import { IDtoProcedureDocument } from './Documents';
import { IRelatedObject } from '../RelatedObjects';
import { IDtoHistoryEntry } from './IDtoHistoryEntry';


export const enum ProcedureParticipantStatus {
    PARTICIPANT = "PARTICIPANT",
    REQUIREMENTS_FAILED = "REQUIREMENTS_FAILED",
    REQUIREMENTS_SUCCESSFUL = "REQUIREMENTS_SUCCESSFUL",
    WINNER = "WINNER",
    WINNER_CONTRACT_PENDING = "WINNER_CONTRACT_PENDING",
    WINNER_CONTRACT_DONE = "WINNER_CONTRACT_DONE"
}

export declare interface IDtoProcedureParticipant {
    price: string
    lotId: string
    status: ProcedureParticipantStatus
    registrationDate: string
    participant: IDtoParticipant
}

export declare interface IDtoProcedure {
    id: string | null
    regNumber: string
    status: ObjectStatus
    law: LawType

    subject: string
    providerSelectionType: ProviderSelectionType

    marketplace: number | null // IDtoServiceOrganization
    documentationPreparer: number | null // IDtoServiceOrganization
    procedurePerformer: { equalToDocPreparer: true } | { equalToDocPreparer: false; organization: number | null } // IDtoServiceOrganization }

    lots: IDtoLot[]
    decisionNumber: string
    decisionDate: string | null

    documents: Array<IDtoProcedureDocument>

    documentationOrder: {
        address: string
        room: string
        email: string
        phone: string
        fax: string
        contactPerson: string
        orderDescription: {
            default: boolean
            text: string | null
        }
    }

    requestAcceptanceOrder: {
        address: string
        room: string
        email: string
        phone: string
        fax: string
        contactPerson: string
        copies: number
        orderDescription: {
            default: boolean
            text: string | null
        }
    }

    paymentOrder: {
        paymentRequired: boolean
        volume: string
        currency: Currency
        taxRequired: boolean
        taxDescription: string

        account: {
            code: string
            bankId: string
            bankName: string
            bankCity: string
        }

        orderDescription: string
    }

    publishTerms: IDtoPublishTerms | null
    participants: IDtoProcedureParticipant[]

    relatedObjects: IRelatedObject[]
    history: IDtoHistoryEntry[] | null
}
