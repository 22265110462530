











import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ISpecificationSelection } from './PurchaseObjectDetailedSpecModal';
import SubjectDeclarations from '../../../api/SubjectDeclarations';
import { SimpleRow } from '../../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../../components/vue/form-table/FieldBase';
import { FilterValueType, FilterConditionType } from '../../../api/http';
import { SubjectDeclaration } from '../../../models';

@Component({ components: {  } })
export default class SubjectClassPicker extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ISpecificationSelection>>;

    private get realId() {
        return this.props.source.subjectDeclaration && this.props.source.subjectDeclaration.id || null;
    }

    @Watch("realId") public updateId(newId: number) {
        this.id = newId;
        this.error = null;
    }

    public id: number | null = this.realId;
    public error: string | null = null;

    public select() {
        if (this.id) {
            SubjectDeclarations.get(0, 1, {
                id: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: this.id }]
            }).then(x => this.applySelected(x.items));
        } else {
            this.error = "Некорректный идентификатор СПГЗ";
        }
    }

    private applySelected(decls: SubjectDeclaration[]) {
        if (decls.length === 0) {
            this.error = "Некорректный идентификатор СПГЗ. СПГЗ удален или не существует";
            return;
        }
        const decl = decls[0];
        const codePrefix = decl.subjectClass.code.split(".")[0];
        const curPrefix = this.props.source.baseSubjectClass.code.split(".")[0];

        if (curPrefix !== codePrefix) {
            this.error = "Некорректный идентификатор СПГЗ. В объекте закупок выбран КПГЗ из другой ветки";
            return;
        }

        this.props.source.subjectClass = decl.subjectClass;
        this.props.source.subjectDeclaration = decl;
    }
}
