import { Component, Prop, Vue } from "vue-property-decorator";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, EditType, validationResult, IRowConfiguration } from "@/components/TableAbstractions";
import { formatNumber } from "@/NumberFormatting";
import Decimal from "decimal.js";
import { Specification, PurchaseObjectDetail, StartPrice, SourceUsage, FinancialSource, UsedSource } from "@/models";

function totalPrices(specs: Specification[]) {
    return specs.reduce((r, v) => r.add(v.startPrice ? v.startPrice.multiplied : new Decimal(0)), new Decimal(0));
}

class SourceUsageWrapper {
    constructor(public specs: Specification[], public usage: SourceUsage) {}

    public get totalPrices(): Decimal {
        return totalPrices(this.specs);
    }
    public get source(): UsedSource {
        return this.usage.source;
    }
    public get year(): number {
        return this.usage.source.source.year;
    }
    public get amount(): Decimal {
        return this.usage.usedAmount.eq(0) && this.usage.assumeFull ? this.totalPrices : this.usage.usedAmount;
    }
    public set amount(v: Decimal) {
        this.usage.assumeFull = false;
        this.usage.usedAmount = v;
    }
    public get percent(): Decimal {
        return this.usage.usedAmount.eq(0) && this.usage.assumeFull ? new Decimal(100) : this.usage.usedAmount.mul(100).div(this.totalPrices);
    }
    public set percent(v: Decimal) {
        this.usage.assumeFull = false;
        this.usage.usedAmount = this.totalPrices.mul(v).div(100).toNearest(0.01, Decimal.ROUND_HALF_EVEN);
    }
}

function finiteOrZero(num: Decimal) {
    return num.isFinite() ? num : new Decimal(0);
}

@Component({ components: { DataTable } })
export default class SourceUsageSection extends Vue {
    @Prop() private state!: FieldState;
    @Prop() private source!: PurchaseObjectDetail;
    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: true }) private hideButton!: boolean;

    private get totalPricesDecimal(): Decimal {
        return totalPrices(this.source.specifications);
    }

    public get totalPrices(): string {
        return formatNumber(this.totalPricesDecimal);
    }

    private get providedValue(): Decimal {
        return this.source.sourceUsage.reduce((r, v) => r.add(finiteOrZero(
            v.usedAmount.eq(0) && v.assumeFull ? this.totalPricesDecimal : v.usedAmount
        )), new Decimal(0));
    }

    public get unprovidedValue(): string {
        return formatNumber(this.totalPricesDecimal.sub(this.providedValue));
    }

    public get badUnprovidedValue(): boolean {
        return this.totalPricesDecimal.sub(this.providedValue).abs().gte(0.01);
    }

    public get dataset(): SourceUsageWrapper[] {
        return this.source.sourceUsage.map(x => new SourceUsageWrapper(this.source.specifications, x));
    }

    // TODO: remove copypaste
    public config: IRowConfiguration<SourceUsageWrapper> = {
        group(items) {
            const groupMap = new Map<number, SourceUsageWrapper[]>();

            for (const item of items) {
                if (!groupMap.has(item.year)) groupMap.set(item.year, []);

                const arr = groupMap.get(item.year)!;
                arr.push(item);
            }

            return [...groupMap.keys()].sort().map(x => ({ header: x + " год", items: groupMap.get(x)! }));
        }
    };

    private createHeaders(): ITableColumn<SourceUsageWrapper>[] {
        const that = this;

        return [
            {
                title: "ГРБС",
                getter() {
                    return "Нет";
                }, // ???
            },
            {
                title: "Тип",
                getter() {
                    return "ПФХД";
                }, // ???
            },
            {
                title: "Источник",
                getter(v) {
                    return v.source.source.format(true);
                },
            },
            {
                title: "Лимит объекта, руб.",
                getter(v) {
                    return formatNumber(v.source.amount);
                },
            },
            {
                title: "Остаток объекта, руб.",
                getter(v) {
                    return formatNumber(v.source.amount.sub(finiteOrZero(v.amount)));
                },
                validate(v) {
                    return validationResult(v.amount.isFinite() && v.source.amount.gte(v.amount));
                },
            },
            {
                title: "Сумма, руб.",
                type: EditType.DECIMAL,
                precision: 2,
                get editable(): boolean {
                    return that.state !== "READONLY";
                },

                getter(v) {
                    return that.state === "READONLY" ? formatNumber(v.amount) : finiteOrZero(v.amount);
                },
                setter(v, val: Decimal) {
                    v.amount = val;
                },
                validate(v) {
                    return validationResult(v.amount.isFinite() && v.amount.gte(0));
                },
            },
            {
                title: "Процент от суммы закупки, %",
                type: EditType.DECIMAL,
                precision: 2,
                get editable(): boolean {
                    return that.state !== "READONLY";
                },

                getter(v) {
                    return that.state === "READONLY" ? formatNumber(v.percent) : finiteOrZero(v.percent);
                },
                setter(v, val: Decimal) {
                    v.percent = val;
                },
                validate(v) {
                    return validationResult(v.amount.isFinite() && v.amount.gte(0));
                },
            },
        ];
    }

    private headers = this.createHeaders();
}
