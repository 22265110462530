import { Component, Prop, Vue } from "vue-property-decorator";
import Plans from "@/api/Plans";

import Card from "@/components/vue/Card.vue";
import DataTable from "@/components/vue/DataTable.vue";
import router from "@/router";

import { ITableColumn } from "@/components/TableAbstractions";
import { ObjectStatus } from "@/models/enums";

import { PurchasePlan as Plan } from "@/models";
import { PurchaseObject } from '@/models';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import PurchaseObjects from '@/api/PurchaseObjects';
import EventBus from '@/EventBus';

import ObjectDeletionModal from '@/views/modals/ObjectDeletionModal';
import { IObjectDeletionModalArgs } from '@/views/modals/ModalArgs';
import { formatDate } from '@/DateFormatting';
import { FilterValueType, FilterConditionType } from '@/api/http';

import ChangeHistorySection from "@/views/form-renderers/ChangeHistorySection";
import Auth from '@/api/Auth';

@Component({ components: { Card, DataTable, ChangeHistorySection } })
export default class PurchasePlan extends Vue {
    @Prop() private purchasePlanId!: string;
    public source: Plan | null = null;

    public purchaseObjectsContext: PaginationContext<PurchaseObject> | null = null;

    public get customer() {
        return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName;
    }

    public get canDelete() {
        return this.source &&
            (this.source.status !== ObjectStatus.GRBS_AGREEMENT_PENDING &&
            this.source.status !== ObjectStatus.PUBLISHED);
    }

    public async mounted() {
        Plans.getPurchasePlanById(this.purchasePlanId).then(object => {
            this.source = object;
        });
        this.purchaseObjectsContext = await createPaginationContext((n, sz, f) =>
            PurchaseObjects.get(n, sz, {
                ...f,
                planId: [{
                    type: FilterValueType.GUID,
                    conditionType: FilterConditionType.EQUAL,
                    guid: this.purchasePlanId!,
                }]
            }), 10);
    }

    public purchaseObjectsTableHeaders: ITableColumn<PurchaseObject>[] = [
        {
            title: "Реестровый номер",
            getter: x => x.regNumber,
            // url: (x: PurchaseObject) => `/plan-objects/${x.id}`,
        },
        {
            title: "Закон-основание",
            getter: x => (x.law === "F44" ? "44-ФЗ" : "223-ФЗ"),
        },
        {
            title: "Описание закупки",
            getter: x => x.subject,
        },
        {
            title: "КПГЗ",
            getter: x =>
                x.subjectClass ? x.subjectClass.toLongString() : "",
        },
        {
            title: "Код финансирования",
            getter: x => x.financialSources.map(s => s.source.format(false)).join("; "),
        },
        {
            title: "Объем финансирования",
            getter: x => x.totalVolume,
        },
        {
            title: "Замечание",
            getter: x => "",
        },
    ];

    public purchasePlanAsuGfTableHeaders: ITableColumn<Plan>[] = [
        {
            title: "№",
            getter: () => "1",
            size: "auto"
        },
        {
            title: "Исходящий файл",
            getter: () => "Request.xml",
            url: () => () => {}
        },
        {
            title: "Дата отправки",
            getter: x => x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""
        },
        {
            title: "Подтверждение получения",
            getter: () => "Ticket.xml",
            url: () => () => {}
        },
        {
            title: "Дата приема подтверждения",
            getter: x => x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""
        },
        {
            title: "Сведения о согласовании",
            getter: () => "Protocol.xml",
            url: () => () => {}
        },
        {
            title: "Дата получения сведений",
            getter: x => x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""
        },
        {
            title: "Дополнительные сведения о согласовании",
            getter: () => "PositiveProtocol.xml",
            url: () => () => {}
        },
        {
            title: "Дата получения дополнительных сведений",
            getter: x => x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""
        },
    ];

    public purchasePlanEisTableHeaders: ITableColumn<Plan>[] = [
        {
            title: "Номер",
            getter: () => "1",
            size: "auto"
        },
        {
            title: "Исходящий файл",
            getter: () => "xml_to_oos.xml",
            url: () => () => {}
        },
        {
            title: "Дата отправки",
            getter: x => x && x.publishDate ? formatDate(x.publishDate, true) : ""
        },
        {
            title: "Подтверждение получения",
            getter: () => "xml_from_oos.xml",
            url: () => () => {}
        },
        {
            title: "Дата приема подтверждения",
            getter: x => x && x.publishDate ? formatDate(x.publishDate, true) : ""
        },
        {
            title: "Сведения о публикации",
            getter: () => "xml_from_oos_publ.xml",
            url: () => () => {}
        },
        {
            title: "Дата получения сведений",
            getter: x => x && x.publishDate ? formatDate(x.publishDate, true) : ""
        },
    ];

    public setStatus(status: ObjectStatus) {
        Plans.setPurchasePlanStatus(this.source!.id, status)
            .then(r => router.go(0));
    }

    public async deletePlan() {
        const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
            title: "Удаление",
            description: "Вы действительно хотите удалить план закупок?"
        });
        if (result.isOk && result.getResult()) {
            Plans.deletePurchasePlan(this.source!.id)
                .then(() => router.back());
        }
    }

    public close() {
        router.back();
    }
}
