import { EnumStrings } from "@/models/enums/EnumStrings";

export const enum GoalType {
    EVENT_EXECUTION = "EVENT_EXECUTION",
    WORLDWIDE_PROGRAM = "WORLDWIDE_PROGRAM",
    FUNCTION_EXECUTION = "FUNCTION_EXECUTION",
}

export const GoalTypeStrings: EnumStrings = {
    [GoalType.EVENT_EXECUTION]:
        "Достижение целей и реализации мероприятий, предусмотренных государственными программами (в том числе федеральными, региональными, муниципальными целевыми программами) и документами стратегического и программно-целевого планирования Российской Федерации и ее субъектов",
    [GoalType.WORLDWIDE_PROGRAM]:
        "Исполнение международных обязательств Российской Федерации, реализация межгосударственных целевых программ, участником которых является Российская Федерация",
    [GoalType.FUNCTION_EXECUTION]:
        "Выполнение функций и полномочий государственных органов Российской Федерации, органов управления государственными внебюджетными фондами Российской Федерации, государственных органов субъектов Российской Федерации, органов управления территориальными внебюджетными фондами, муниципальных органов",
};
