import { IForm, IAction, FormStyle, IconType } from "@/views/FormAbstractions";
import { makeFormHelpers, pathfactory, commonReadonlyActionsL, commonReadonlyActionsR, merge, actionL, classIcon, actionR, Converter } from './helpers';
import { Procedure } from '@/models/Procedure';
import { LawTypeStrings, toSelectOptions, ProviderSelectionTypeStrings, ProviderSelectionType, ObjectStatus } from '@/models/enums';
import FileTableRow, { FileTableInfo } from './raw-rows/FileTableRow/FileTableRow';
import ProcedureLotsSection from '@/views/form-renderers/ProcedureLotsSection/ProcedureLotsSection.vue';
import { TextAlignment } from '@/components/vue/form-table/FormFields';
import { CurrencyStrings } from '@/models/enums/Currency';
import { Participant, Lot } from '@/models';
import TableSection from '@/views/form-renderers/TableSection';
import SalesResultSection from '@/views/form-renderers/SalesResultSection';
import { procDocsToFileTableInfo } from './converters';
import { DateTimeRange } from '@/models/DateTimeRange';
import { formatDate } from '@/DateFormatting';
import { LotDocumentType, LotDocumentTypeStrings } from '@/models/enums/Documents';
import { LotDocument } from '@/models/Documents';
import Auth from '@/api/Auth';
import Decimal from "decimal.js";

const h = makeFormHelpers<Procedure>();
const p = pathfactory<Procedure>();

export declare interface IProcedureFormData {
    specialParticipants: Promise<Participant[]>
}

export function FormDeclarationBuilder(readonly: boolean, data: IProcedureFormData): IForm<Procedure> {
    function formatTime(time?: Date | null) {
        if (!time) return "";
        return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
    }
    function range(withTime: boolean): Converter<DateTimeRange, string> {
        return {
            convert(t) {
                return withTime
                    ? `с ${formatDate(t.from, true)} ${formatTime(t.from)} по ${formatDate(t.to, true)} ${formatTime(t.from)}`
                    : `с ${formatDate(t.from, true)} по ${formatDate(t.to, true)}`;
            },
            convertBack(_) { return null! }
        };
    }

    const participantSelection = data.specialParticipants.then(ps => {
        const mapped = ps.map(x => ({ key: x.id, desc: x.shortName }));
        mapped.splice(0, 0, { key: null!, desc: "Выберите организацию"});
        return mapped;
    });

    const containing = [
        ProviderSelectionType.OPENED_EXAM,
        ProviderSelectionType.QUATATIONS_REQUEST,
        ProviderSelectionType.OFFERS_REQUEST,
        ProviderSelectionType.TWOSTAGE_EXAM,
        ProviderSelectionType.LIMITED_EXAM,
    ];

    function isOrderSectionVisible(pp: Procedure) {
        return containing.includes(pp.providerSelectionType);
    }

    return {
        header: "Процедура",
        pages: [{
            id: "main",
            showNavigationHeader: true,
            sections: [
                h.fields("Сведения о закупке", FormStyle.HORIZONTAL, 1, {
                    rows: [
                        h.required().const().text("Реестровый номер",  "regNumber"),
                        h.required().const().explicit().text("Организатор", () => Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName, () => {}),
                        h.required().const().text("Наименование",  "subject"),
                        h.required().const().explicit().text("Сумма", (proc) => {
                            let result = new Decimal("0");
                            if (proc && proc.lots)
                                proc.lots.forEach(lot =>  result = result.plus(lot.totalStartPrice));
                            return (result);
                        }, () => {}),
                        // TODO: #405
                        // h.required().const().explicit().text("Этап", () => {}, () => {}),

                    ]
                }), {
                    type: "UNTYPED",
                    name: "Лоты закупки",
                    component: ProcedureLotsSection,
                    data: undefined,
                }, {
                    type: "UNTYPED",
                    name: "Результаты закупки",
                    visible(v: Procedure) { return v.participants.length > 0 },
                    component: SalesResultSection,
                    data: undefined,
                }, {
                    type: "TABLE",
                    name: "Обмен с АСУ ГФ",
                    component: TableSection,
                    visible: () => readonly,
                    data: {
                        headers: [{
                            title: "№",
                            getter: () => "1",
                            size: "auto"
                        }, {
                            title: "Исходящий файл",
                            getter: () => "Request.xml",
                            url: () => () => {}
                        }, {
                            title: "Дата отправки",
                            getter: () => ""
                        }, {
                            title: "Подтверждение получения",
                            getter: () => "Ticket.xml"
                        }, {
                            title: "Дата приема подтверждения",
                            getter: () => ""
                        },
                        {
                            title: "Сведения о согласовании",
                            getter: () => ""
                        },
                        {
                            title: "Дата получения сведений",
                            getter: () => ""
                        }],
                        getter(v) { return [] }
                    }
                },
            ],
        }],
        actions(pp: Procedure) {
            if (readonly) {
                switch (pp.status) {
                    case ObjectStatus.CREATING:
                        return [
                            actionL("send-to-grbs", "green", "На согласование в РГ ГРБС"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close")),
                            actionR("delete", "red", classIcon("delete"))
                        ];
                    case ObjectStatus.GRBS_AGREEMENT_PENDING:
                        return [
                            actionL("download-docs", "green", "Документация"),
                            actionL("cancel-grbs-agreement", "green", "Отозвать с рассмотрения ГРБС"),
                            actionL("grbs-agree", "orange", "Согласовать в ГРБС (тест)"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close"))
                        ];
                    case ObjectStatus.GRBS_AGREED:
                        return [
                            actionL("pre-publish", "green", "Отправить на публикацию"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close")),
                            actionR("delete", "red", classIcon("delete"))
                        ];
                    case ObjectStatus.PUBLISH_PENDING:
                    case ObjectStatus.PUBLISH_DATE_SET:
                        return [
                            actionL("download-docs", "green", "Документация"),
                            actionL("set-publish-date", "green", "Установить сроки публикации"),
                            actionL("publish", "green", "Опубликовать",
                                x => x.status === ObjectStatus.PUBLISH_DATE_SET &&
                                    x.lots.every(lot => lot.status === ObjectStatus.PUBLISHED)),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close"))
                        ];
                    case ObjectStatus.PUBLISHED:
                        return [
                            actionL("additional-info", "green", "Подробные сведения"),
                            actionL("complete-sales", "orange", "Завершить торги (тест)"),
                            actionR("close", "red", classIcon("close"))
                        ];
                    default:
                        return commonReadonlyActionsR;
                }
            } else {
                return [
                    actionL("save", "blue", classIcon("save")),
                    actionR("close", "red", classIcon("close"))
                ];
            }
        }
    };
}
