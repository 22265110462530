import { Component, Prop, Vue } from "vue-property-decorator";
import SingleTableView from "@/components/SingleTableView.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import ObjectDeletionModal from "@/views/modals/ObjectDeletionModal";
import router from "@/router";

import PurchaseObjectDetails from "@/api/PurchaseObjectDetails";
import EventBus from "@/EventBus";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import { PurchaseObjectDetail } from "@/models";
import Decimal from "decimal.js";
import { formatNumber } from "@/NumberFormatting";
import { ObjectStatusStrings, toSelectOptions, LawType, LawTypeStrings } from "@/models/enums";
import { createPaginationContext, PaginationContext } from '@/api/Pagination';
import { containsFilter, selectFilter, startsWithFilter } from '@/components/TableHelpers';

@Component({ components: { SingleTableView, ObjectDeletionModal } })
export default class PlanObjectsDetailed extends Vue {
    public itemActions: unknown[] = [
        {
            id: "delete",
            title: "Удалить",
        },
        {
            id: "edit",
            title: "Редактировать",
        },
    ];

    public headers: ITableColumn<PurchaseObjectDetail>[] = [
        {
            title: "Реестровый номер",
            getter: x => x.regNumber,
            url: x => `/plan-objects/detailed/${x.id}`,
            filter: containsFilter("regNumber")
        },
        {
            title: "Закон-основание",
            getter: x => (x.object.law === "F44" ? "44-ФЗ" : "223-ФЗ"),
            filter: selectFilter("law", LawTypeStrings, false),
        },
        {
            title: "КПГЗ",
            getter: x => x.object.subjectClass,
            filter: startsWithFilter("subjectClassCode"),
        },
        {
            title: "Предмет закупки",
            getter: x => x.subject,
            filter: containsFilter("subject"),
        },
        {
            title: "Код финансирования",
            getter: x => x.object.financialSources.map(y => y.source.format(false)).join("; "),
        },
        {
            title: "Стоимость",
            getter: x => formatNumber(x.specifications.reduce((p, v) => p.add(v.startPrice!.multiplied), new Decimal(0))),
        },
        {
            title: "Статус",
            getter: x => ObjectStatusStrings[x.status],
            filter: selectFilter("status", ObjectStatusStrings, true),
        },
    ];

    public context: PaginationContext<PurchaseObjectDetail> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) => PurchaseObjectDetails.get(n, sz, f), 20);
    }

    public async onItemAction(item: {id: string}, action: string) {
        switch (action) {
            case "edit":
                router.push(`/plan-objects/detailed/${item.id}/edit`);
                break;
            case "delete":
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Подтверждение удаления детализированного объекта закупки",
                    description: "Вы действительно хотите удалить детализированный объект закупки?",
                });
                if (result.isOk && result.getResult()) {
                    PurchaseObjectDetails.delete(item.id).then(() => this.context!.load(0));
                }
                break;
        }
    }
}
