import { render, staticRenderFns } from "./FileTableRow.vue?vue&type=template&id=f2c8aabc&scoped=true&"
import script from "./FileTableRow.ts?vue&type=script&lang=js&"
export * from "./FileTableRow.ts?vue&type=script&lang=js&"
import style0 from "./FileTableRow.vue?vue&type=style&index=0&id=f2c8aabc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c8aabc",
  null
  
)

export default component.exports