import { File } from "@/models/Documents";
import { AbstractDocument } from "@/models/Documents/AbstractDocument";
import { LotDocumentType } from "@/models/enums/Documents";
import { IDtoLotDocument } from "@/models/json/Documents";
import { IJsonFormattable } from "@/models/IJsonFormattable";

export class LotDocument implements AbstractDocument<LotDocumentType>, IJsonFormattable<IDtoLotDocument> {
    constructor(public file: File, public type: LotDocumentType, public sendToEis: boolean) {}

    public toJson(): IDtoLotDocument {
        return {
            file: this.file.toJson(),
            type: this.type,
            sendToEis: this.sendToEis,
        };
    }

    public static fromJson(dto: IDtoLotDocument): LotDocument {
        return Object.assign(Object.create(LotDocument.prototype), {
            file: File.fromJson(dto.file),
            type: dto.type,
            sendToEis: dto.sendToEis,
        });
    }
}
