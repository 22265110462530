
















import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { IObjectDeletionModalArgs } from "@/views/modals/ModalArgs";
import { PurchaseObject } from '../../../models';
import { ObjectStatusStrings } from '../../../models/enums';

@Component({ components: { CardModal } })
export default class ObjectCancellationModal extends ModalVue<null, null> {
    @Prop() public prop!: PurchaseObject;

    public confirm() {
        this.$emit("close-modal", ModalResult.ok(true));
    }
}
