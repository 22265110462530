import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { IColumn, ISetValueEvent } from "@/components/vue/form-table/FormFields";
import { LotDocumentTypeStrings, LotDocumentType, LotDocumentAllowedFiles, LotDocumentAllowedFilesGeneric } from "@/models/enums/Documents/LotDocumentType";
import { toSelectOptions, ProviderSelectionType } from "@/models/enums";
import { ModalResult } from "@/view-models/ModalRequest";
import { LotDocument, File as MFile } from '@/models/Documents';
import { validationResult } from '@/components/TableAbstractions';
import { Lot } from '@/models';

@Component({ components: { CardModal, TreeView, FormTable, DataTable } })
export default class UploadDocumentModal extends Vue {
    @Prop() private prop!: Lot;
    @Prop() private visible!: boolean;

    private source = new LotDocument(new MFile(null!, 0, "", ""), null!, false);
    
    public get isFileTypeValid() {
        const p = this.source;
        const types = LotDocumentAllowedFiles[p.type] || LotDocumentAllowedFilesGeneric;
        return !p.file.fileName || !!types.find(x => p.file.fileName.endsWith("." + x));
    }

    public formColumns: IColumn<LotDocument>[] = [
        {
            rows: [
                {
                    title: "Тип документа",
                    type: "SELECT",
                    selectOptions: 
                        toSelectOptions(LotDocumentTypeStrings, true, "Выберите тип документа")
                            .map(x => ({
                                ...x,
                                disabled: 
                                    this.prop.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE && 
                                        (x.key === LotDocumentType.CONTRACT_PROJECT || x.key === LotDocumentType.TECH_SPECS)
                            })),

                    getter(src) {
                        return src.type;
                    },
                    setter(src, type: LotDocumentType) {
                        src.type = type;
                    },
                },
                {
                    title: "Примечание",
                    type: "MULTILINE_TEXT",

                    getter(src) {
                        return src.file.comment;
                    },
                    setter(src, val: string) {
                        src.file.comment = val;
                    },
                },
                {
                    title: "Документ",
                    type: "FILE",

                    getter() {
                        return null;
                    },
                    setter(src, val: File | undefined) {
                        if (val) {
                            src.file.fileName = val.name;
                            src.file.length = val.size;
                        } else {
                            src.file.fileName = "";
                            src.file.length = 0;
                        }
                    },
                },
                {
                    type: "TEXT",
                    titleWhitespace: "pre-line",
                    title(p) {
                        const types = LotDocumentAllowedFiles[p.type] || LotDocumentAllowedFilesGeneric;
                        return "Допустимые типы файлов: " + types.join(", ") + ".\nМаксимальный размер файла - 50 МБ";
                    },
                    editable: false,

                    getter() { return null },
                    setter() {},
                    validate: () => validationResult(this.isFileTypeValid, "Выбран файл недопустимого типа или размера")
                },
            ],
        },
    ];

    public selectItemAndExit() {
        const typesToSendToEis = [
            LotDocumentType.CONTRACT_PROJECT, 
            LotDocumentType.TECH_SPECS,
            LotDocumentType.MSV_CALC, 
            LotDocumentType.MSV_PROTOCOL]; 

        this.source.sendToEis = typesToSendToEis.includes(this.source.type);            

        this.$emit("close-modal", ModalResult.ok(this.source));
    }
}
