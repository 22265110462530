import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { Lot } from "@/models/Lot";
import { ObjectStatus } from '@/models/enums';
import { LotDocument } from '@/models/Documents';

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Lot>> {
        return convertPaged(
            await http.getPaged("/api/lots", {
                from: from,
                count: count,
                filters: filters
            }),
            Lot,
        );
    }

    public static async getById(id: string): Promise<Lot> {
        return Lot.fromJson(await http.get(`/api/lots/${id}`));
    }

    public static async create(obj: Lot): Promise<Lot> {
        return Lot.fromJson(await http.post(`/api/lots/create?law=${obj.law}`, obj.toJson()));
    }

    public static async addDocument(id: string, doc: LotDocument) {
        await http.post(`/api/lots/${id}/addDocument`, doc.toJson());
    }

    public static async update(obj: Lot, tradeMode = false): Promise<Lot> {
        return Lot.fromJson(await http.put(`/api/lots/${obj.id}?tradeMode=${tradeMode}`, obj.toJson()));
    }

    public static async delete(id: string): Promise<void> {
        await http.delete(`/api/lots/${id}`);
    }

    public static async setStatus(id: string, status: ObjectStatus): Promise<Lot> {
        return Lot.fromJson(await http.put(`/api/lots/${id}/Status/${status}`, null));
    }

    public static async sendToGrbs(id: string, doc: LotDocument): Promise<Lot> {
        return Lot.fromJson(await http.put(`/api/lots/${id}/SendToGrbs/`, doc));
    }
}
