import { BankAccount } from "@/models/BankAccount";
import { IDtoAccount } from "@/models/json";
import { IDtoContractParticipantAccount } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractParticipantAccount implements IJsonFormattable<IDtoContractParticipantAccount> {
    constructor(
        public account: BankAccount,
        public selected: boolean,
        public provision: boolean,
    ) {}
    
    public toJson(): IDtoContractParticipantAccount {
        return {
            account: this.account.toJson(),
            selected: this.selected,
            provision: this.provision,
        };
    }
    
    public static fromJson(dto: IDtoContractParticipantAccount): ContractParticipantAccount {
        return Object.assign(Object.create(ContractParticipantAccount.prototype), {
            account: BankAccount.fromJson(dto.account),
            selected: dto.selected,
            provision: dto.provision,
        });
    }
}
