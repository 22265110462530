import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/vue/Card.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, IRowConfiguration, MultiSelection } from "@/components/TableAbstractions";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import { PurchaseObjectDetail, Specification, Characteristic } from "@/models";

import { formatNumber } from "@/NumberFormatting";
import { CharacteristicValueType, CharacteristicConditionTypeStrings, ObjectStatus } from "@/models/enums";
import SpecificationDetailsModal from '@/views/modals/SpecificationDetailsModal/SpecificationDetailsModal.vue';
import EventBus from '@/EventBus';
import Decimal from 'decimal.js';

import ImportSpecsModal from './ImportSpecsModal.vue';

interface IWrappedSpec {
    id: number
    spec: Specification
}

@Component({ components: { DataTable, Card } })
export default class DetailedSpecificationsSection extends Vue {
    @Prop() private source!: PurchaseObjectDetail;
    @Prop({ default: true }) private hideButton!: boolean;
    @Prop() private state!: FieldState;

    private get readonly() {
        return this.state === "READONLY";
    }

    public selection: MultiSelection<{ id: number }> = { items: [], tag: "MULTI" };

    public get specs() {
        return this.source.specifications.map((x, i) => ({ spec: x, id: i }));
    }

    public async openModal(v: {spec: Specification; id: number}) {
        await EventBus.callModal(SpecificationDetailsModal, v);
    }

    private createHeaders(): ITableColumn<IWrappedSpec>[] {
        const rdonlyPart: ITableColumn<IWrappedSpec>[] = [
            {
                title: "#",
                getter(_, i) {
                    return i + 1 + "";
                },
                size: "0.5fr",
                headerAlignment: "center",
            },
            {
                title: "СПГЗ",
                url: (v: {spec: Specification; id: number}) => () => {
                    if (this.readonly) {
                        return this.openModal(v);
                    } else {
                        return null;
                    }
                },
                getter(v) {
                    return v.spec.subjectDeclaration?.subject ?? "";
                },
                size: "5fr",
                headerAlignment: "center",
            }, {
                title: "Объем",
                visible: () => {
                    return !this.source.isPerItemPurchase;
                },
                getter(v) {
                    return formatNumber(v.spec.volume);
                },
                size: "1fr",
                headerAlignment: "center",
            },
            {
                title: "Цена за единицу",
                getter: (v) => {
                    return formatNumber(v.spec.startPrice ? v.spec.startPrice.perItem :
                        this.source.isPerItemPurchase ? v.spec.singleItemPrice.add(v.spec.sparePartsPrice) : new Decimal(0));
                },
                size: "2.5fr",
                headerAlignment: "center",
            },
            {
                title: "Сумма, руб.",
                getter(v) {
                    return formatNumber(v.spec.startPrice ? v.spec.startPrice.multiplied : 0);
                },
                size: "1.5fr",
                headerAlignment: "center",
            },
        ];

        if (this.state !== FieldState.READONLY) {
            rdonlyPart.push({
                title: "Ред.",
                action: () => ({
                    id: "edit",
                    icon: {
                        type: "CLASS",
                        name: "edit",
                    },
                }),

                getter() {
                    return "";
                },
                size: "1fr",
                headerAlignment: "center",
            });

            rdonlyPart.push({
                title: "НМЦ",
                action: () => ({
                    id: "calculate",
                    icon: {
                        type: "FONT",
                        name: "calculator",
                    },
                }),

                getter() {
                    return "";
                },
                size: "1fr",
                headerAlignment: "center",
            });
        } else {
            rdonlyPart.push({
                title: "Печать",
                action: () => ({
                    id: "print",
                    icon: {
                        type: "CLASS",
                        name: "save",
                    },
                    title: "распечатать",
                    color: "green"
                }),

                getter() {
                    return "";
                },
                size: "auto",
                headerAlignment: "center",
            });
        }

        return rdonlyPart;
    }

    public headers = this.createHeaders();
    public config: IRowConfiguration<IWrappedSpec> = {
        selection: {
            showSelectionColumn: false,
            background: "#eee"
        },
        showJoinedRow(v) {
            return !!v.spec.subjectDeclaration?.characteristics.length;
        },
    };

    public async removeItems() {
        for (const item of this.selection.items)
            this.source.specifications.splice(item.id, 1);
        this.selection.items = [];
    }

    public formatCharDesc(char: Characteristic) {
        switch (char.valueType) {
            case CharacteristicValueType.TEXT:
                return char.enumValues.join("; ");
            case CharacteristicValueType.NUMBER:
                return CharacteristicConditionTypeStrings[char.conditionType] + " " + char.defaultValue;
        }
        return "?";
    }

    public formatChar(char: Characteristic) {
        return char.name + ": " + this.formatCharDesc(char);
    }

    public additionalChars(spec: Specification): Characteristic[] {
        return spec.subjectDeclaration?.characteristics.filter(x => x.isAdditional) ?? [];
    }

    public exportSpecs() {
        const a = document.createElement("a");

        const specs = JSON.stringify(this.specs.map(x => x.spec.toJson()));
        const encodedFile = btoa(unescape(encodeURIComponent(specs)));

        a.setAttribute('download', `${this.source.regNumber}.json`);
        a.setAttribute('href', `data:application/json;base64,${encodedFile}`);

        a.click();
    }

    public importSpecs() {
        EventBus.callModal(ImportSpecsModal, this.source);
    }
}
