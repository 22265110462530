








import { Component } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { Terms } from "@/models";
import { TermsDuration, TermsPeriod } from "@/models/enums";
import { mixins } from "vue-class-component";
import DecimalField from "@/components/vue/form-table/DecimalField.vue";
import { DateTimeRange } from '@/models/DateTimeRange';

import { DateRangeRow } from "@/components/vue/form-table/FormFields";
import { isAfter, isBefore } from 'date-fns';

@Component({ components: { DecimalField } })
export default class DateRangeField extends mixins<MutatingFieldBase<DateRangeRow<unknown>>>(MutatingFieldBase) {
    private get range(): DateTimeRange {
        return this.row.getter(this.source) as DateTimeRange;
    }

    public get endRangeStart() {
        if (this.row.rangeStart) {
            const date = this.row.rangeStart(this.source);

            return this.from
                ? date && isAfter(date, this.from) ? date : this.from
                : date;
        }

        return this.from;
    }

    public get startRangeEnd() {
        if (this.row.rangeEnd) {
            const date = this.row.rangeEnd(this.source);

            return this.to
                ? date && isBefore(date, this.to) ? date : this.to
                : date;
        }

        return this.to;
    }

    public get from() { return this.range ? this.range.from : null }
    public set from(v) {
        if (v === null) return;

        if (this.range) {
            this.$emit("set-value", { row: this.row, value: new DateTimeRange(v, this.range.to) });
        } else {
            this.$emit("set-value", { row: this.row, value: new DateTimeRange(v, null!) });
        }
    }

    public get to() { return this.range ? this.range.to : null }
    public set to(v) {
        if (v === null) return;

        if (this.range) {
            this.$emit("set-value", { row: this.row, value: new DateTimeRange(this.range.from, v) });
        } else {
            this.$emit("set-value", { row: this.row, value: new DateTimeRange(null!, v) });
        }
    }
}
