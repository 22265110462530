import { createRelatedObjects } from "@/models/RelatedObjects";
import LotGrbsConfirmModal from '@/views/modals/LotGrbsConfirmModal/LotGrbsConfirmModal.vue';
import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";

import FormDeclarationBuilder from "@/form-declarations/lot";

import { Lot } from "@/models/Lot";
import Lots from "@/api/Lots";
import router from "@/router";
import { ObjectStatus, LotStatusStrings, LawType } from "@/models/enums";
import EventBus from "@/EventBus";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import ObjectDeletionModal from "../modals/ObjectDeletionModal";
import LotCancellationModal from "../modals/LotCancellationModal";
import Participants from '@/api/Participants';
import {DtoApiErrorV1} from '@/api/ApiError';
import Auth from '@/api/Auth';
import Contracts from '@/api/Contracts';

@Component({
    components: {
        FormBase
    },
})
export default class LotDetails extends Vue {
    public source: Lot | null = null;

    @Prop() private objectId!: string;
    private formDeclaration = FormDeclarationBuilder(true, 
        Auth.getUserInfoIfRequired().then(x => Participants.getSingleProviderReasons(x.selectedParticipant.id)));

    private setSource(object: Lot) {
        this.source = object;
        this.formDeclaration.header = "Лот - " + object.regNumber + " - " + LotStatusStrings[object.status];

        this.formDeclaration.relatedObjects = createRelatedObjects(object);
    }

    public mounted() {
        this.formDeclaration.header = "Лот";
        Lots.getById(this.objectId).then(object => this.setSource(object));
    }

    public async onAction(action: string) {
        switch (action) {
            case "edit":
                router.push(`/plan-objects/lots/${this.objectId}/edit`);
                break;
            case "delete":
                {
                    const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                        title: "Подтверждение удаления лота",
                        description: "Вы действительно хотите удалить лот?",
                    });
                    if (result.isOk && result.getResult()) {
                        Lots.delete(this.objectId).then(() => router.push("/"));
                    }
                }
                break;
            case "clone":
                router.push(`/plan-objects/lots/${this.objectId}/clone`);
                break;
            case "cancel":
                {
                    const result = await EventBus.callModal(LotCancellationModal, this.source);

                    if (result.isOk && result.getResult())
                        Lots.setStatus(this.source!.id!, ObjectStatus.CANCELED).then(response => this.setSource(response));
                }
                break;
            case "send-to-grbs":
                EventBus.callModal(LotGrbsConfirmModal, this.objectId).then(result => {
                    if (result.isOk && result.getResult()) {
                        const lot = result.getResult();

                        if (lot instanceof Lot) this.setSource(lot);
                        else EventBus.raiseError(lot as DtoApiErrorV1);
                    }
                });
                break;
            case "cancel-grbs-agreement":
                Lots.setStatus(this.objectId!, ObjectStatus.CREATING)
                    .then(x => this.setSource(x));
                break;
            case "grbs-agree":
                Lots.setStatus(this.objectId!, ObjectStatus.GRBS_AGREED)
                    .then(x => this.setSource(x));
                break;
            case "approve":
                Lots.setStatus(this.objectId!, ObjectStatus.APPROVED)
                    .then(x => this.setSource(x));
                break;
            case "create-procedure":
                const law = this.source!.law === LawType.F44 ? "44" : "223";
                router.push(`/procedures/create${law}proc?lotId=${this.objectId}`);
                break;
            case "create-contract":
                const contract = await Contracts.createFromLot(this.source!.id!);
                router.push(`/contracts/${contract.id}`);
                break;
            case "close":
                router.replace("/plan-objects/lots");
                break;
        }
    }
}
