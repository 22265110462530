import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import Card from "@/components/vue/Card.vue";
import FormBase from "@/views/FormBase/FormBase.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import Term from "@/models/PurchaseObjectDetailedTerm";
import PurchaseObjectDetailedTermAddModal from "@/views/modals/PurchaseObjectDetailedTermAddModal";
import EventBus from "@/EventBus";

@Component({ components: { DataTable, Card, FormBase, PurchaseObjectDetailedTermAddModal } })
export default class DictionarySection extends Vue {
    @Prop() private source!: unknown;
    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: true }) private hideButton!: boolean;

    private isTermModal = true;

    private selectOptions = [
        {
            key: "",
            desc: "",
        },
    ];

    private headers: ITableColumn<Term>[] = [
        {
            title: "",
            getter(v) {
                return v.term;
            },
        },
        {
            title: "",
            getter(v) {
                return v.description;
            },
        },
    ];

    private dataset: Term[] = [];

    public mounted() {}

    public async addObject() {
        const result = await EventBus.callModal(PurchaseObjectDetailedTermAddModal, null);
        const newTerm = result.getResult();
        let flag = true;
        let rand = "";

        do {
            rand = (Math.random() * (1000 - 1) + 1).toFixed(0);
            this.dataset.forEach(element => {
                if (element.id === rand) flag = false;
            });
        } while (!flag);

        newTerm.id = "term_" + rand;
        if (result.isOk) this.dataset.push(newTerm);
        this.selectOptions.push({
            key: newTerm.id,
            desc: newTerm.term + " - Проект",
        });
    }

    public onTermSelectChanged(e: string) {
        // TODO: Handle.
    }

    public addTerm() {
        this.isTermModal = true;
    }
    private selectSubjClass() {}

    private removeObject(a: Term) {}
}
