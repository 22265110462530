import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { Lot } from "@/models";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import Decimal from "decimal.js";
import { formatNumber } from "@/NumberFormatting";

@Component({ components: { DataTable } })
export default class StartPriceSection extends Vue {
    @Prop() public source!: Lot;
    @Prop() public state!: FieldState;

    public get startPrice() {
        return formatNumber(
            this.source.purchaseObjectDetails!
                .flatMap(x => x.detail.specifications)
                .reduce((p, v) => p.add(v.startPrice ? v.startPrice.multiplied : new Decimal(0)), new Decimal(0)),
        );
    }
}
