import { render, staticRenderFns } from "./ProvisionSection.vue?vue&type=template&id=662443b5&scoped=true&"
import script from "./ProvisionSection.ts?vue&type=script&lang=ts&"
export * from "./ProvisionSection.ts?vue&type=script&lang=ts&"
import style0 from "./ProvisionSection.vue?vue&type=style&index=0&id=662443b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662443b5",
  null
  
)

export default component.exports