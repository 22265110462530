






























import { ModalResult } from "@/view-models/ModalRequest";

import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { PurchaseObjectDetail, Specification, SupplyStage } from '@/models';
import { SelectOption, createYearSelection, SupplyStagePeriodType, DayType } from '@/models/enums';
import { makeFormHelpers } from '@/form-declarations/helpers';
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';
import Decimal from 'decimal.js';
import { IRow } from '@/components/vue/form-table/FormFields';
import { IDtoSpecification } from '@/models/json';
import EventBus from '@/EventBus';
import ImportErrorsModal from './ImportErrorsModal.vue';

const h = makeFormHelpers<ContractSpecificationSupplyStage>();

type SupplyStageImportType = "KEEP" | "SET_FOR_EACH" | "SET_FOR_ALL";

@Component({ components: { FormTable, CardModal } })
export default class ImportSpecsModal extends Vue {
    @Prop() public prop!: { detail: PurchaseObjectDetail; specs: Specification[] };

    public isSpecGood(spec: Specification) {
        if (!this.prop.detail.object.subjectClass) return true;

        const code = spec.subjectDeclaration?.subjectClass.code ?? "";
        const objCode = this.prop.detail.object.subjectClass.code;

        return code.startsWith(objCode);
    }

    public goodSpecs = this.prop.specs.filter(this.isSpecGood);
    public badSpecs = this.prop.specs.filter(x => !this.isSpecGood(x));

    public saveAndClose() {
        this.prop.detail.specifications = this.goodSpecs;
        this.$emit("close-modal", ModalResult.ok(null));
    }

    public showBad() {
        EventBus.callModal(ImportErrorsModal, this.badSpecs);
    }
}
