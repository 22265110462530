import { FilterConditionType, FilterValueType } from "@/api/http";
import Lots from "@/api/Lots";
import { createPaginationContext, PaginationContext } from "@/api/Pagination";
import Plans from "@/api/Plans";

import Card from "@/components/vue/Card.vue";
import DataTable from "@/components/vue/DataTable.vue";

import { ITableColumn } from "@/components/TableAbstractions";
import { formatDate } from "@/DateFormatting";
import EventBus from "@/EventBus";

import { Lot, SchedulePlan as Plan } from "@/models";
import { LawType, LawTypeStrings, ObjectStatus, ProviderSelectionTypeStrings, SmpTypeStrings } from "@/models/enums";
import router from "@/router";

import ChangeHistorySection from "@/views/form-renderers/ChangeHistorySection";
import ObjectDeletionModal from "@/views/modals/ObjectDeletionModal";
import EisWarning from "@/views/SchedulePlan/EisWarning.vue";
import SendToPublishModal from "@/views/SchedulePlan/SendToPublishModal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import Auth from '@/api/Auth';

interface IPurchaseObjectSmall {
    regNumber: string
    law: LawType
    purchaseInfo: string
    subjectClass: {
        code: string
        description: string
    }
    finCode: string
    purchaseVolume: number
    purchaseData: string
}

@Component({ components: { Card, DataTable, ChangeHistorySection } })
export default class SchedulePlan extends Vue {
    @Prop() private schedulePlanId!: string;
    private source: Plan | null = null;

    public context: PaginationContext<Lot> | null = null;

    public get customer() {
        return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName;
    }

    public get canDelete() {
        return (
            this.source &&
            (this.source.status !== ObjectStatus.GRBS_AGREEMENT_PENDING &&
                this.source.status !== ObjectStatus.PUBLISHED)
        );
    }

    public async mounted() {
        Plans.getSchedulePlanById(this.schedulePlanId).then(object => {
            this.source = object;
        });
        this.context = await createPaginationContext(
            (n, sz, f) =>
                Lots.get(n, sz, {
                    ...f,
                    planId: [
                        {
                            type: FilterValueType.GUID,
                            conditionType: FilterConditionType.EQUAL,
                            guid: this.schedulePlanId,
                        },
                    ],
                }),
            10,
        );
    }

    private lotTableHeaders: ITableColumn<Lot>[] = [
        {
            title: "Регистровый номер",
            getter(v) {
                return v.regNumber;
            },
        },
        {
            title: "Закон-основание",
            getter(v) {
                return v && LawTypeStrings[v.law];
            },
        },
        {
            title: "Наименование лота",
            getter(v) {
                return v.subject;
            },
        },
        {
            title: "НМЦ контракта",
            getter(v) {
                return v.totalStartPrice;
            },
        },
        {
            title: "Способ определения поставщика",
            getter(v) {
                return ProviderSelectionTypeStrings[v.providerSelectionType!];
            },
        },
        {
            title: "Дата начала закупки",
            getter(v) {
                return formatDate(v.contractTerms!);
            },
        },
        {
            title: "Размещение среди СМП/МСП",
            getter(v) {
                return SmpTypeStrings[v.smpType];
            },
        },
        {
            title: "Замечание",
            getter(v) {
                return "";
            },
        },
    ];
    public schedulePlanAsuGfTableHeaders: ITableColumn<Plan>[] = [
        {
            title: "№",
            getter: () => "1",
            size: "auto",
        },
        {
            title: "Исходящий файл",
            getter: () => "Request.xml",
            url: () => () => {},
        },
        {
            title: "Дата отправки",
            getter: x => (x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""),
        },
        {
            title: "Подтверждение получения",
            getter: () => "Ticket.xml",
            url: () => () => {},
        },
        {
            title: "Дата приема подтверждения",
            getter: x => (x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""),
        },
        {
            title: "Сведения о согласовании",
            getter: () => "Protocol.xml",
            url: () => () => {},
        },
        {
            title: "Дата получения сведений",
            getter: x => (x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""),
        },
        {
            title: "Дополнительные сведения о согласовании",
            getter: () => "PositiveProtocol.xml",
            url: () => () => {},
        },
        {
            title: "Дата получения дополнительных сведений",
            getter: x => (x && x.grbsAgreementDate ? formatDate(x.grbsAgreementDate, true) : ""),
        },
    ];

    public schedulePlanEisTableHeaders: ITableColumn<Plan>[] = [
        {
            title: "Номер",
            getter: () => "1",
            size: "auto",
        },
        {
            title: "Исходящий файл",
            getter: () => "xml_to_oos.xml",
            url: () => () => {},
        },
        {
            title: "Дата отправки",
            getter: x => (x && x.publishDate ? formatDate(x.publishDate, true) : ""),
        },
        {
            title: "Подтверждение получения",
            getter: () => "xml_from_oos.xml",
            url: () => () => {},
        },
        {
            title: "Дата приема подтверждения",
            getter: x => (x && x.publishDate ? formatDate(x.publishDate, true) : ""),
        },
        {
            title: "Сведения о публикации",
            getter: () => "xml_from_oos_publ.xml",
            url: () => () => {},
        },
        {
            title: "Дата получения сведений",
            getter: x => (x && x.publishDate ? formatDate(x.publishDate, true) : ""),
        },
    ];

    public get law() {
        return this.source ? LawTypeStrings[this.source.law] : "";
    }

    public setStatus(status: ObjectStatus) {
        Plans.setSchedulePlanStatus(this.source!.id, status).then(r => router.go(0));
    }

    public async deletePlan() {
        const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
            title: "Удаление",
            description: "Вы действительно хотите удалить план-график?",
        });
        if (result.isOk && result.getResult()) {
            Plans.deleteSchedulePlan(this.source!.id).then(() => router.back());
        }
    }

    public close() {
        router.back();
    }

    public async sentToPublish() {
        if (this.source && this.source.law === LawType.F223) {
            const result = await EventBus.callModal(SendToPublishModal, {});

            if (!result.isOk || !result.getResult()) return;
        }

        await this.setStatus(ObjectStatus.PUBLISHED);
    }

    public linkToEis() {
        EventBus.callModal(EisWarning, {});
    }
}
