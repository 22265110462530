


















import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import TextField from "@/components/vue/form-table/TextField.vue";
import PurchaseObjectDetailedTerm from "@/models/PurchaseObjectDetailedTerm";
import { formatNumber } from "../../../NumberFormatting";

@Component({ components: { CardModal, TextField } })
export default class PurchaseObjectDetailedTermAddModal extends ModalVue<null, PurchaseObjectDetailedTerm> {
    private term!: string;
    private desc!: string;
    public inputedTermin!: PurchaseObjectDetailedTerm;

    public confirm() {
        this.inputedTermin = new PurchaseObjectDetailedTerm("", this.term, "Проект");
        this.$emit("close-modal", ModalResult.ok(this.inputedTermin));
    }

    public cancel() {
        this.$emit("close-modal");
    }
    public termNameInputAction(value: string) {
        this.term = value;
    }
    public termDescInputAction(value: string) {
        this.desc = value;
    }
}
