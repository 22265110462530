



























import EventBus from "@/EventBus";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import EisLogin from "@/views/SchedulePlan/EisLogin.vue";
import EisWarning from "@/views/SchedulePlan/EisWarning.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import CardModal from "../../components/CardModal.vue";

@Component({ components: { CardModal } })
export default class SendToPublishModal extends ModalVue<unknown, unknown> {
    @Prop() private prop!: unknown;

    private warn() {
        EventBus.callModal(EisWarning, {});
    }

    private async login() {
        const result = await EventBus.callModal(EisLogin, {});

        if (result.isOk && result.getResult()) return this.$emit("close-modal", ModalResult.ok(true));
        else this.$emit("close-modal");
    }
}
