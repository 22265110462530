import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { ObjectStatus } from '@/models/enums';
import { Procedure } from '@/models/Procedure';
import { PublishTerms } from '@/models/PublishTerms';

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Procedure>> {
        return convertPaged(
            await http.getPaged("/api/procedures", {
                from: from,
                count: count,
                filters: filters
            }),
            Procedure,
        );
    }

    public static async getById(id: string): Promise<Procedure> {
        return Procedure.fromJson(await http.get(`/api/procedures/${id}`));
    }

    public static async create(obj: Procedure): Promise<Procedure> {
        return Procedure.fromJson(await http.post(`/api/procedures/create`, obj.toJson()));
    }

    public static async update(obj: Procedure): Promise<Procedure> {
        return Procedure.fromJson(await http.put(`/api/procedures/${obj.id}`, obj.toJson()));
    }

    public static async delete(id: string): Promise<void> {
        await http.delete(`/api/procedures/${id}`);
    }

    public static async setStatus(id: string, status: ObjectStatus): Promise<Procedure> {
        return Procedure.fromJson(await http.put(`/api/procedures/${id}/Status/${status}`, null));
    }

    public static async attachAutoDocument(id: string): Promise<void> {
        await http.post(`/api/procedures/${id}/AttachAutoDocument`, null);
    }

    public static async setPublishTerms(id: string, terms: PublishTerms): Promise<Procedure> {
        return Procedure.fromJson(await http.post(`/api/procedures/${id}/PublishTerms`, terms.toJson()));
    }
}
