import { Converter } from './helpers';
import { FileTableInfo } from './raw-rows/FileTableRow/FileTableRow';
import { LotDocument, ProcedureDocument } from '@/models/Documents';
import { 
    LotDocumentType, 
    LotDocumentTypeStrings, 
    ProcedureDocumentType, 
    Procedure44DocumentTypeStrings, 
    Procedure223DocumentTypeStrings, 
    ProcedureDocumentProvStrings 
} from '@/models/enums/Documents';
import UploadDocumentModal from '@/views/modals/UploadDocumentModal/UploadDocumentModal';
import UploadProcedureDocumentModal from '@/views/modals/UploadProcedureDocumentModal/UploadProcedureDocumentModal.vue';
import { Procedure } from '@/models/Procedure';
import { EditType } from '@/components/TableAbstractions';
import { Lot } from '@/models';
import {EnumStrings, LawType} from '@/models/enums';

export const lotDocsToFileTableInfo: Converter<Lot, FileTableInfo<Lot, LotDocumentType, LotDocument>> = {
    convert(v) { 
        return { 
            groups: [{ source: v, documents: v.lotDocuments }],
            strings: LotDocumentTypeStrings,
            creationModal: UploadDocumentModal,
            creationModalProp: v
        };
    },
    convertBack(v) { return null! }
};

export function procDocsToFileTableInfo(readonly: boolean): Converter<Procedure, FileTableInfo<Procedure, ProcedureDocumentType, ProcedureDocument>> {
    return { 
        convert(v) { 
            const strs = v.law === LawType.F44 ? Procedure44DocumentTypeStrings : Procedure223DocumentTypeStrings;
            return { 
                groups: [{ source: v, documents: v.documents }],
                strings: (x, s) => 
                    x.type === ProcedureDocumentType.PROCEDURE_DOCS && v.law === LawType.F44
                        ? ProcedureDocumentProvStrings[s.providerSelectionType] 
                        : (strs as EnumStrings)[x.type],
                creationModal: UploadProcedureDocumentModal,
                creationModalProp: strs,
                additionalHeadersAfter: [{
                    type: readonly ? undefined : EditType.CHECK,
                    editable: r => r.document.type !== ProcedureDocumentType.PROCEDURE_DOCS,
                    title: "Отправить в ЕИС/ЭТП",
                    getter(l) { return readonly ? l.document.sendToEis ? 'Да' : 'Нет' : l.document.sendToEis },
                    setter: readonly ? () => {} : (l, s: boolean) => l.document.sendToEis = s,
                }],
                itemEnabled(r) { return r.type !== ProcedureDocumentType.PROCEDURE_DOCS }
            };
        },
        convertBack(v) { return null! }
    };
}
