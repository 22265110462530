import NavPanel from "@/components/NavPanel.vue";
import { PurchaseObjectDetail, Specification, StartPrice } from "@/models";
import { IDtoPurchaseObjectDetail } from "@/models/json";
import { RoutedModal } from "@/routed-modals";
import router from "@/router";
import Decimal from "decimal.js";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IStartPriceCalculationModalArgs } from "../RoutedModalArgs";
import { StartPriceMethod } from '@/models/enums/StartPriceMethod';

import CommonPage from './pages/CommonPage.vue';
import MarketPricesPage from './pages/MarketPricesPage.vue';

interface IMethod {
    id: string
    methodName: string
    applicable: "APPLICABLE" | "REQUIRES_ADDITIONAL_CALC" | "NOT_APPLICABLE"
    optRatioUnallowed?: boolean

    calculatedExternally: boolean
    price: Decimal
    optRatio: Decimal
}


class RoutedModalT extends RoutedModal<IStartPriceCalculationModalArgs, IDtoPurchaseObjectDetail> {}

@Component({ components: { 
    NavPanel,
    CommonPage,
    MarketPricesPage
} })
export default class StartPriceCalculation extends mixins(RoutedModalT) {
    public detail!: PurchaseObjectDetail;
    public specification!: Specification;

    public marketPricesResult: Decimal | null = null;

    public startPrice = new StartPrice(
        null!,
        null!,
        new Decimal(0),
        null!,
        new Decimal(1),
        null,
        null
    );

    public commitChanges = false;

    public created() {
        if (this.routedProp !== null) {
            this.detail = PurchaseObjectDetail.fromJson(this.routedProp!.object);
            this.specification = Specification.fromJson(this.routedProp!.specification);
        } else {
            router.replace("/");
        }
    }

    public page = "COMMON";
    public navSections = [
        { anchor: "COMMON", title: "НМЦ СПГЗ", active: true },
        { anchor: "MARKET_PRICES", title: "Метод анализа рыночной стоимости закупаемых товаров, работ, услуг", active: false },
    ];

    public get pageProps() {
        return {
            detail: this.detail,
            specification: this.specification,
            startPrice: this.startPrice,
            marketPricesResult: this.marketPricesResult
        };
    }

    public selectPage(page: string) {
        for (const sec of this.navSections) {
            sec.active = sec.anchor === page;
        }
        this.page = page;
    }

    public setMarketPricesResult(result: Decimal) {
        this.marketPricesResult = result;
        this.startPrice.method = StartPriceMethod.MARKET_PRICES;
    }

    protected saveState(callback: (r: IDtoPurchaseObjectDetail) => void) {
        if (this.commitChanges) {
            this.detail!.specifications[this.routedProp!.specIndex].startPrice = this.startPrice;
        }
        callback(this.detail!.toJson());
    }

    public back(save: boolean) {
        this.commitChanges = save;
        router.back();
    }
}
