import { IDtoPurchaseObjectDetail, IDtoSpecification } from "@/models/json";

export const enum ObjectActionType {
    CREATE = "CREATE",
    EDIT = "EDIT",
    CLONE = "CLONE",
}

export declare interface IStartPriceCalculationModalArgs {
    object: IDtoPurchaseObjectDetail
    specification: IDtoSpecification
    specIndex: number
    actionType: ObjectActionType
}
