
















import { ModalResult } from "@/view-models/ModalRequest";

import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { PurchaseObjectDetail, Specification, SupplyStage } from '@/models';
import { SelectOption, createYearSelection, SupplyStagePeriodType, DayType } from '@/models/enums';
import { makeFormHelpers } from '@/form-declarations/helpers';
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';
import Decimal from 'decimal.js';
import { IRow } from '@/components/vue/form-table/FormFields';
import { IDtoSpecification } from '@/models/json';
import EventBus from '@/EventBus';
import ImportResultModal from './ImportResultModal.vue';

import { hs } from '@/components/TableHelpers';

@Component({ components: { FormTable, CardModal } })
export default class ImportSpecsModal extends Vue {
    @Prop() public prop!: Specification[];

    public tableHeaders = hs<Specification>(
        hs.any("#", (_, n) => (n + 1).toString(), "1fr"),
        hs.any("Наименование СПГЗ", s => s.subjectDeclaration?.subject ?? "", "5fr"),
    );
}
