import {Component, Vue, Watch} from "vue-property-decorator";
import Sidebar from "@/components/Sidebar.vue";
import NsiSidebar from "@/components/NsiSidebar.vue";
import ShopSidebar from "@/components/ShopSidebar.vue";
import Header from "@/components/Header.vue";
import LoginPage from "@/LoginPage.vue";
import RegisterPage from "@/RegisterPage.vue";
import EventBus from "@/EventBus";
import {IHint} from "@/components/vue/form-table/FormFields";
import router from "@/router";
import {ModalRequest, ModalResult} from "@/view-models/ModalRequest";
import Auth, {userInfoStore} from './api/Auth';
import {User} from './models/User';
import {Subscription} from "effector";
import ProvisionSidebar from "@/components/ProvisionSidebar.vue";
import {ToastWrapper} from "./components/toasts/toastWrapper";
import {ReactWrapper} from "vuera";

@Component({ components: { Header, Sidebar, NsiSidebar, ShopSidebar, LoginPage, RegisterPage, ProvisionSidebar, ReactWrapper } })
export default class App extends Vue {
    private toastWrapper = ToastWrapper;

    private hint: IHint | null = null;
    private hintPosition: { x: number; y: number } = { x: 0, y: 0 };
    private router = router;
    private collapsed = false;

    public spinnerVisible = false;
    private spinnerPromises: Array<Promise<unknown>> = [];

    public get user(): User | null { return Auth.currentUserInfo }

    private modalList: Array<ModalRequest<unknown, unknown>> = [];

    private yearRerouteSubscription?: Subscription;

    public created() {
        EventBus.$on("show-hint", this.openHint);
        EventBus.$on("request-modal", this.openModal);
        EventBus.$on("show-spinner", this.showSpinner);

        this.yearRerouteSubscription = userInfoStore.watch(x => {
            if (x.year < 2020) {
                switch (router.currentRoute.fullPath) {
                    case "/plan-objects/composed-lots":
                        router.replace("/plan-objects");
                        break;
                    case "/plans/2020":
                        router.replace("/plans/pre-2020");
                        break;
                    case "/procedures/2020":
                        router.replace("/procedures/pre-2020");
                        break;
                    case "/procedures/2020/trades":
                        router.replace("/procedures/pre-2020/trades");
                        break;
                }
            } else {
                switch (router.currentRoute.fullPath) {
                    case "/plan-objects":
                    case "/plan-objects/detailed":
                    case "/plan-objects/lots":
                        router.replace("/plan-objects/composed-lots");
                        break;
                    case "/plans/pre-2020":
                        router.replace("/plans/2020");
                        break;
                    case "/procedures/pre-2020":
                        router.replace("/procedures/2020");
                        break;
                    case "/procedures/pre-2020/trades":
                        router.replace("/procedures/2020/trades");
                        break;
                }
            }
        });
    }

    @Watch("$router.currentRoute") private dismissAllModals() {
        this.modalList = [];
    }

    public async mounted() {
        await Auth.getUserInfo();
    }

    public beforeDestroy() {
        EventBus.$off("show-hint", this.openHint);
        EventBus.$off("request-modal", this.openModal);
        EventBus.$off("show-spinner", this.showSpinner);
        this.yearRerouteSubscription?.unsubscribe();
        this.yearRerouteSubscription = undefined;
    }

    private openModal(request: ModalRequest<unknown, unknown>) {
        this.modalList.push(request);
    }

    public closeModal(request: ModalRequest<unknown, unknown>, index: number, result: ModalResult<unknown>) {
        this.modalList.splice(index, 1);
        request.callback(result || ModalResult.empty());
    }

    private openHint(hint: IHint, ev: MouseEvent) {
        ev.stopPropagation();
        this.hint = hint;
        this.hintPosition = { x: ev.pageX, y: ev.pageY };
    }

    public closeHint() {
        this.hint = null;
        EventBus.$emit("close-popup");
    }

    private showSpinner(endPromise: Promise<unknown>) {
        this.spinnerVisible = true;

        if (this.spinnerPromises.length === 0) {
            const intervalPromise = new Promise(resolve => setInterval(resolve, 500));
            this.spinnerPromises.push(intervalPromise);
            intervalPromise.finally(() => this.hideSpinner(intervalPromise));
        }

        this.spinnerPromises.push(endPromise);
        endPromise
            .catch(() => {})
            .finally(() => this.hideSpinner(endPromise));
    }

    private hideSpinner(promise: Promise<unknown>) {
        const ix = this.spinnerPromises.indexOf(promise);
        if (ix === -1) return;

        this.spinnerPromises.splice(ix, 1);
        if (this.spinnerPromises.length === 0) this.spinnerVisible = false;
    }

    public toggle() {
        this.collapsed = !this.collapsed;
    }
}
