import Card from "@/components/vue/Card.vue";
import { IColumn, TextAlignment } from "@/components/vue/form-table/FormFields";
import FormTable from "@/components/vue/FormTable.vue";
import { validationResult } from "@/components/TableAbstractions";
import Decimal from "decimal.js";
import { Component, Prop, Vue } from "vue-property-decorator";
import MarketPrices from '@/api/MarketPrices';
import { MarketPrice, SubjectDeclaration } from '@/models';
import { MarketPriceDataSource } from '@/models/enums';
import { addMonths } from 'date-fns';

export interface IAddOfferSource {
    name: string
    priceWithNds: Decimal | null
    nds: string
    provider: unknown
    providerString: string
    customPrices: Decimal | null
    startDate: Date
    file: File | null
}

const validate = (o: IAddOfferSource) =>
    validate.offerName(o) && validate.priceWithNds(o) && validate.provider(o) && validate.startDate(o) && validate.file(o);

validate.offerName = (o: IAddOfferSource) => !!o.name;
validate.priceWithNds = (o: IAddOfferSource) => !!o.priceWithNds && o.priceWithNds.isFinite();
validate.provider = (o: IAddOfferSource) => !!o.provider || !!o.providerString;
validate.startDate = (o: IAddOfferSource) => !!o.startDate;
validate.file = (o: IAddOfferSource) => !!o.file;

@Component({ components: { Card, FormTable } })
export default class NewPriceSection extends Vue {
    @Prop() public subjectDeclaration!: SubjectDeclaration;

    private offer: IAddOfferSource = {
        name: "",
        priceWithNds: null,
        nds: "0",
        provider: "",
        providerString: "",
        customPrices: null,
        startDate: null!,
        file: null
    };

    private buttons = [
        { id: "SAVE", icon: { type: "CLASS", name: "save" }, eventName: "save-offer" },
        { id: "CLOSE", icon: { type: "CLASS", name: "close" }, color: "red", eventName: "close-offer" },
    ];

    private format = { year: "numeric", month: "2-digit", day: "2-digit" };

    private columns: IColumn<IAddOfferSource>[] = [
        {
            rows: [
                {
                    title: "Наименование позиции:",
                    type: "TEXT",
                    required: true,
                    getter: o => o.name,
                    setter: (o, value) => (o.name = value),
                    validate: o => validationResult(validate.offerName(o), "Не указано наименование."),
                },
                {
                    title: "Цена за единицу с НДС:",
                    type: "DECIMAL",
                    required: true,
                    alignment: TextAlignment.LEFT,
                    getter: o => o.priceWithNds,
                    setter: (o, value) => (o.priceWithNds = value),
                    validate: o => validationResult(validate.priceWithNds(o), "Не указана цена с НДС."),
                },
                {
                    title: "НДС:",
                    type: "SELECT",
                    required: true,
                    getter: o => o.nds,
                    setter: (o, value) => (o.nds = value),

                    selectOptions: [
                        { key: "0", desc: "0% /не облагается" },
                        { key: "10", desc: "10%" },
                        { key: "20", desc: "20%" },
                    ],
                },
                {
                    title: "Подтверждающий документ:",
                    type: "FILE",
                    required: true,
                    getter: o => o.file,
                    setter: (o, f: File) => o.file = f
                },
                {
                    title: "Поставщик:",
                    type: "SELECT",
                    required: true,
                    getter: o => o.provider,
                    setter: (o, value) => (o.provider = value),
                    validate: o => validationResult(validate.provider(o), "Не указан поставщик."),

                    selectOptions: [
                        // TODO
                    ]
                },
                {
                    title: "Поставщик (Ручной ввод):",
                    type: "TEXT",
                    required: true,
                    getter: o => o.providerString,
                    setter: (o, value) => (o.providerString = value),
                    validate: o => validationResult(validate.provider(o), "Не указан поставщик."),
                },
                {
                    title: "Таможенные пошлины и сборы:",
                    type: "DECIMAL",
                    alignment: TextAlignment.LEFT,
                    getter: o => o.customPrices,
                    setter: (o, value) => (o.customPrices = value),
                },
                {
                    title: "Дата начала действия:",
                    type: "DATE_FIELD",
                    getter: o => o.startDate,
                    setter: (o, value) => (o.startDate = value),
                    validate: o => validationResult(validate.startDate(o), "Не указана дата начала действия."),
                },
            ],
        },
    ];

    private async saveOffer() {
        if (!validate(this.offer)) return;

        const priceWithTax = new Decimal(this.offer.priceWithNds!);
        const taxPercent = new Decimal(this.offer.nds);

        const endDate = addMonths(this.offer.startDate, 6);

        const marketPrice = new MarketPrice(
            null!,
            this.offer.name,
            priceWithTax.mul(taxPercent.mul(-1).add(100)).div(100),
            priceWithTax,
            this.offer.startDate,
            endDate,
            MarketPriceDataSource.PROVIDER_OFFER,
            taxPercent,
            null,
            this.offer.providerString,
            this.subjectDeclaration
        );

        this.$emit("new-offer", await MarketPrices.create(marketPrice, this.subjectDeclaration.id));
        this.$emit("close");
        this.clearMarketAddForm();
    }

    private closeOffer() {
        this.$emit("close");
        this.clearMarketAddForm();
    }

    private clearMarketAddForm() {
        this.offer = {
            name: "",
            priceWithNds: null!,
            nds: "0",
            provider: "",
            providerString: "",
            customPrices: null!,
            startDate: null!,
            file: null
        };
    }
}
