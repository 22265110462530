import ProcedurePublishConfirm from "@/views/modals/ProcedurePublishConfirm/ProcedurePublishConfirm.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import { FormDeclarationBuilder } from "@/form-declarations/procedure";
import { Procedure } from "@/models/Procedure";
import router from "@/router";
import Procedures from "@/api/Procedures";
import {
    ProcedureStatusStrings,
    ProviderSelectionTypeStrings,
    ObjectStatus,
    ProviderSelectionType,
} from "@/models/enums";
import Participants from "@/api/Participants";
import EventBus from "@/EventBus";
import ProcedurePublishDateModal from "../modals/ProcedurePublishDateModal/ProcedurePublishDateModal";
import { PublishTerms } from "@/models/PublishTerms";
import { IObjectDeletionModalArgs } from "../modals/ModalArgs";
import ObjectDeletionModal from "../modals/ObjectDeletionModal";
import { createRelatedObjects } from "@/models/RelatedObjects";

@Component({ components: { FormBase } })
export default class ProcedureDetails extends Vue {
    @Prop() public objectId!: string;
    public source: Procedure | null = null;
    public formDecl = FormDeclarationBuilder(true, {
        specialParticipants: Participants.getSpecial(0, 100).then(x => x.items),
    });

    private setSource(object: Procedure) {
        this.source = object;
        this.formDecl.header = "Процедура - " + object.regNumber + " - " + ProcedureStatusStrings[object.status];

        const procedureObj = object.relatedObjects.find(x => x.type === "PROCEDURE");
        if (procedureObj) procedureObj.type = "TRADE";

        this.formDecl.relatedObjects = createRelatedObjects(object);
    }

    public created() {
        this.formDecl.header = "Процедура";
        Procedures.getById(this.objectId).then(object => this.setSource(object));
    }

    public async onAction(action: string) {
        switch (action) {
            case "edit":
                router.replace(`/procedures/${this.objectId}/edit`);
                break;
            case "clone":
                router.replace(`/procedures/${this.objectId}/clone`);
                break;
            case "delete":
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Удаление",
                    description: "Вы уверены, что хотите удалить процедуру?",
                });
                if (result.isOk && result.getResult()) {
                    Procedures.delete(this.objectId)
                        .then(response => router.push("/"));
                }
                break;
            case "send-to-grbs":
                if (confirm("Вы уверены, что хотите отправить процедуру на согласование в РГ ГРБС?")) {
                    Procedures.setStatus(this.objectId!, ObjectStatus.GRBS_AGREEMENT_PENDING)
                        .then(response => this.setSource(response));
                }
                break;
            case "cancel-grbs-agreement":
                Procedures.setStatus(this.objectId!, ObjectStatus.CREATING)
                    .then(response => this.setSource(response));
                break;
            case "grbs-agree":
                Procedures.setStatus(this.objectId!, ObjectStatus.GRBS_AGREED)
                    .then(response => this.setSource(response));
                break;
            case "set-publish-date":
                const newTerms = await EventBus.callModal<
                    { publishTerms: PublishTerms | null; type: ProviderSelectionType },
                    PublishTerms
                >(ProcedurePublishDateModal, {
                    publishTerms: this.source!.publishTerms,
                    type: this.source!.providerSelectionType,
                });

                if (newTerms.isOk)
                    Procedures.setPublishTerms(this.objectId!, newTerms.getResult())
                        .then(response => this.setSource(response));
                break;
            case "pre-publish":
                {
                    const confirmation = await EventBus.callModal(ProcedurePublishConfirm, {});

                    if (confirmation.isOk && confirmation.getResult()) {
                        Procedures.setStatus(this.objectId!, ObjectStatus.PUBLISH_PENDING)
                            .then(response => this.setSource(response));
                    }
                }
                break;
            case "publish":
                Procedures.setStatus(this.objectId!, ObjectStatus.PUBLISHED)
                    .then(response => this.setSource(response));
                break;
            case "complete-sales":
                Procedures.setStatus(this.objectId!, ObjectStatus.SALES_FINISHED)
                    .then(response => this.setSource(response));
                break;
            case "download-docs":
                Procedures.attachAutoDocument(this.objectId)
                    .then(() => {
                        const a = document.createElement("a");

                        a.setAttribute('download', 'Документация.zip');
                        a.setAttribute('href', 'data:application/zip;base64,UEsFBgAAAAAAAAAAAAAAAAAAAAAAAA==');

                        a.click();
                    });
                break;
            case "close":
                router.back();
                break;
        }
    }
}
