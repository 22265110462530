import CardModal from "@/components/CardModal.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { Component, Prop } from "vue-property-decorator";
import { Lot } from '@/models';
import { IRow, TextAlignment } from '@/components/vue/form-table/FormFields';
import { makeFormHelpers } from '@/form-declarations/helpers';
import { LotRequestStage, LotRequestStageStrings } from '@/models/enums/LotRequestStage';

const h = makeFormHelpers<Lot["requestDocuments"][0]>();

@Component({ components: { CardModal, FormTable } })
export default class ContractRequiredDocumentModal extends ModalVue<Lot["requestDocuments"][0] | null, Lot["requestDocuments"][0]> {
    @Prop() private prop!: Lot["requestDocuments"][0] | null;

    public source = this.prop
        ? {...this.prop}
        : {
            name: "",
            order: null!,
            originalComment: "",
            comment: "",
            required: false,
            option: "ORIGINAL",
            hardcoded: false,
            originalStage: LotRequestStage.FIRST,
            stage: LotRequestStage.FIRST
        };

    public rows: IRow<Lot["requestDocuments"][0]>[] = [
        h.required().const(x => x.hardcoded).integer("Номер", "order", { alignment: TextAlignment.LEFT }),
        h.required().const(x => x.hardcoded).multiline("Наименование", "name", {
            lines: 5
        }),
        h.multiline("Примечание", "comment", {
            lines: 5
        }),
        h.visible(x => !x.hardcoded || !x.required).explicit().checkbox("Обязательность",
            x => x.option === "OPTED_IN", (x, v) => x.option = v ? "OPTED_IN" : "ORIGINAL"),
        h.select("Часть заявки", "stage", [
            { key: LotRequestStage.FIRST, desc: LotRequestStageStrings[LotRequestStage.FIRST] },
            { key: LotRequestStage.SECOND, desc: LotRequestStageStrings[LotRequestStage.SECOND] },
            { key: LotRequestStage.THIRD, desc: LotRequestStageStrings[LotRequestStage.THIRD] }
        ])
    ];

    public columns = [{ rows: this.rows }];

    public save() {
        if (this.prop) Object.assign(this.prop, this.source);
        this.$emit('close-modal', ModalResult.ok(this.source));
    }
}
