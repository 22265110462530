import { Component, Prop, Vue } from "vue-property-decorator";

import Card from "@/components/vue/Card.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import router from "@/router";
import {
    ObjectStatus, LawType, LawTypeStrings, createYearSelection,
} from "@/models/enums";
import PurchaseObjects from "@/api/PurchaseObjects";
import { PurchaseObject } from '@/models';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import Plans from '@/api/Plans';
import { FilterValueType, FilterConditionType } from '@/api/http';

@Component({ components: { Card } })
export default class CreatePurchasePlan extends Vue {
    private periodStartYear = 2019;

    private periods = createYearSelection(false).map(x => ({...x, desc: `${x.key} - ${x.key && x.key + 2}`}));

    private purchaseObjectTableHeader: ITableColumn<PurchaseObject>[] = [
        {
            title: "Реестровый номер",
            getter(v) {
                return v.regNumber;
            },
        },
        {
            title: "Закон-основание",
            getter(v) {
                return LawTypeStrings[v.law];
            },
        },
        {
            title: "Описание закупки",
            getter(v) {
                return v.subject;
            },
        },
        {
            title: "КПГЗ",
            getter(v) {
                return v.subjectClass!.code + ' ' + v.subjectClass!.description;
            },
        },
        {
            title: "Источник финансирования",
            getter(v) {
                return v.financialSources.map(x => x.source.format(false)).join("; ");
            },
        },
        {
            title: "Объем финансирования, руб",
            getter(v) {
                return v.totalVolume;
            },
        }
    ];

    private context: PaginationContext<PurchaseObject> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) =>
            PurchaseObjects.get(n, sz, {
                ...f,
                status: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.APPROVED,
                }, {
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.PUBLISHED,
                }, {
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: ObjectStatus.INCLUDED_IN_PLAN,
                }],
                law: [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.EQUAL,
                    string: LawType.F44,
                }]
            }), 10);
    }

    public closeAdd() {
        router.replace('/plans');
    }

    public createPlan() {
        Plans.createPurсhasePlan(this.periodStartYear)
            .then(r => router.replace('/plans/purchase-plans/' + r.id));
    }
}
