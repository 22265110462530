import { IRelatedObject } from "@/models/RelatedObjects";
import { LawType, LotContractType, ProviderSelectionType, JointLotType, ObjectStatus, SmpType } from "./enums";
import { JointLotOrganizerType } from "./enums/JointLotOrganizerType";
import Decimal from "decimal.js";
import { IJsonFormattable } from "./IJsonFormattable";
import { IDtoLot } from "./json/IDtoLot";
import { PurchaseStage } from "./PurchaseStage";
import { LotRequestStage } from "./enums/LotRequestStage";
import { nonEmptyString } from '@/form-declarations/validators';
import { LotDetail } from './LotDetail';
import { NoValue } from './Common';
import { SingleProviderReason } from './SingleProviderReason';
import { LotDocument } from './Documents';
import { HistoryEntry } from './HistoryEntry';
import { BankAccount } from './BankAccount';
import { Participant } from './Participant';
import { Criteria } from './Criteria';

// todo: 44/223 diff
export class Lot implements IJsonFormattable<IDtoLot> {
    constructor(
        public id: string | null,
        public regNumber: string,
        public purchaseId: string | null,
        public status: ObjectStatus,
        public law: LawType,
        public contractType: LotContractType | null,
        public isProductUsed: boolean,
        public providerSelectionType: ProviderSelectionType | null,
        public providerSelectionTypeExplanation: string,

        /* todo: file */

        public subject: string,
        public plannedPublishDate: Date | null,
        public isPublishDatePrecise: boolean,

        public contractTerms: Date | null,

        public jointLotType: JointLotType,
        public jointLotOrganizerType: JointLotOrganizerType,
        // public jointLotOrganizer: Participant,

        public isRepairRelated: boolean,
        public isServicePurchaseAccordingToF233: boolean,

        public startPriceExplanation: string,
        public startPriceCalculationInabilityExplanation: string,

        public smpType: SmpType,
        public purchaseObjectDetails: LotDetail[] | NoValue,

        public isInnovationProduct: boolean,
        public minimalProductRequirements: string,

        public paymentTerms:
            | {
                  staged: true
                  stages: PurchaseStage[]
              }
            | {
                  staged: false
                  date: Date | null
              },

        public contractProvision: {
            used: boolean
            percent: Decimal | null
            account: BankAccount | null
            acceptor: string | null
            additionalInfo: string | null
        },

        public guaranteeProvision: {
            used: boolean
            percent: Decimal | null
            account: BankAccount | null
            acceptor: string | null
            additionalInfo: string | null
        },

        public requestProvision: {
            used: boolean
            percent: Decimal | null
            account: BankAccount | null
            acceptor: string | null
        },
        public additionalProviderRequirements: { used: boolean; requirements: string | null },
        public additionalGovRequirements: { used: boolean; requirements: string | null }, // TODO
        public additionalRequirementExplanation: string | null,

        // public customerEmployee: Employee,
        // public contractCreatorEmployee: Employee,

        public additionalInfo: {
            advantagesForUis: Decimal | null
            advantagesForDisabledPeopleOrganizations: Decimal | null
            prepaidExpense: Decimal | null
            foreignProductsForbidConditions: string
            foreignProductsAmount: Decimal | null

            isEeuProduction: boolean
            banTurkishProduction: boolean
            banForeignMachines: boolean
            banForeignLightProduction: boolean
            banForeignComputingProduction: boolean
            banForeignFood: boolean
            banForeignMedicines: boolean
            banForeignImportantMedicines: boolean
            banForeignElectronics: boolean
            banForeignFurniture: boolean

            isMultipleParticipantsAllowed: boolean
            isLifeSupportProduction: boolean
            isResearchServices: boolean
        },

        public lotDocuments: Array<LotDocument>,

        public requestDocuments: Array<{
            name: string
            order: number
            originalComment: string
            comment: string
            required: boolean
            option: "ORIGINAL" | "OPTED_IN" | "OPTED_OUT"
            hardcoded: boolean
            originalStage: LotRequestStage
            stage: LotRequestStage
        }>,
        public singleProviderReason: SingleProviderReason | null,
        public relatedObjects: IRelatedObject[],

        public procedureId: string | null,
        public history: HistoryEntry[],
        public provider: Participant | null,

        public criteria: Criteria[] | null,
        public criteriaAssessmentOrder: string | null
    ) {}

    public toJson(): IDtoLot {
        return {
            ...this,
            contractType: this.contractType!,
            providerSelectionType: this.providerSelectionType!,
            additionalInfo: {
                ...this.additionalInfo,
                advantagesForUis: this.additionalInfo.advantagesForUis && this.additionalInfo.advantagesForUis.toString(),
                advantagesForDisabledPeopleOrganizations: this.additionalInfo.advantagesForDisabledPeopleOrganizations &&
                    this.additionalInfo.advantagesForDisabledPeopleOrganizations.toString(),
                prepaidExpense: this.additionalInfo.prepaidExpense && this.additionalInfo.prepaidExpense.toString(),
                foreignProductsAmount: this.additionalInfo.foreignProductsAmount && this.additionalInfo.foreignProductsAmount.toString(),
            },
            contractProvision: {
                ...this.contractProvision,
                percent: this.contractProvision.percent && this.contractProvision.percent.toString(),
                account: this.contractProvision.account && this.contractProvision.account.toJson()
            },
            guaranteeProvision: {
                ...this.guaranteeProvision,
                percent: this.guaranteeProvision.percent && this.guaranteeProvision.percent.toString(),
                account: this.guaranteeProvision.account && this.guaranteeProvision.account.toJson()
            },
            requestProvision: {
                ...this.requestProvision,
                percent: this.requestProvision.percent && this.requestProvision.percent.toString(),
                account: this.requestProvision.account && this.requestProvision.account.toJson()
            },
            lotDocuments: this.lotDocuments.map(x => x.toJson()),
            requestDocuments: this.requestDocuments.map(x => ({ ...x })),
            additionalProviderRequirements: { ...this.additionalProviderRequirements } as never, // TODO
            additionalGovRequirements: { ...this.additionalGovRequirements } as never,
            purchaseObjectDetails: this.purchaseObjectDetails ? this.purchaseObjectDetails.map(x => x.toJson()) : null,
            paymentTerms: this.paymentTerms.staged
                ? {
                      staged: true,
                      stages: this.paymentTerms.stages.map(x => ({
                          date: x.date!.toISOString(),
                          percent: x.percent.toString(),
                      })),
                  }
                : { staged: false, date: this.paymentTerms.date!.toISOString() },
            plannedPublishDate: this.plannedPublishDate!.toISOString(),
            contractTerms: this.contractTerms!.toISOString(),
            singleProviderReason: this.singleProviderReason ? this.singleProviderReason.toJson() : null,
            history: [], // NOT NECESSARY
            provider: this.provider && this.provider.toJson(),
            criteria: this.criteria && this.criteria.map(x => x.toJson()),
            criteriaAssessmentOrder: this.criteriaAssessmentOrder
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoLot): Lot {
        return Object.assign(Object.create(Lot.prototype), dto, {
            additionalInfo: {
                ...dto.additionalInfo,
                advantagesForUis: dto.additionalInfo.advantagesForUis && new Decimal(dto.additionalInfo.advantagesForUis),
                advantagesForDisabledPeopleOrganizations: dto.additionalInfo.advantagesForDisabledPeopleOrganizations && new Decimal(
                    dto.additionalInfo.advantagesForDisabledPeopleOrganizations,
                ),
                prepaidExpense: dto.additionalInfo.prepaidExpense && new Decimal(dto.additionalInfo.prepaidExpense),
                foreignProductsAmount: dto.additionalInfo.foreignProductsAmount && new Decimal(dto.additionalInfo.foreignProductsAmount),
            },
            contractProvision: {
                ...dto.contractProvision,
                percent: dto.contractProvision.percent && new Decimal(dto.contractProvision.percent),
                account: dto.contractProvision.account && BankAccount.fromJson(dto.contractProvision.account)
            },
            guaranteeProvision: {
                ...dto.guaranteeProvision,
                percent: dto.guaranteeProvision && dto.guaranteeProvision.percent && new Decimal(dto.guaranteeProvision.percent),
                account: dto.guaranteeProvision && dto.guaranteeProvision.account && BankAccount.fromJson(dto.guaranteeProvision.account)
            },
            requestProvision: {
                ...dto.requestProvision,
                percent: dto.requestProvision.percent && new Decimal(dto.requestProvision.percent),
                account: dto.requestProvision.account && BankAccount.fromJson(dto.requestProvision.account)
            },
            additionalProviderRequirements: { ...dto.additionalProviderRequirements },
            additionalGovRequirements: { ...dto.additionalGovRequirements },
            purchaseObjectDetails: dto.purchaseObjectDetails ? dto.purchaseObjectDetails.map(x => LotDetail.fromJson(x)) : null,
            paymentTerms: dto.paymentTerms.staged
                ? {
                      staged: true,
                      stages: dto.paymentTerms.stages.map(x => ({
                          date: new Date(x.date),
                          percent: new Decimal(x.percent),
                      })),
                  }
                : { staged: false, date: new Date(dto.paymentTerms.date) },
            plannedPublishDate: new Date(dto.plannedPublishDate),
            contractTerms: new Date(dto.contractTerms),
            lotDocuments: dto.lotDocuments.map(x => LotDocument.fromJson(x)),
            requestDocuments: [...dto.requestDocuments.map(x => ({ ...x }))],
            singleProviderReason: dto.singleProviderReason ? SingleProviderReason.fromJson(dto.singleProviderReason) : null,
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : [],
            provider: dto.provider && Participant.fromJson(dto.provider),
            criteria: dto.criteria && dto.criteria.map(x => Criteria.fromJson(x)),
            criteriaAssessmentOrder: dto.criteriaAssessmentOrder
        });
    }

    public get totalStartPrice() {
        if (!this.purchaseObjectDetails) return new Decimal(0);

        return this.purchaseObjectDetails
            .flatMap(x => x.detail.specifications)
            .map(x => x.startPrice!.multiplied)
            .reduce((p, x) => p.add(x), new Decimal(0));
    }

    public get isFilled() {
        return this.contractType !== null &&
            this.providerSelectionType !== null &&
            ((this.law === LawType.F223 && this.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE)
                || nonEmptyString(this.providerSelectionTypeExplanation)) &&
            (this.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE || this.singleProviderReason !== null) &&
            nonEmptyString(this.subject) &&
            this.plannedPublishDate !== null &&
            this.contractTerms !== null &&
            (this.law === LawType.F223 || (
                nonEmptyString(this.startPriceExplanation) &&
                nonEmptyString(this.startPriceCalculationInabilityExplanation))) &&
            !!this.purchaseObjectDetails &&
            this.purchaseObjectDetails.length > 0 &&
            (this.paymentTerms.staged ? this.paymentTerms.stages.length > 0 : this.paymentTerms.date !== null);
    }
}
