































































































import { ITableColumn } from "@/components/TableAbstractions";
import { BankAccount } from "@/models/BankAccount";
import { LawType, ObjectStatus, SelectOption } from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Lot, Criteria, Subcriteria } from "@/models";
import AddCriteria from './AddCriteria.vue';
import EventBus from '../../../EventBus';
import EditCriteriaProp from './EditCriteriaProp.vue';

@Component
export default class CriteriaSelection extends Vue {
    @Prop() public source!: Lot;
    @Prop() public criteriaSectionVisible!: boolean;
    @Prop() public objectId!: string;

    public criteriaTableHeaders: ITableColumn<unknown>[] = [
        {
            title: "Название",
            slot: "name",
            getter: () => undefined,
        },
        {
            title: "Значимость (%)",
            slot: "value",
            getter: () => undefined,
            size: "auto",
        },
        {
            title: "Содержание",
            slot: "content",
            getter: () => undefined,
            size: "auto",
        },
        {
            title: "Порядок оценки",
            slot: "assessment-order",
            getter: () => undefined,
            size: "auto",
        },
        {
            title: "Добавить показатель",
            slot: "add-subcriteria",
            getter: () => undefined,
            size: "auto",
        },
        {
            title: "Удалить",
            slot: "remove-subcriteria",
            getter: () => undefined,
            size: "auto",
        },
    ];

    public get criteria(): { criteria: Criteria; subcriteria: Subcriteria | null; actual: Subcriteria }[] {
        return (this.source.criteria || [])
            .flatMap(x => [
                { criteria: x, subcriteria: null, actual: x },
                ...(x.subcriteria.map(s => ({
                    criteria: x,
                    subcriteria: s,
                    actual: s
                })))
            ]);
    }

    public async addSubcriteria(entry: { criteria: Criteria }) {
        const subcriteria = await EventBus.callModal<null, Subcriteria>(AddCriteria, null);
        if (subcriteria.isOk)
            entry.criteria.subcriteria.push(subcriteria.getResult());
    }

    public editProp(entry: { actual: Subcriteria }, prop: "assessmentOrder" | "content") {
        EventBus.callModal(EditCriteriaProp, { actual: entry.actual, prop });
    }

    public deleteSubcriteria(entry: { criteria: Criteria; subcriteria: Subcriteria | null }) {
        if (entry.subcriteria) {
            const index = entry.criteria.subcriteria.indexOf(entry.subcriteria);
            entry.criteria.subcriteria.splice(index, 1);
        }
    }

    public setOrderFile(ev: Event) {
        const file = ((ev.target as HTMLInputElement).files || [])[0];
        this.source.criteriaAssessmentOrder = file ? file.name : null;
    }
}
