import { Component, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormBase from "@/views/FormBase/FormBase.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import Card from "@/components/vue/Card.vue";
import { IRow } from "@/components/vue/form-table/FormFields";
import { ITableAction, ITableColumn } from "@/components/TableAbstractions";
import SubjectClassSelectionModal from "@/views/modals/SubjectClassSelectionModal/SubjectClassSelectionModal.vue";
import SubjectDeclarationSelectionModal from "@/views/modals/SubjectDeclarationSelectionModal";
import PurchaseObjectDetailedAddStageModal from "@/views/modals/PurchaseObjectDetailedAddStageModal";
import { CharacteristicConditionTypeStrings, DayType, SupplyStagePeriodType } from "@/models/enums";
import {
    Characteristic,
    MeasurementUnit,
    Specification,
    SubjectClass,
    SubjectDeclaration,
    SupplyStage
} from "@/models";
import { Decimal } from "decimal.js";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import {
    ISpecificationCreationModalArgs,
    ISubjectClassSelectionModalArgs,
    ISubjectDeclarationSelectionModalArgs,
    ISupplyStageModalArgs,
} from "@/views/modals/ModalArgs";
import { FormStyle, IForm, ISection, ITableSection } from "@/views/FormAbstractions";
import FieldSection from "@/views/form-renderers/FieldSection/FieldSection.vue";
import TableSection from "@/views/form-renderers/TableSection/TableSection.vue";
import EventBus from "@/EventBus";
import SubjectDeclarations from "@/api/SubjectDeclarations";
import { FilterConditionType, FilterValueType } from "@/api/http";
import { ActionClass } from '@/models/ActionClass';
import ActionClassSelectionModal from '../ActionClassSelectionModal/ActionClassSelectionModal';
import SubjectClassPicker from './SubjectClassPicker.vue';
import { firstMaybe } from "@/arrayUtils";

export declare interface ISpecificationSelection {
    baseSubjectClass: SubjectClass
    subjectClass: SubjectClass | null
    subjectDeclaration: SubjectDeclaration | null
    actionClass: ActionClass | null
    volume: Decimal | null
    singleItemPrice: Decimal | null
    sparePartsPrice: Decimal | null
    measurementUnit: MeasurementUnit | null
    supplyStages: SupplyStage[]

    subjectClassHasDeclarations: boolean
}

@Component({
    components: {
        CardModal,
        FormBase,
        TreeView,
        FormTable,
        DataTable,
        SubjectClassSelectionModal,
        SubjectDeclarationSelectionModal,
        Card,
        PurchaseObjectDetailedAddStageModal,
    },
})
export default class PurchaseObjectDetailedSpecModal extends ModalVue<ISpecificationCreationModalArgs, Specification> {
    @Prop() private prop!: ISpecificationCreationModalArgs;

    public isStagesVisible = false;

    private selection: ISpecificationSelection = {
        baseSubjectClass: this.prop.subjectClass,
        subjectClass: this.prop.existingSpecification && this.prop.existingSpecification.subjectDeclaration
            ? this.prop.existingSpecification.subjectDeclaration.subjectClass
            : this.prop.subjectClass,
        subjectDeclaration: this.prop.existingSpecification ? this.prop.existingSpecification.subjectDeclaration : null,
        actionClass: this.prop.existingSpecification ? this.prop.existingSpecification.actionClass : null,
        supplyStages: this.prop.existingSpecification ? this.prop.existingSpecification.supplyStages : [],
        measurementUnit: this.prop.existingSpecification ? this.prop.existingSpecification.measurementUnit : null,
        volume: this.prop.existingSpecification ? this.prop.existingSpecification.volume : null,
        sparePartsPrice: this.prop.existingSpecification ? this.prop.existingSpecification.sparePartsPrice : null,
        singleItemPrice: this.prop.existingSpecification ? this.prop.existingSpecification.singleItemPrice : null,

        subjectClassHasDeclarations: this.prop.existingSpecification !== null,
    };

    public async mounted() {
        if (this.prop.existingSpecification) return;

        await SubjectDeclarations.get(0, 1, {
            subjectClassId: [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.EQUAL,
                longint: this.prop.subjectClass.id,
            }],
        }).then(r => (this.selection.subjectClassHasDeclarations = r.totalCount > 0));
    }

    private formatDate(stage: SupplyStage, end: boolean): string {
        switch (stage.type) {
            case SupplyStagePeriodType.RELATIVE:
                const offset = end ? stage.relativePeriod.endOffset : stage.relativePeriod.startOffset;
                const type = end ? stage.relativePeriod.endOffsetType : stage.relativePeriod.startOffsetType;
                const typeStr = type === DayType.NORMAL ? "календарных" : "рабочих";
                return `${offset} ${typeStr} дней с момента заключения контракта`;
            default:
                return (
                    (end
                        ? stage.absolutePeriod.endDate ? stage.absolutePeriod.endDate .toLocaleDateString() : ""
                        : stage.absolutePeriod.startDate ? stage.absolutePeriod.startDate.toLocaleDateString() : "") + ""
                );
        }
    }

    private subjDeclRow: IRow<ISpecificationSelection> = {
        id: "subjectDeclaration",
        title: "СПГЗ",
        type: "PICK",
        required: true,
        visible: v => v.subjectClass !== null && v.subjectClassHasDeclarations,

        getter: s => s.subjectDeclaration
    };

    private purchaseInfoSection: ISection<ISpecificationSelection> = {
        type: "FIELDS",
        name: "Содержание закупки",
        component: FieldSection,
        data: {
            columnCount: 1,
            columns: [
                {
                    header: "Для добавления спецификации в детализированный объект закупки укажите конечный КПГЗ",
                    rows: [
                        {
                            id: "subjectType",
                            title: "КПГЗ",
                            type: "PICK",
                            required: true,

                            getter: s => s.subjectClass
                        },
                        this.subjDeclRow,
                        {
                            type: "RAW",
                            component: SubjectClassPicker,
                            full: true,
                            visible: p => !!p.subjectClass,
                            getter: () => null,
                            setter: () => {}
                        },
                        {
                            title: "ОКПД-2",
                            type: "TEXT",
                            editable: false,
                            visible: p => !!(p.subjectDeclaration && p.subjectDeclaration.okpd2),

                            getter: p => p.subjectDeclaration && p.subjectDeclaration.okpd2
                                ? p.subjectDeclaration.okpd2.code + " " + p.subjectDeclaration.okpd2.name
                                : ""
                        },
                        {
                            id: "actionClass",
                            title: "ОКВЭД-2",
                            type: "PICK",
                            visible: p => this.prop.needActionClass && !!p.subjectDeclaration,

                            getter: p => p.actionClass
                        }
                    ],
                },
            ],
        },
    };

    private additSpecificationsSection: ITableSection<ISpecificationSelection, Characteristic> = {
        type: "TABLE",
        name: "Необязательные характеристики",
        component: TableSection,
        visible: v => v.subjectDeclaration !== null,
        data: {
            headers: [
                {
                    title: "Характеристика",
                    getter(v) {
                        return v.name;
                    },
                },
                {
                    title: "Единица измерения",
                    getter(v) {
                        return v.measurementUnit ? v.measurementUnit.name : "";
                    },
                },
                {
                    title: "Условные операции",
                    getter(v) {
                        return CharacteristicConditionTypeStrings[v.conditionType];
                    },
                    slot: "condition"
                },
                {
                    title: "Значение",
                    getter(v) {
                        return v.defaultValue || v.enumValues.join(", ");
                    },
                    slot: "value"
                },
            ],
            getter(v) {
                return v.subjectDeclaration ? v.subjectDeclaration.characteristics.filter(x => x.isAdditional) : [];
            },
        },
    };

    private createPriceSection(): ISection<ISpecificationSelection> {
        const that = this;

        return {
            type: "FIELDS",
            name: "Цена",
            component: FieldSection,
            visible: v => v.subjectDeclaration !== null,
            data: {
                columnCount: 1,
                columns: [
                    {
                        rows: [
                            {
                                title: "Объем закупки",
                                type: "DECIMAL",
                                required: true,
                                style: "SHORT",
                                visible: () => !that.prop.isPerItemPurchase,

                                getter: s => s.volume,
                                setter: (s, value: Decimal) => {
                                    s.volume = value;
                                    const finiteValue = value.isFinite() ? value : new Decimal(1);

                                    if (s.supplyStages.length === 0 && this.prop.canAutoAddStage) {
                                        s.supplyStages.push(new SupplyStage(finiteValue, SupplyStagePeriodType.ABSOLUTE, {
                                            startOffset: null,
                                            startOffsetType: DayType.NORMAL,
                                            endOffset: null,
                                            endOffsetType: DayType.NORMAL,
                                            finishYear: null }, {
                                                endDate: this.prop.purchaseDate,
                                                startDate: null
                                            },
                                            "в соответствии с проектом Контракта и Техническим заданием",
                                            null));
                                    } else if (s.supplyStages.length === 1) {
                                        s.supplyStages[0].volume = finiteValue;
                                    }
                                },
                            },
                            {
                                title: "Общая начальная (максимальная) цена запасных частей к технике и оборудованию",
                                type: "DECIMAL",
                                required: true,
                                style: "SHORT",
                                visible: () => that.prop.isPerItemPurchase,

                                getter: s => s.sparePartsPrice,
                                setter: (s, value: Decimal) => s.sparePartsPrice = value
                            },
                            {
                                title: "Начальная (максимальная) цена единицы товара, работы или услуги",
                                type: "DECIMAL",
                                required: true,
                                style: "SHORT",
                                visible: () => that.prop.isPerItemPurchase,

                                getter: s => s.singleItemPrice,
                                setter: (s, value: Decimal) => s.singleItemPrice = value
                            },
                            {
                                title: "Единицы измерения",
                                type: "SELECT",
                                required: true,

                                get selectOptions() {
                                    return that.selection.subjectDeclaration
                                        ? that.selection.subjectDeclaration.measurementUnits.map(x => ({
                                              key: x.id.toString(),
                                              desc: x.name,
                                          }))
                                        : [];
                                },
                                getter: s => (s.measurementUnit ? s.measurementUnit.id : 0),
                                setter: (s, id: number) =>
                                    (s.measurementUnit = s.subjectDeclaration!.measurementUnits.find(
                                        x => x.id === id,
                                    )!),
                            },
                        ],
                    },
                ],
            },
        };
    }
    private priceSection = this.createPriceSection();

    private generalSpecificationsSection: ITableSection<ISpecificationSelection, Characteristic> = {
        type: "TABLE",
        name: "Обязательные характеристики",
        component: TableSection,
        visible: v => v.subjectDeclaration !== null,
        data: {
            headers: [
                {
                    title: "Характеристика",
                    getter(v) {
                        return v.name;
                    },
                },
                {
                    title: "Единица измерения",
                    getter(v) {
                        return v.measurementUnit ? v.measurementUnit.name : "";
                    },
                },
                {
                    title: "Условные операции",
                    getter(v) {
                        return CharacteristicConditionTypeStrings[v.conditionType];
                    },
                },
                {
                    title: "Значение",
                    getter(v) {
                        return v.defaultValue || v.enumValues.join(", ");
                    },
                },
            ],
            getter(v) {
                return v.subjectDeclaration ? v.subjectDeclaration.characteristics.filter(x => !x.isAdditional) : [];
            },
        },
    };

    public formDecl: IForm<ISpecificationSelection> = {
        header: "",
        stats: undefined,
        formStyle: FormStyle.HORIZONTAL,
        actions: () => [],
        pages: [{
            id: "main",
            showNavigationHeader: false,
            sections: [
                this.purchaseInfoSection,
                this.generalSpecificationsSection as never,
                this.additSpecificationsSection as never,
                this.priceSection,
            ]
        }],
    };

    public schedHeaders: ITableColumn<SupplyStage>[] = [
        {
            title: "Объем поставки",
            getter(v) {
                return v.volume + "";
            },
        },
        {
            title: "Дата начала поставки",
            getter: v => this.formatDate(v, false),
        },
        {
            title: "Дата окончания поставки",
            getter: v => this.formatDate(v, true),
        },
        {
            title: "Условия поставки",
            getter(v) {
                return v.conditions;
            },
        },
    ];

    public schedActions: ITableAction[] = [
        {
            id: "edit",
            title: "Редактировать",
        },
        {
            id: "delete",
            title: "Удалить",
        },
    ];

    public async formPick(id: string) {
        switch (id) {
            case "subjectType":
                const subjectClass = await EventBus.callModal<ISubjectClassSelectionModalArgs, SubjectClass>(
                    SubjectClassSelectionModal,
                    { subjectClass: this.selection.subjectClass, root: this.prop.subjectClass },
                );
                if (subjectClass.isOk) {
                    const result = subjectClass.getResult();
                    if (!this.selection.subjectClass || this.selection.subjectClass.id !== result.id) {
                        this.selection.subjectDeclaration = null;
                    }
                    this.selection.subjectClass = result;
                    this.selection.subjectClassHasDeclarations = false;

                    await SubjectDeclarations.get(0, 1, {
                        subjectClassId: [{
                            type: FilterValueType.LONGINT,
                            conditionType: FilterConditionType.EQUAL,
                            longint: result.id,
                        }],
                    }).then(r => (this.selection.subjectClassHasDeclarations = r.totalCount > 0));
                }
                break;
            case "actionClass":
                const actionClass = await EventBus.callModal<ActionClass | null, ActionClass>(
                    ActionClassSelectionModal,
                    this.selection.actionClass,
                );
                if (actionClass.isOk) {
                    this.selection.actionClass = actionClass.getResult();
                }
                break;
            case "subjectDeclaration":
                const subjectDecl = await EventBus.callModal<ISubjectDeclarationSelectionModalArgs, SubjectDeclaration>(
                    SubjectDeclarationSelectionModal,
                    {
                        subjectClass: this.selection.subjectClass!,
                        selectedSubjectDeclaration: this.selection.subjectDeclaration,
                        law: this.prop.law
                    },
                );
                if (subjectDecl.isOk) {
                    const result = subjectDecl.getResult();
                    this.selection.subjectDeclaration = result;
                    this.selection.measurementUnit = firstMaybe(result.measurementUnits) || null;
                }
                break;
        }
    }

    public async addStage(existing: SupplyStage | null = null) {
        const reqdPeriod =
            this.selection.supplyStages.length > (existing === null ? 0 : 1) ? this.selection.supplyStages[0].type : null;
        const stage = await EventBus.callModal<Omit<ISupplyStageModalArgs, "volume">, SupplyStage>(
            PurchaseObjectDetailedAddStageModal,
            { stage: existing, requiredPeriodType: reqdPeriod, purchasePerUnit: this.prop.isPerItemPurchase },
        );
        if (stage.isOk) {
            const result = stage.getResult();
            if (!this.selection.supplyStages.includes(result)) this.selection.supplyStages.push(result);
        }
    }

    public selectItemAndExit() {
        if (this.isOk) {
            let result = this.prop.existingSpecification;
            if (result === null) {
                result = new Specification(
                    this.selection.subjectDeclaration!,
                    this.selection.actionClass,
                    this.selection.volume!,
                    this.selection.singleItemPrice!,
                    this.selection.sparePartsPrice!,
                    this.selection.measurementUnit!,
                    this.selection.supplyStages,
                    null,
                    null,
                );
            } else {
                result.subjectDeclaration = this.selection.subjectDeclaration!;
                result.supplyStages = this.selection.supplyStages;
                if (
                    result.volume !== this.selection.volume ||
                    result.measurementUnit !== this.selection.measurementUnit
                )
                    result.startPrice = null;
                result.measurementUnit = this.selection.measurementUnit!;
                result.volume = this.selection.volume!;
                result.sparePartsPrice = this.selection.sparePartsPrice!;
                result.singleItemPrice = this.selection.singleItemPrice!;
            }

            this.$emit("close-modal", ModalResult.ok(result));
        }
    }

    public onSchedStageAction(item: SupplyStage, action: string) {
        switch (action) {
            case "delete":
                this.selection.supplyStages = this.selection.supplyStages.filter(x => x !== item);
                break;
            case "edit":
                this.addStage(item);
                break;
        }
    }

    public get isOk(): boolean {
        const isClassSelected = this.selection.subjectClass != null;
        const isDeclSelected = this.selection.subjectDeclaration != null;
        return (
            isClassSelected &&
            isDeclSelected &&
            (!this.prop.needActionClass || this.selection.actionClass !== null) &&
            !!this.selection.supplyStages.length &&
            (!this.prop.isPerItemPurchase
                ? !!this.selection.volume && this.selection.volume.gt(0)
                : !!this.selection.singleItemPrice && this.selection.singleItemPrice.gte(0) && !!this.selection.sparePartsPrice && this.selection.sparePartsPrice.gte(0) &&
                    (this.selection.singleItemPrice.gt(0) || this.selection.sparePartsPrice.gt(0))) &&
            !!this.selection.measurementUnit &&
            this.selection.supplyStages.every(x => {
                return x.type !== SupplyStagePeriodType.ABSOLUTE ||
                    (x.absolutePeriod.endDate !== null && x.absolutePeriod.startDate !== null);
            })
        );
    }
}
