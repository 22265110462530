


































































































































































import Lots from "@/api/Lots";
import PurchaseObjectDetails from "@/api/PurchaseObjectDetails";
import DataTable from "@/components/vue/DataTable.vue";
import Card from "@/components/vue/Card.vue";
import AdditionalProperties from "@/views/CreateLot/sections/AdditionalProperties.vue";
import AdditionalRequirements from "@/views/CreateLot/sections/AdditionalRequirements.vue";
import ContactProperties from "@/views/CreateLot/sections/ContactProperties.vue";
import ContractProvision from "@/views/CreateLot/sections/ContractProvision.vue";
import GuaranteeProvision from "@/views/CreateLot/sections/GuaranteeProvision.vue";
import CriteriaSelection from "@/views/CreateLot/sections/CriteriaSelection.vue";
import GeneralProperties from "@/views/CreateLot/sections/GeneralProperties.vue";
import RequestProvision from "@/views/CreateLot/sections/RequestProvision.vue";
import TotalStartPrice from "@/views/CreateLot/sections/TotalStartPrice.vue";
import DocumentationSection from "@/views/form-renderers/DocumentationSection";
import SpecificationsSection from "@/views/form-renderers/SpecificationsSection";
import { Lot as LotModel, LotDetail, Participant, Criteria } from "@/models";
import {
    JointLotType,
    LawType,
    LotStatusStrings,
    ObjectStatus,
    ObjectStatusStrings,
    SmpType,
    toSelectOptions,
    LotContractTypeStrings,
    ProviderSelectionTypeStrings,
    SelectOption,
    ProviderSelectionType,
    LotContractType,
} from "@/models/enums";
import { JointLotOrganizerType } from "@/models/enums/JointLotOrganizerType";
import Decimal from "decimal.js";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Participants from "@/api/Participants";
import router from "../../router";
import { formatNumber } from "../../NumberFormatting";
import { SingleProviderReason } from "../../models/SingleProviderReason";
import { BankAccount } from "../../models/BankAccount";
import { ITableColumn } from "../../components/TableAbstractions";
import EventBus from "../../EventBus";
import ParticipantSelectionModal2 from "../modals/ParticipantSelectionModal2/ParticipantSelectionModal2";
import { LotDocumentType } from "../../models/enums/Documents";
import Auth from '../../api/Auth';

@Component({
    components: {
        ContactProperties,
        AdditionalRequirements,
        AdditionalProperties,
        CriteriaSelection,
        ContractProvision,
        GuaranteeProvision,
        RequestProvision,
        TotalStartPrice,
        GeneralProperties,
        DataTable,
        Card,
        DocumentationSection,
        SpecificationsSection,
    },
})
export default class Lot extends Vue {
    @Prop({ default: null }) private objectId!: string | null;
    @Prop({ default: null }) private detailedObjectId!: string | null;
    @Prop({ default: false }) private clone!: boolean;
    @Prop({ default: null }) private lawType!: LawType | null;

    public header = "Новый лот";

    // region
    public source: LotModel = new LotModel(
        null,
        "",
        null,
        ObjectStatus.CREATING_FIRST,
        this.lawType || LawType.F44,
        null,
        false,
        null,
        "",
        "",
        null!,
        false,
        null!,
        JointLotType.NONE,
        JointLotOrganizerType.ORGANIZER,
        false,
        false,
        "",
        "",
        SmpType.FULL,
        [],
        false,
        "",
        { staged: false, date: null },
        { used: false, percent: new Decimal(0), acceptor: null, additionalInfo: null, account: null },
        { used: false, percent: new Decimal(0), acceptor: null, additionalInfo: null, account: null },
        { used: false, percent: new Decimal(0), acceptor: null, account: null },
        { used: false, requirements: null },
        { used: false, requirements: null },
        null,
        {
            advantagesForDisabledPeopleOrganizations: null,
            advantagesForUis: null,
            banForeignComputingProduction: false,
            banForeignElectronics: false,
            banForeignFood: false,
            banForeignFurniture: false,
            banForeignImportantMedicines: false,
            banForeignLightProduction: false,
            banForeignMachines: false,
            banForeignMedicines: false,
            banTurkishProduction: false,
            foreignProductsAmount: null,
            foreignProductsForbidConditions: "",
            isEeuProduction: false,
            isLifeSupportProduction: false,
            isMultipleParticipantsAllowed: false,
            isResearchServices: false,
            prepaidExpense: null,
        },
        [],
        [],
        null,
        [],
        null!,
        [],
        null,
        null,
        null
    );
    // endregion

    private async loadParticipantInfo() {
        const user = await Auth.getUserInfoIfRequired();

        const reasons = await Participants.getSingleProviderReasons(user.selectedParticipant.id);
        const accounts = await Participants.getAccounts(user.selectedParticipant.id);

        this.singleProviderReasonOptions = reasons.map(x => ({ key: x, desc: x.name }));
        this.accountOptions = accounts.map(x => ({ key: x, desc: x.accountNumber }));
        this.accountOptions.splice(0, 0, { key: null!, desc: "Выберите лицевой счет" });
    }

    public created() {
        this.loadParticipantInfo();

        if (this.objectId) {
            this.header = this.clone ? "Копирование лота" : "Редактирование лота";

            Lots.getById(this.objectId).then(response => {
                this.source = response;
                if (this.clone) this.source.status = ObjectStatus.CREATING_FIRST;
                else if (response.status === ObjectStatus.PUBLISHED) this.mode = "trade";
            });
        } else if (this.detailedObjectId) {
            PurchaseObjectDetails.getById(this.detailedObjectId).then(response => {
                this.source.subject = response.subject;
                this.source.purchaseObjectDetails!.push(new LotDetail(response, new Decimal(0)));

                const specWithSupplier = response.specifications.find(x => !!x.startPrice!.supplier);
                this.source.provider = (specWithSupplier && specWithSupplier.startPrice!.supplier) || null;

                const typeCode = response.object.subjectClass!.code.split(".")[0];
                switch (typeCode) {
                    case "01":
                        this.source.contractType = LotContractType.GOOD_PROVIDING;
                        break;
                    case "02":
                        this.source.contractType = LotContractType.WORK_COMPLETES;
                        break;
                    case "03":
                        this.source.contractType = LotContractType.DO_SERVICES;
                        break;
                }
            });
        }
    }

    public mode = "planning" as "planning" | "trade";
    public partialPurchases = false;

    public get provider() {
        return this.source.provider;
    }
    public set provider(p) {
        this.source.provider = p;
    }

    public get trade() {
        return this.mode === "trade";
    }

    public get planning() {
        return this.mode === "planning";
    }

    public get published() {
        return this.source.status === ObjectStatus.PUBLISHED;
    }

    public get law() {
        return this.source.law === LawType.F44 ? "44-ФЗ" : "223-ФЗ";
    }

    public get status() {
        return LotStatusStrings[this.source.status];
    }

    public async selectProvider() {
        const r = await EventBus.callModal<null, Participant>(ParticipantSelectionModal2, null);
        if (r.isOk) this.provider = r.getResult();
    }

    public changeOptions = [
        { key: "0", desc: `Выберите причину изменения` },
        { key: "1", desc: `
                Изменение планируемых сроков приобретения товаров, работ, услуг, способа размещения заказа,
                срока исполнения контракта
            ` },
        { key: "2", desc: `
                Изменение более чем на 10% стоимости планируемых к приобретению товаров, работ, услуг,
                выявленные в результате подготовки к размещению конкретного заказа
            ` },
        { key: "3", desc: `
                Выдача предписания уполномоченного органа исполнительной власти об устранении нарушения
                законодательства РФ
            ` },
        { key: "4", desc: `Изменение по результатам обязательного общественного обсуждения` },
        { key: "5", desc: `Образовавшаяся экономия от использования в текущем финансовом году бюджетных ассигнований` },
        { key: "6", desc: `Возникновение непредвиденных обстоятельств` },
    ];

    public singleProviderReasonOptions = [] as SelectOption<SingleProviderReason>[];
    public accountOptions = [] as SelectOption<BankAccount>[];

    public get multiLotCheckField() {
        return this.source.jointLotType === JointLotType.JOINT_LOT;
    }

    public set multiLotCheckField(x: boolean) {
        this.source.jointLotType = x ? JointLotType.JOINT_LOT : JointLotType.NONE;
    }

    public get requestProvisionAllowed() {
        switch (this.source.providerSelectionType) {
            case ProviderSelectionType.QUATATIONS_REQUEST:
            case ProviderSelectionType.ONE_PROVID_PURCHASE:
                return false;
            default:
                return true;
        }
    }

    public get requestProvisionDisabled() {
        switch (this.source.providerSelectionType) {
            case ProviderSelectionType.E_QUOTATIONS_REQUEST:
                return true;
            default:
                return false;
        }
    }

    public get criteriaSectionVisible() {
        switch (this.source.providerSelectionType) {
            case ProviderSelectionType.E_EXAM:
            case ProviderSelectionType.E_TWOSTAGE_EXAM:
            case ProviderSelectionType.E_LIMITED_EXAM:
            case ProviderSelectionType.TWOSTAGE_EXAM:
            case ProviderSelectionType.LIMITED_EXAM:
            case ProviderSelectionType.OPENED_EXAM:
            case ProviderSelectionType.E_OFFERS_REQUEST:
            case ProviderSelectionType.OFFERS_REQUEST:
                return true;
            default:
                return false;
        }
    }

    // ACTIONS

    @Watch("source.providerSelectionType") public updateCriteria() {
        if (this.criteriaSectionVisible) {
            this.source.criteria = !this.source.criteria || this.source.criteria.length === 0 ? [
                new Criteria("Цена контракта",
                    null, null, null, false, []),
                new Criteria("Расходы на эксплуатацию и ремонт товаров (объектов), использование результатов работ",
                    null, null, null, false, []),
                new Criteria("Предложение о сумме соответствующих расходов заказчика, которые заказчик осуществит или понесет по энергосервисному контракту",
                    null, null, null, false, []),
                new Criteria("Качественные, функциональные и экологические характеристики объекта закупки",
                    null, null, null, true, []),
                new Criteria(
                    "Квалификация участников закупки, в том числе наличие у них финансовых ресурсов, " +
                    "оборудования и других материальных ресурсов, принадлежащих им на праве собственности " +
                    "или на ином законном основании, опыта работы, связанного с предметом контракта, и " +
                    "деловой репутации, специалистов и иных работников определенного уровня квалификации",
                    null, null, null, true, [])
            ] : this.source.criteria;
        }
    }

    public save() {
        const errors: string[] = [];
        if (this.source.contractType === null) errors.push('Заполните "Тип контракта"');
        if (this.source.providerSelectionType === null) errors.push('Заполните "Способ определения поставщика"');
        if (!this.source.subject || this.source.subject.trim().length === 0) errors.push('Заполните "Предмет закупки"');
        if (this.source.plannedPublishDate === null) errors.push('Заполните "Планируемая дата публикации"');
        if (this.source.contractTerms === null) errors.push('Заполните "Срок исполнения контракта"');
        if (!this.source.purchaseObjectDetails || this.source.purchaseObjectDetails.length === 0)
            errors.push("В лот не добавлены ДОЗы");
        if (this.source.paymentTerms.staged) {
            if (this.source.paymentTerms.stages.length === 0) errors.push("Не добавлены этапы оплаты");
        } else if (this.source.paymentTerms.date === null) errors.push('Заполните "Дата оплаты"');

        if (
            this.source.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE &&
            this.source.smpType === SmpType.FULL
        )
            errors.push(
                'Лоты со способом определения поставщика "Закупка у единственного поставщика" не могут быть полностью ' +
                    "размещены среди СМП/МСП",
            );

        if (this.source.law === LawType.F44) {
            if (
                (this.source.startPriceExplanation === null || this.source.startPriceExplanation.trim().length === 0) &&
                (this.source.startPriceCalculationInabilityExplanation === null ||
                    this.source.startPriceCalculationInabilityExplanation.trim().length === 0)
            )
                errors.push('Заполните "Обоснование НМЦ"');
        }

        if (this.source.provider) {
            for (const nonEqual of this.source
                .purchaseObjectDetails!.map(x => x.detail)
                .filter(x => {
                    const specWithSupplier = x.specifications.find(s => s.startPrice!.supplier !== null);
                    return specWithSupplier && specWithSupplier.startPrice!.supplier!.id !== this.source.provider!.id;
                })) {
                errors.push("Выбранный поставщик не соответствует поставщику по тарифу для ДОЗа " + nonEqual.regNumber);
            }
        }

        if (this.mode === "trade") {
            if (this.requestProvisionAllowed && !this.requestProvisionDisabled &&
                this.source.requestProvision.used && this.source.requestProvision.account === null)
                errors.push('Заполните "Банковские реквизиты обеспечения заявки"');

            if (this.source.contractProvision.used && this.source.contractProvision.account === null)
                errors.push('Заполните "Банковские реквизиты обеспечения исполнения контракта"');
            if (
                this.source.contractProvision.used &&
                (this.source.contractProvision.additionalInfo === null ||
                    this.source.contractProvision.additionalInfo.trim().length === 0)
            )
                errors.push("Необходимо заполнить особенности внесения обеспечения исполнения контракта");

            if (this.source.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE) {
                if (this.source.lotDocuments.findIndex(d => d.type === LotDocumentType.TECH_SPECS) === -1)
                    errors.push(
                        `Лот №${this.source.regNumber} в процедуре не содержит документ типа "Техническое задание"`,
                    );
                if (this.source.lotDocuments.findIndex(d => d.type === LotDocumentType.CONTRACT_PROJECT) === -1)
                    errors.push(
                        `Лот №${this.source.regNumber} в процедуре не содержит документ типа "Проект контракта"`,
                    );
            }
            if (this.source.lotDocuments.findIndex(d => d.type === LotDocumentType.MSV_CALC) === -1)
                errors.push(`Лот №${this.source.regNumber} в процедуре не содержит документ типа "Расчет НМЦ"`);
            if (this.source.lotDocuments.findIndex(d => d.type === LotDocumentType.MSV_PROTOCOL) === -1)
                errors.push(`Лот №${this.source.regNumber} в процедуре не содержит документ типа "Протокол НМЦ"`);
        }

        if (errors.length) {
            EventBus.raiseErrors(errors);
            return;
        }

        this.source!.status = ObjectStatus.CREATING;
        if (this.clone || !this.objectId) {
            const copy = LotModel.fromJson(this.source.toJson());

            if (!this.requestProvisionAllowed || this.requestProvisionDisabled)
                copy.requestProvision.used = false;

            copy.status = ObjectStatus.CREATING;

            Lots.create(copy)
                .then(response => router.replace(`/plan-objects/lots/${response.id}`));
        } else {
            Lots.update(this.source, this.mode === "trade")
                .then(response => router.replace(`/plan-objects/lots/${response.id}`));
        }
    }

    public close() {
        router.back();
    }
}
