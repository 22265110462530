import Decimal from "decimal.js";
import { IJsonFormattable } from "./IJsonFormattable";
import { IDtoLotDetail } from './json/IDtoLotDetail';
import { SourceUsage } from './SourceUsage';
import { SubjectDeclaration } from './SubjectDeclaration';
import { PurchaseObjectDetail } from './PurchaseObjectDetail';

// todo: 44/223 diff
export class LotDetail implements IJsonFormattable<IDtoLotDetail> {
    constructor(
        public detail: PurchaseObjectDetail,
        public smpPercent: Decimal) {}

    public toJson(): IDtoLotDetail {
        return {
            ...this,
            detail: this.detail.toJson(),
            smpPercent: this.smpPercent.toString()
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoLotDetail): LotDetail {
        return Object.assign(Object.create(LotDetail.prototype), dto, {
            detail: PurchaseObjectDetail.fromJson(dto.detail),
            smpPercent: new Decimal(dto.smpPercent)
        });
    }
}
