import { IDtoPurchaseObject, IDtoTerms } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IRelatedObject } from "@/models/RelatedObjects";
import Decimal from "decimal.js";
import {
    LawType,
    ObjectStatus,
    SmallVolumeType,
    GoalType,
    TermsDuration,
    TermsPeriod,
    SpecialPurchaseType,
} from "@/models/enums";
import { SubjectClass, UsedSource } from "@/models";
import { HistoryEntry } from './HistoryEntry';
import { EditReasonType } from './enums/EditReasonType';

export abstract class IPurchaseObject implements IJsonFormattable<IDtoPurchaseObject> {
    protected constructor(
        public id: string | null,
        public regNumber: string,
        public purchaseId: string,
        public law: LawType,

        public subject: string,
        public subjectClass: SubjectClass | null,

        public status: ObjectStatus,
        public financialSources: UsedSource[],

        public year: number | null,
    ) {}

    public abstract toJson(): IDtoPurchaseObject;

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoPurchaseObject): PurchaseObject {
        // noinspection JSRedundantSwitchStatement
        switch (dto.law) {
            case LawType.F44:
                return PurchaseObject.fromJson(dto as IDtoPurchaseObject);
            default:
                throw new Error("Failed to parse purchase object with law " + dto.law);
        }
    }
}

export class Terms implements IJsonFormattable<IDtoTerms> {
    constructor(
        public duration: TermsDuration,
        public beginDate: Date | null,
        public period: TermsPeriod,
        public count: number,
    ) {}

    public toJson(): IDtoTerms {
        return {
            ...this,
            beginDate: this.beginDate ? this.beginDate.toISOString() : new Date().toISOString(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoTerms): Terms {
        return Object.assign(Object.create(Terms.prototype), dto, {
            beginDate: new Date(dto.beginDate),
        });
    }
}

export class PurchaseObject extends IPurchaseObject implements IJsonFormattable<IDtoPurchaseObject> {
    constructor(
        public id: string | null,
        public regNumber: string,
        public purchaseId: string,
        public planId: number | null,
        public law: LawType,

        public subject: string,
        public subjectClass: SubjectClass | null,

        public status: ObjectStatus,
        public financialSources: UsedSource[],

        public year: number | null,

        public specialPurchaseType: SpecialPurchaseType,
        public smallVolume: SmallVolumeType,
        public goal: {
            type: GoalType
            expectedResult: string
            programName: string
            programEvents: string
            purchaseReason: string
            legalActs: string
            pt7Info: string
        },
        public terms: Terms, // TODO
        public isPublicDiscussionNotApplicableDueToClosedMethod: boolean,
        public isPublicDiscussionNotApplicableDueToSingleProvider: boolean,
        public relatedObjects: IRelatedObject[],
        public history: HistoryEntry[],
        public isEditReasonRequired: boolean,
        public editReasonType: EditReasonType | null,
        public editReasonInfo: string | null
    ) {
        super(
            id,
            regNumber,
            purchaseId,
            law,
            subject,
            subjectClass,
            status,
            financialSources,
            year,
        );
    }

    public toJson(): IDtoPurchaseObject {
        return {
            ...this,
            subjectClass: this.subjectClass ? this.subjectClass.toJson() : null,
            financialSources: this.financialSources.map(x => x.toJson()),
            goal: { ...this.goal },
            terms: this.terms.toJson(), // TODO
            year: this.year!,
            history: [] // NOT NECESSARY
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoPurchaseObject): PurchaseObject {
        return Object.assign(Object.create(PurchaseObject.prototype), dto, {
            subjectClass: SubjectClass.fromJson(dto.subjectClass!),
            financialSources: dto.financialSources.map(x => UsedSource.fromJson(x)),
            goal: { ...dto.goal },
            terms: Terms.fromJson(dto.terms), // TODO
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : []
        });
    }

    public static createEmpty(lawType: LawType | null): PurchaseObject {
        return new PurchaseObject(
            null,
            "",
            "",
            null,
            lawType || LawType.F44,
            "",
            null,
            ObjectStatus.CREATING,
            [],
            null,
            SpecialPurchaseType.NO,
            SmallVolumeType.NO,
            {
                expectedResult: "",
                legalActs: "",
                programEvents: "",
                programName: "",
                pt7Info: "",
                purchaseReason: "",
                type: GoalType.EVENT_EXECUTION,
            },
            new Terms(TermsDuration.ONE_TIME, null!, TermsPeriod.UNDEFINED, 0),
            false,
            false,
            [],
            [],
            false,
            null,
            null
        );
    }

    public get totalVolume(): Decimal {
        return this.financialSources.reduce((a, x) => new Decimal(x.amount).add(a), new Decimal(0));
    }

    private static largeVolume = new Decimal("1000000000");

    public get isDiscussionRequired() {
        return this.smallVolume === SmallVolumeType.NO && this.totalVolume.gte(PurchaseObject.largeVolume);
    }
}
