import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoPublishTerms } from "./json/IDtoPublishTerms";
import { DateTimeRange } from "./DateTimeRange";

export class PublishTerms implements IJsonFormattable<IDtoPublishTerms> {
    constructor(
        public publishDate: Date,
        public requestAcceptRange: DateTimeRange,
        public documentExplainationRange?: DateTimeRange | null,
        public firstRequestConsiderationFinishDate?: Date | null,
        public secondRequestConsiderationFinishDate?: Date | null,
        public executionDate?: Date | null,
        public finalOffersDate?: Date | null,
    ) {}

    public toJson(): IDtoPublishTerms {
        return {
            publishDate: this.publishDate.toISOString(),
            requestAcceptRange: this.requestAcceptRange.toJson(),
            documentExplainationRange: this.documentExplainationRange && this.documentExplainationRange.toJson(),
            firstRequestConsiderationFinishDate:
                this.firstRequestConsiderationFinishDate && this.firstRequestConsiderationFinishDate.toISOString(),
            secondRequestConsiderationFinishDate:
                this.secondRequestConsiderationFinishDate && this.secondRequestConsiderationFinishDate.toISOString(),
            executionDate: this.executionDate && this.executionDate.toISOString(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoPublishTerms): PublishTerms {
        return new PublishTerms(
            new Date(dto.publishDate),
            DateTimeRange.fromJson(dto.requestAcceptRange),
            dto.documentExplainationRange && DateTimeRange.fromJson(dto.documentExplainationRange),
            (dto.firstRequestConsiderationFinishDate as null) && new Date(dto.firstRequestConsiderationFinishDate as string),
            (dto.secondRequestConsiderationFinishDate as null) && new Date(dto.secondRequestConsiderationFinishDate as string),
            (dto.executionDate as null) && new Date(dto.executionDate as string),
            (dto.finalOffersDate as null) && new Date(dto.finalOffersDate as string),
        );
    }
}
