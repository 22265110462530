import { EnumStrings } from "./EnumStrings";

/* @deprecated Use only for compatibility 2019-version */
export const enum ProviderSelectionType {
    E_AUC = "E_AUC",
    E_EXAM = "E_EXAM",
    E_TWOSTAGE_EXAM = "E_TWOSTAGE_EXAM",
    E_LIMITED_EXAM = "E_LIMITED_EXAM",
    E_OFFERS_REQUEST = "E_OFFERS_REQUEST",
    E_QUOTATIONS_REQUEST = "E_QUOTATIONS_REQUEST",
    OPENED_EXAM = "OPENED_EXAM",
    QUATATIONS_REQUEST = "QUATATIONS_REQUEST",
    ONE_PROVID_PURCHASE = "ONE_PROVID_PURCHASE",
    OFFERS_REQUEST = "OFFERS_REQUEST",
    TWOSTAGE_EXAM = "TWOSTAGE_EXAM",
    LIMITED_EXAM = "LIMITED_EXAM",
}

/* @deprecated Use only for compatibility 2019-version */
export const ProviderSelectionTypeStrings: EnumStrings = {
    [ProviderSelectionType.E_AUC]: "Электронный аукцион",
    [ProviderSelectionType.E_EXAM]: "Электронный конкурс",
    [ProviderSelectionType.E_TWOSTAGE_EXAM]: "Электронный двухэтапный конкурс",
    [ProviderSelectionType.E_LIMITED_EXAM]: "Электронный конкурс с ограниченным участием",
    [ProviderSelectionType.E_OFFERS_REQUEST]: "Электронный запрос предложений",
    [ProviderSelectionType.E_QUOTATIONS_REQUEST]: "Электронный запрос котировок",
    [ProviderSelectionType.OPENED_EXAM]: "Открытый конкурс",
    [ProviderSelectionType.QUATATIONS_REQUEST]: "Запрос котировок",
    [ProviderSelectionType.ONE_PROVID_PURCHASE]: "Закупка у единственного поставщика",
    [ProviderSelectionType.OFFERS_REQUEST]: "Запрос предложений",
    [ProviderSelectionType.TWOSTAGE_EXAM]: "Двухэтапный конкурс",
    [ProviderSelectionType.LIMITED_EXAM]: "Конкурс с ограниченным участием",
};
