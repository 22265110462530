import { Component, Prop, Vue } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import { FormDeclarationBuilder } from '@/form-declarations/trade';
import { Procedure } from '@/models/Procedure';
import router from '@/router';
import Procedures from '@/api/Procedures';
import { ProcedureStatusStrings, ProviderSelectionTypeStrings, ObjectStatus } from '@/models/enums';
import Participants from '@/api/Participants';
import EventBus from '@/EventBus';
import ProcedurePublishDateModal from '../modals/ProcedurePublishDateModal/ProcedurePublishDateModal';
import { PublishTerms } from '@/models/PublishTerms';
import { IObjectDeletionModalArgs } from '../modals/ModalArgs';
import ObjectDeletionModal from '../modals/ObjectDeletionModal';
import { createRelatedObjects } from '@/models/RelatedObjects';

@Component({ components: { FormBase } })
export default class TradeDetails extends Vue {
    @Prop() public objectId!: string;
    public source: Procedure | null = null;
    public formDecl = FormDeclarationBuilder(true, { specialParticipants: Participants.getSpecial(0, 100).then(x => x.items) });

    private setSource(object: Procedure) {
        this.source = object;
        this.formDecl.header =
            ProviderSelectionTypeStrings[object.providerSelectionType] + " " + object.regNumber + " - " + ProcedureStatusStrings[object.status];

        this.formDecl.relatedObjects = createRelatedObjects(object);
    }

    public created() {
        this.formDecl.header = "Торги";
        Procedures.getById(this.objectId).then(object => this.setSource(object));
    }

    public async onAction(action: string) {
        switch (action) {
            case 'edit':
                router.replace(`/procedures/${this.objectId}/edit`);
                break;
            case 'clone':
                router.replace(`/procedures/${this.objectId}/clone`);
                break;
            case "delete":
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Удаление",
                    description:
                        "Вы уверены, что хотите удалить процедуру?",
                });
                if (result.isOk && result.getResult()) {
                    Procedures.delete(this.objectId).then(response => router.push("/"));
                }
                break;
            case 'send-to-grbs':
                Procedures.setStatus(this.objectId!, ObjectStatus.GRBS_AGREEMENT_PENDING)
                    .then(response => this.setSource(response));
                break;
            case 'cancel-grbs-agreement':
                Procedures.setStatus(this.objectId!, ObjectStatus.CREATING)
                    .then(response => this.setSource(response));
                break;
            case 'grbs-agree':
                Procedures.setStatus(this.objectId!, ObjectStatus.GRBS_AGREED)
                    .then(response => this.setSource(response));
                break;
            case 'set-publish-date':
                const newTerms = await EventBus.callModal<PublishTerms | null, PublishTerms>(
                    ProcedurePublishDateModal, this.source!.publishTerms);

                if (newTerms.isOk)
                    Procedures.setPublishTerms(this.objectId!, newTerms.getResult())
                        .then(response => this.setSource(response));
                break;
            case 'pre-publish':
                Procedures.setStatus(this.objectId!, ObjectStatus.PUBLISH_PENDING)
                    .then(response => this.setSource(response));
                break;
            case 'publish':
                Procedures.setStatus(this.objectId!, ObjectStatus.PUBLISHED)
                    .then(response => this.setSource(response));
                break;
            case 'complete-sales':
                Procedures.setStatus(this.objectId!, ObjectStatus.SALES_FINISHED)
                    .then(response => this.setSource(response));
                break;
            case "close":
                router.back();
                break;
        }
    }
}
