import { Component, Prop, Vue } from "vue-property-decorator";
import SingleTableView from "@/components/SingleTableView.vue";
import router from "@/router";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import { Procedure } from '@/models/Procedure';
import { LawTypeStrings, ProviderSelectionTypeStrings, ProcedureStatusStrings, ObjectStatus } from '@/models/enums';
import Decimal from 'decimal.js';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';

import Api from '@/api/Procedures';
import { FilterValueType, FilterConditionType } from '@/api/http';
import Auth from '@/api/Auth';
import { containsFilter, selectFilter } from '@/components/TableHelpers';

@Component({ components: { SingleTableView, DataTable } })
export default class PublishedProcedures extends Vue {
    private isAddObjectModalVisible = false;
    private selectedLaw = "44";

    private headers: ITableColumn<Procedure >[] = [
        {
            title: "Реестровый номер",
            getter(v) {
                return v.regNumber;
            },
            url(v) { 
                return `/trades/${v.id}`;
            },
            filter: containsFilter("regNumber"),
        },
        {
            title: "Закон-основание",
            getter(v) {
                return LawTypeStrings[v.law];
            },
            filter: selectFilter("law", LawTypeStrings, false),
        },
        {
            title: "Наименование предмета закупки",
            getter(v) {
                return v.subject;
            },
            filter: containsFilter("subject"),
        },
        {
            title: "Организатор",
            getter(v) {
                return Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName;
            },
        },
        {
            title: "Способ размещения",
            getter(v) {
                return ProviderSelectionTypeStrings[v.providerSelectionType];
            },
            filter: selectFilter("providerSelectionType", ProviderSelectionTypeStrings,false),
        },
        {
            title: "Уровень размещения",
            getter(v) {
                return v.lots.reduce((r, x) => x.totalStartPrice.add(r), new Decimal(0)).gte(50000000) ? "Первый" : "Второй";
            },
        },
        {
            title: "Сумма, руб.",
            getter(v) {
                return v.lots.reduce((p, x) => p.add(x.totalStartPrice), new Decimal(0));
            },
        },
        {
            title: "Плановая дата публикации",
            getter(v) {
                return "?";
            },
        },
        {
            title: "Статус",
            getter(v) {
                return ProcedureStatusStrings[v.status];
            },
            filter: selectFilter("status", ProcedureStatusStrings, false),
        },
    ];

    public context: PaginationContext<Procedure> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) => Api.get(n, sz, {...f,
            status: [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: ObjectStatus.PUBLISHED,
            }, {
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: ObjectStatus.SALES_FINISHED,
            }]
        }), 20);
    }
}
