import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormBase from "@/views/FormBase/FormBase.vue";
import SubjectClassSelectionModal from "@/views/modals/SubjectClassSelectionModal/SubjectClassSelectionModal.vue";
import ProvisionSelectionModal from "@/views/modals/ProvisionSelectionModal/ProvisionSelectionModal.vue";

import { ISetValueEvent } from "@/components/vue/form-table/FormFields";

import { FormDeclarationBuilder } from "@/form-declarations/purchaseObject";
import router from "@/router";

import PurchaseObjects from "@/api/PurchaseObjects";
import Decimal from "decimal.js";
import {
    ObjectStatus, LawType,
} from "@/models/enums";
import { PurchaseObject, UsedSource, SubjectClass, Terms } from "@/models";
import EventBus from "@/EventBus";
import FinancialSources from '@/api/FinancialSources';
import { ISubjectClassSelectionModalArgs } from '../modals/ModalArgs';
import { createRelatedObjects } from '@/models/RelatedObjects';

@Component({ components: { FormBase, SubjectClassSelectionModal, ProvisionSelectionModal } })
export default class CreatePurchaseObject extends Vue {
    @Prop({ default: null }) private objectId!: string | null;
    @Prop({ default: null }) private financialSourceId!: string | null;
    @Prop({ default: false }) private clone!: boolean;
    @Prop({ default: null }) private lawType!: LawType | null;

    public source: PurchaseObject = PurchaseObject.createEmpty(this.lawType);

    private selectedSource: UsedSource | null = null;

    public formDecl = FormDeclarationBuilder(false, !!this.objectId, this.clone);

    public async pickFormValue(property: string, value: unknown) {
        switch (property) {
            case "subjectClass":
                const subjectClass = await EventBus.callModal<ISubjectClassSelectionModalArgs, SubjectClass>(
                    SubjectClassSelectionModal,
                    { subjectClass: value ? value as SubjectClass : null, root: null },
                );
                if (subjectClass.isOk) {
                    this.source.subjectClass = subjectClass.getResult();
                }
                break;
            case "provision":
                const source = await EventBus.callModal<UsedSource | null, UsedSource>(
                    ProvisionSelectionModal,
                    value ? value as UsedSource : null,
                );
                if (source.isOk) {
                    const r = source.getResult();
                    if (!this.source.financialSources.includes(r)) this.source.financialSources.push(r);
                }

                break;

            // case 'provision':
            //     this.selectedSource = value
            //     this.isProvisionModalVisible = true
            //     break
        }
    }

    public mounted() {
        if (this.objectId) {
            const word = this.clone ? "Копирование" : "Редактирование";
            this.formDecl.header = word + " объекта закупки";
            PurchaseObjects.getById(this.objectId).then(response => {
                this.source = response;
                this.lawType = response.law;

                this.formDecl.relatedObjects = createRelatedObjects(response);
            });
        } else if (this.financialSourceId) {
            FinancialSources.getById(this.financialSourceId).then(response => {
                this.source.financialSources.push(new UsedSource(response, new Decimal(0)));
            });
        }
    }

    public cleanSource() { this.source = PurchaseObject.createEmpty(this.lawType) }

    public onAction(action: string) {
        switch (action) {
            case "save":
                if (!this.clone && this.objectId) {
                    PurchaseObjects.update(this.source).then(response => router.replace(`/plan-objects/${response.id}`));
                } else {
                    PurchaseObjects.create(this.source).then(response => router.replace(`/plan-objects/${response.id}`));
                }
                break;
            // case "save-and-continue":
            //     this.source.status = ObjectStatus.CREATING
            //     if (!this.clone && this.objectId) {
            //         // PurchaseObjects.update(this.source.object)
            //         //     .then(response => router.replace(`/plan-objects/${response.id}`))
            //     } else {
            //         PurchaseObjects.create(this.source)
            //         // .then(response => router.replace(`/plan-objects/${response.id}`))

            //         this.cleanSource()
            //     }
            //     break
            case "close":
                router.replace("/plan-objects");
                break;
        }
    }
}
