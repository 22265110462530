













import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { IObjectDeletionModalArgs } from "@/views/modals/ModalArgs";

@Component({ components: { CardModal } })
export default class ObjectDeletionModal extends ModalVue<IObjectDeletionModalArgs, boolean> {
    @Prop() public prop!: IObjectDeletionModalArgs;

    public confirm() {
        this.$emit("close-modal", ModalResult.ok(true));
    }
}
