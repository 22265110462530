






















import { ModalResult, ModalVue } from '@/view-models/ModalRequest';
import { Component, Vue, Prop } from "vue-property-decorator";
import CardModal from "../../components/CardModal.vue";

@Component({ components: { CardModal } })
export default class EisLogin extends ModalVue<unknown, unknown> {
    @Prop() private prop!: unknown;

    private proceed() {
        this.$emit('close-modal', ModalResult.ok(true));
    }
}
