













import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";

@Component({ components: { CardModal } })
export default class ProcedurePublishConfirm extends ModalVue<unknown, boolean> {
    public confirm() {
        this.$emit("close-modal", ModalResult.ok(true));
    }
}
