var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-section',{attrs:{"name":"Критерии оценки"},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('f-header',{staticClass:"f-card"},[_vm._v("Критерии оценки")])]},proxy:true}])},[(_vm.criteriaSectionVisible)?[_c('d-table',{attrs:{"headers":_vm.criteriaTableHeaders,"dataset":_vm.criteria},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [(!!item.subcriteria && !!_vm.objectId)?[_c('x-textbox',{attrs:{"multiline":true,"lines":5},model:{value:(item.actual.name),callback:function ($$v) {_vm.$set(item.actual, "name", $$v)},expression:"item.actual.name"}})]:[_vm._v(" "+_vm._s(item.actual.name)+" ")]]}},{key:"add-subcriteria",fn:function(ref){
var item = ref.item;
return (!item.subcriteria && item.criteria.canAddSubcriteria)?[_c('x-button',{attrs:{"title":"добавить показатель","icon":{ type: 'CLASS', name: 'add' },"color":"green","disabled":!_vm.objectId},on:{"click":function () { return _vm.addSubcriteria(item); }}})]:undefined}},{key:"remove-subcriteria",fn:function(ref){
var item = ref.item;
return (item.subcriteria)?[_c('x-button',{style:({ width: '100%' }),attrs:{"icon":{ type: 'CLASS', name: 'delete' },"color":"red","disabled":!_vm.objectId},on:{"click":function () { return _vm.deleteSubcriteria(item); }}})]:undefined}},{key:"assessment-order",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"criteria-text-btn"},[_c('div',[_vm._v(_vm._s(item.actual.assessmentOrder ? "Задано" : "Не задано"))]),_c('x-button',{attrs:{"icon":{ type: 'CLASS', name: 'edit' },"disabled":!_vm.objectId},on:{"click":function($event){return _vm.editProp(item, 'assessmentOrder')}}})],1)]}},{key:"content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"criteria-text-btn"},[_c('div',[_vm._v(_vm._s(item.actual.content ? "Задано" : "Не задано"))]),_c('x-button',{attrs:{"icon":{ type: 'CLASS', name: 'edit' },"disabled":!_vm.objectId},on:{"click":function($event){return _vm.editProp(item, 'content')}}})],1)]}},{key:"value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"criteria-value"},[_c('f-float',{attrs:{"width":"short","state":!_vm.objectId ? 'disabled' : 'enabled'},model:{value:(item.actual.value),callback:function ($$v) {_vm.$set(item.actual, "value", $$v)},expression:"item.actual.value"}}),(!!_vm.objectId && !!item.subcriteria && item.actual.value && (item.actual.value < 0 || item.actual.value > 1))?_c('div',[_vm._v(" Значение не может быть меньше 0 или превышать 1 ")]):(!!_vm.objectId && item.actual.value && (item.actual.value < 0 || item.actual.value > 100))?_c('div',[_vm._v(" Значение не может быть меньше 0 или превышать 100 ")]):_vm._e()],1)]}}],null,true)}),_c('f-grid',{attrs:{"ratio":[1, 3]}},[_c('f-field',[_c('f-label',[_vm._v("Порядок оценки заявок")]),(!_vm.source.criteriaAssessmentOrder)?_c('div',{style:({gridColumn: 'span 3'})},[_c('input',{attrs:{"type":"file"},on:{"input":_vm.setOrderFile}}),_c('div',[_vm._v(" Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar. ")]),_c('div',[_vm._v(" Максимальный размер файла - 10 МБ ")])]):_c('div',{staticClass:"selected-assessment-order"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.source.criteriaAssessmentOrder))]),_c('div',{staticClass:"reset",on:{"click":function($event){_vm.source.criteriaAssessmentOrder = null}}},[_vm._v("x")])])],1)],1),(!_vm.objectId)?_c('f-notice',[_vm._v(" Заполнение раздела \"Критерии оценки\" возможно только после первого сохранения лота ")]):_vm._e()]:[_c('div',[_vm._v(" Для данного способа определения поставщика критерии оценки не указываются ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }