import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoPurchaseObjectDetail } from "@/models/json";
import { ObjectStatus, AddressType } from "@/models/enums";
import { SourceUsage, Specification, Address, PurchaseObject } from "@/models";
import { IRelatedObject } from "@/models/RelatedObjects";
import { Decimal } from "decimal.js";
import { HistoryEntry } from './HistoryEntry';

export class PurchaseObjectDetail implements IJsonFormattable<IDtoPurchaseObjectDetail> {
    constructor(
        public id: string | null,
        public regNumber: string | null,
        public object: PurchaseObject,
        public subject: string,
        public specifications: Specification[],
        public status: ObjectStatus,
        public isPerItemPurchase: boolean,
        public sourceUsage: SourceUsage[],
        public address: Address,
        public relatedObjects: IRelatedObject[],
        public lotId: string | null,
        public history: HistoryEntry[],
    ) {}

    public toJson(): IDtoPurchaseObjectDetail {
        return {
            ...this,
            object: this.object.toJson(),
            specifications: this.specifications.map(x => x.toJson()),
            sourceUsage: this.sourceUsage.map(x => x.toJson()),
            address: this.address.toJson(),
            history: [] // NOT NECESSARY
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoPurchaseObjectDetail): PurchaseObjectDetail {
        return Object.assign(Object.create(PurchaseObjectDetail.prototype), dto, {
            object: PurchaseObject.fromJson(dto.object),
            specifications: dto.specifications.map(x => Specification.fromJson(x)),
            sourceUsage: dto.sourceUsage.map(x => SourceUsage.fromJson(x)),
            address: Address.fromJson(dto.address),
            history: dto.history ? dto.history.map(x => HistoryEntry.fromJson(x)) : []
        });
    }

    public static createEmpty(object: PurchaseObject): PurchaseObjectDetail {
        return new PurchaseObjectDetail(
            null,
            null,
            object,
            object.subject,
            [],
            ObjectStatus.CREATING,
            false,
            object.financialSources.length === 1
                ? [new SourceUsage(object.financialSources[0], new Decimal(0), true)]
                : object.financialSources.map(x => new SourceUsage(x, new Decimal(0))),
            new Address(AddressType.FIAS, "", ""),
            [],
            null,
            []
        );
    }

    public get totalStartPrice() {
        return this.specifications.reduce(
            (a, x) => (x.startPrice ? x.startPrice.multiplied : new Decimal(0)).add(a),
            new Decimal(0),
        );
    }
}
