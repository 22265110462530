var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specifications-sec"},[_c('FormTable',{attrs:{"columnCount":1,"state":_vm.tradeMode ? 'READONLY' : _vm.state,"formStyle":_vm.formStyle,"columns":[{rows: [_vm.smpTypeField]}],"source":_vm.source}}),_c('div',{staticClass:"details"},[(_vm.source.purchaseObjectDetails.length)?_c('x-label',{attrs:{"text":"Детализированные объекты закупки"}}):_vm._e(),(_vm.lotDetails.length)?_c('DataTable',{staticStyle:{"align-self":"stretch"},attrs:{"headers":_vm.detailsHeaders,"dataset":_vm.lotDetails,"config":{selection: { showSelectionColumn: !_vm.tradeMode, background: '#ccc' }},"selection":_vm.state === 'READONLY' || _vm.tradeMode ? null : _vm.detailsSelection}}):_c('x-label',{attrs:{"text":"Детализированных объектов нет"}}),(_vm.state !== 'READONLY' && !_vm.tradeMode)?_c('div',{staticClass:"dataset-actions"},[_c('x-button',{attrs:{"color":"green","title":"добавить","icon":{type: 'CLASS', name: 'add'}},on:{"click":_vm.addDetail}}),(_vm.source.purchaseObjectDetails.length)?_c('x-button',{attrs:{"color":"red","title":"Исключить","disabled":!_vm.detailsSelection.items.length},on:{"click":_vm.removeDetails}}):_vm._e()],1):_vm._e()],1),_c('FormTable',{attrs:{"columnCount":1,"state":_vm.tradeMode ? 'READONLY' : _vm.state,"formStyle":_vm.formStyle,"columns":[
            {
                rows: [
                    {
                        title: 'Закупка товаров, работ, услуг, удовлетворяющих критериям отнесения к инновационной ' +
                            'продукции, высокотехнологичной продукции',
                        type: 'CHECKBOX',
                        visible: function visible(p) { return p.lawType === 'F223' },

                        getter: function (p) { return p.isInnovationProduct; },
                        setter: function (p, v) { return (p.isInnovationProduct = v); },
                    },
                    {
                        title: 'Предусмотрена этапность оплаты',
                        type: 'CHECKBOX',

                        getter: function (p) { return p.staged; },
                        setter: function (p, v) { return (p.staged = v); },
                    },
                    {
                        title: 'Дата оплаты',
                        type: 'MONTH_FIELD',
                        visible: function (p) { return !p.staged; },

                        getter: function (p) { return p.date; },
                        setter: function (p, v) { return (p.date = v); },
                        validate: function (p) { return p.date === null ? { error: true, description: 'Поле не заполнено' } : { error: false }; }
                    } ],
            } ],"source":_vm.terms}}),(_vm.source.paymentTerms.staged)?_c('div',{staticClass:"stages"},[(_vm.source.paymentTerms.stages.length)?_c('DataTable',{staticStyle:{"align-self":"stretch","margin-bottom":"15px"},attrs:{"headers":_vm.stagesHeaders,"dataset":_vm.source.paymentTerms.stages,"actions":_vm.state === 'READONLY' || _vm.tradeMode ? undefined : _vm.stagesActions},on:{"item-action":_vm.handleStagesAction}}):_vm._e(),(_vm.state !== 'READONLY' && !_vm.tradeMode)?_c('x-button',{attrs:{"color":"green","title":"Добавить этап оплаты"},on:{"click":function () { return _vm.addStage(); }}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }