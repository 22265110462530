import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { IColumn } from "@/components/vue/form-table/FormFields";
import { ProcedureDocumentType } from "@/models/enums/Documents/ProcedureDocumentType";
import { toSelectOptions, EnumStrings } from "@/models/enums";
import { ModalResult } from "@/view-models/ModalRequest";
import { ProcedureDocument, File as MFile } from '@/models/Documents';

@Component({ components: { CardModal, TreeView, FormTable, DataTable } })
export default class UploadProcedureDocumentModal extends Vue {
    @Prop() private prop!: EnumStrings;

    private source: ProcedureDocument = new ProcedureDocument(new MFile(null!, 0, "", ""), null!, false);

    public formColumns: IColumn<ProcedureDocument>[] = [
        {
            rows: [
                {
                    title: "Тип документа",
                    type: "SELECT",
                    selectOptions: toSelectOptions(this.prop, true, "Выберите тип документа"),

                    getter(src) {
                        return src.type;
                    },
                    setter(src, type: ProcedureDocumentType) {
                        src.type = type;
                    },
                },
                {
                    title: "Примечание",
                    type: "MULTILINE_TEXT",

                    getter(src) {
                        return src.file.comment;
                    },
                    setter(src, val: string) {
                        src.file.comment = val;
                    },
                },
                {
                    title: "Документ",
                    type: "FILE",

                    getter() {
                        return null;
                    },
                    setter(src, val: File | undefined) {
                        if (val) {
                            src.file.fileName = val.name;
                            src.file.length = val.size;
                        } else {
                            src.file.fileName = "";
                            src.file.length = 0;
                        }
                    },
                },
                {
                    type: "TEXT",
                    title: "Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar. Максимальный размер файла - 50 МБ",
                    editable: false,

                    getter() { return null },
                    setter() {},
                },
            ],
        },
    ];

    public selectItemAndExit() {
        this.$emit("close-modal", ModalResult.ok(this.source));
    }
}
