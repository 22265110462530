import DataTable from "@/components/vue/DataTable.vue";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import { EditType, IRowConfiguration, ITableColumn, MultiSelection } from "@/components/TableAbstractions";
import EventBus from "@/EventBus";
import {addMsv, DocSaveMethod, DocumentTable, getDocModalProps, saveLotDoc} from "@/form-declarations/documentation";
import { Lot } from "@/models";
import { LotDocument } from "@/models/Documents";
import { ProviderSelectionType } from "@/models/enums";
import { LotDocumentType, LotDocumentTypeStrings } from "@/models/enums/Documents/LotDocumentType";
import { LotRequestStage } from "@/models/enums/LotRequestStage";
import ContractRequiredDocumentModal from "@/views/modals/ContractRequiredDocumentModal/ContractRequiredDocumentModal.vue";
import DocumentModal from "@/views/modals/DocumentModal/DocumentModal.vue";
import UploadDocumentModal from "@/views/modals/UploadDocumentModal/UploadDocumentModal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

export interface IIdentifiedDoc {
    doc: LotDocument
    id: string
}

@Component({ components: { DataTable } })
export default class DocumentationSection extends Vue {
    @Prop() private source!: Lot;
    @Prop() private state!: FieldState;
    @Prop() private mode!: string;
    @Prop() private objectId?: string | null;

    public async uploadDocument() {
        const result = await EventBus.callModal<Lot, LotDocument>(UploadDocumentModal, this.source);

        if (result.isOk) {
            this.source.lotDocuments.push(result.getResult());
        }
    }

    public get isSingleProvider() {
        return this.source.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE;
    }

    public lotDocumentHeaders: ITableColumn<IIdentifiedDoc>[] = [
        {
            title: "Тип документа",
            getter(r) {
                return LotDocumentTypeStrings[r.doc.type];
            },
        },
        {
            title: "Примечание",
            getter(r) {
                return r.doc.file.comment;
            },
        },
        {
            title: "Документ",
            getter(r) {
                return r.doc.file.fileName;
            },
        },
        ...(this.mode === "trade"
            ? [
                  {
                      title: "Отправить в ЕИС/ЭТП",
                      type: this.state === FieldState.ENABLED ? EditType.CHECK : undefined,
                      editable: this.state === FieldState.ENABLED ? true : false,
                      getter: (doc: IIdentifiedDoc) =>
                          this.state === FieldState.ENABLED ? doc.doc.sendToEis : doc.doc.sendToEis ? "Да" : "Нет",
                      setter: (doc: IIdentifiedDoc, sendToEis: boolean) => (doc.doc.sendToEis = sendToEis),
                  },
              ]
            : []),
        {
            title: "Размер",
            getter(r) {
                return r.doc.file.length.toString();
            },
        },
    ];

    public lotDocumentTableConfig: IRowConfiguration<IIdentifiedDoc> = {
        selection: {
            showSelectionColumn: true,
        },
        showJoinedRow: r => {
            return this.source.lotDocuments.indexOf(r.doc) === this.source.lotDocuments.length - 1;
        },
        isRawJoinedRow: () => true,
    };

    public get totalSize() {
        return this.source.lotDocuments.reduce((p, v) => p + v.file.length, 0).toString();
    }

    public get lotDocs(): IIdentifiedDoc[] {
        return this.source.lotDocuments.map(x => ({ doc: x, id: x.file.fileName }));
    }

    private get buttonsDisabled() {
        return this.state !== FieldState.READONLY;
    }

    public have(type: LotDocumentType) {
        return !!this.source.lotDocuments.find(x => x.type === type);
    }

    public lotDocumentSelection: MultiSelection<IIdentifiedDoc> = { tag: "MULTI", items: [] };

    public deleteLotDocuments() {
        this.source.lotDocuments = this.source.lotDocuments.filter(
            x => this.lotDocumentSelection.items.findIndex(y => y.doc === x) === -1,
        );

        this.lotDocumentSelection.items = [];
    }

    private async showModal(type: "contract" | "spec") {
        const result = await EventBus.callModal(DocumentModal, getDocModalProps(type, this.source));
        const { table, save } = result.getResult() as {table: DocumentTable; save?: DocSaveMethod};

        saveLotDoc(type, this.source, table, save);
    }

    private createMsvCalc() {
        addMsv(this.source);
    }

    public requestDocumentHeaders: ITableColumn<Lot["requestDocuments"][0]>[] = [
        {
            title: "Номер",
            getter: p => p.order,
            size: "auto",
        },
        {
            title: "Наименование",
            getter: p => p.name,
        },
        {
            title: "Комментарий",
            getter: p => p.comment,
            size: "minmax(1fr, auto)",
        },
        {
            title: "Обязательность",
            getter: p => (p.required || p.option === "OPTED_IN" ? "Обязателен" : "Не обязателен"),
            size: "auto",
        },
        {
            title: "Этап заявки",
            getter: p => (p.stage === LotRequestStage.FIRST ? "Первый" : "Второй"),
            size: "auto",
        },
        ...(this.state === FieldState.ENABLED
            ? [
                  {
                      title: "Удалить",
                      getter: () => null,
                      slot: "delete",
                  },
                  {
                      title: "",
                      getter: () => null,
                      slot: "edit",
                  },
              ]
            : []),
    ];

    public get requestDocuments() {
        return (this.source.requestDocuments || []).filter(x => x.option !== "OPTED_OUT");
    }

    public deleteRequestDocument(doc: Lot["requestDocuments"][0]) {
        if (doc.required) return;
        if (doc.hardcoded) {
            doc.option = "OPTED_OUT";
            return;
        } else {
            const ix = this.source.requestDocuments.indexOf(doc);
            if (ix !== -1) this.source.requestDocuments.splice(ix, 1);
        }
    }

    public editRequestDocument(doc: Lot["requestDocuments"][0]) {
        EventBus.callModal(ContractRequiredDocumentModal, doc);
    }

    public async addRequestDocument() {
        const r = await EventBus.callModal<null, Lot["requestDocuments"][0]>(ContractRequiredDocumentModal, null);
        if (r.isOk) {
            this.source.requestDocuments.push(r.getResult());
        }
    }

    public resetRequestDocuments() {
        this.source.requestDocuments = this.source.requestDocuments.filter(x => x.hardcoded);
        for (const doc of this.source.requestDocuments) {
            doc.option = "ORIGINAL";
            doc.stage = doc.originalStage;
            doc.comment = doc.originalComment;
        }
    }
}
