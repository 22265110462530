// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/list_markers.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.section[data-v-070fc430] {\n    padding: 30px;\n}\n.lot-expander-icon[data-v-070fc430] {\n    width: 12px;\n    height: 12px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.lot + .lot[data-v-070fc430] {\n    margin-top: 15px;\n}\n.lot-header[data-v-070fc430] {\n    display: flex;\n    align-items: center;\n\n    font-size: 14px;\n    font-weight: 500;\n\n    cursor: pointer;\n}\n.lot-header[data-v-070fc430] :first-child {\n    margin-right: 10px;\n}\n.lot-participant-table[data-v-070fc430] {\n    margin-top: 15px;\n}\n", ""]);
// Exports
module.exports = exports;
