import { EnumStrings } from "./EnumStrings";

export const enum JointLotOrganizerType {
    ORGANIZER = "ORGANIZER",
    AUTHORIZED_INSTITUTION = "AUTHORIZED_INSTITUTION",
}
export const JointLotOrganizerTypeStrings: EnumStrings = {
    [JointLotOrganizerType.AUTHORIZED_INSTITUTION]: "Уполномоченное учреждение",
    [JointLotOrganizerType.ORGANIZER]: "Организатор совместных торгов",
};
