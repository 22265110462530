import { ITableColumn } from "@/components/TableAbstractions";
import EventBus from "@/EventBus";
import { ObjectStatus, ObjectStatusStrings } from "@/models/enums";
import { Lot } from "@/models/Lot";
import { PurchaseObject } from "@/models/PurchaseObject";
import { PurchaseObjectDetail } from "@/models/PurchaseObjectDetail";
import { UsedSource } from "@/models/UsedSource";
import router from "@/router";

export type RelatedObjectType =
    "LIMIT" | "PURCHASE_OBJECT" | "PURCHASE_OBJECT_DETAIL" | "LOT" | "PROCEDURE" | "TRADE" | "LOT_2020" | "PROCEDURE_2020";

export interface IRelatedObject {
    type: RelatedObjectType
    id: string
    regNumber: string
    status: ObjectStatus
    subject: string
}

export interface HasRelated {
    relatedObjects: IRelatedObject[]
}

export type RelatedObjectsItem =
    | {
          type: "inline"
          label: string
          subject: string
          url: string
      }
    | {
          type: "modal"
          label: string
          title: string
          headers: ITableColumn<IRelatedObject>[]
          dataset: IRelatedObject[]
      };

export const RelatedObjectLabels = {
    PURCHASE_OBJECT: "Объект закупки",
    PURCHASE_OBJECT_DETAIL: "Детализация ОЗ",
    LOT: "Лот",
    LIMIT: "Лимит",
    PROCEDURE: "Закупка",
    TRADE: "Торги",
    LOT_2020: "Лот",
    PROCEDURE_2020: "Закупка",
};

export const RelatedObjectTitles = {
    PURCHASE_OBJECT: "Объекты закупок",
    PURCHASE_OBJECT_DETAIL: "Детализация ОЗ",
    LOT: "Лоты",
    LIMIT: "Лимиты",
    PROCEDURE: "Закупки",
    TRADE: "Торги",
    LOT_2020: "Лот",
    PROCEDURE_2020: "Закупка",
};

export const RelatedObjectUrls = {
    PURCHASE_OBJECT: (id: string) => `/plan-objects/${id}`,
    PURCHASE_OBJECT_DETAIL: (id: string) => `/plan-objects/detailed/${id}`,
    LOT: (id: string) => `/plan-objects/lots/${id}`,
    LIMIT: () => `/finance/pfhd`,
    PROCEDURE: (id: string) => `/procedures/${id}`,
    TRADE: (id: string) => `/trades/${id}`,
    LOT_2020: (id: string) => `/plan-objects/composed-lots/${id}`,
    PROCEDURE_2020: (id: string) => `/procedures/2020/${id}`,
};

export const relatedObjectProps = (type: RelatedObjectType) => {
    const label = RelatedObjectLabels[type];
    const url = RelatedObjectUrls[type];
    const title = RelatedObjectTitles[type];

    const headers: ITableColumn<IRelatedObject>[] =
        type === "LIMIT"
            ? [
                  {
                      title: "КБК / КВР - КОСГУ",
                      getter: ({ subject }) => subject,
                      url: () => () => {
                          EventBus.$emit("close-related-objects-modal");
                          router.push(`/finance/pfhd`);
                      },
                  },
                  {
                      title: "Сумма лимита",
                      getter: ({ status }) => status,
                  },
                  {
                      title: "Год",
                      getter: ({ regNumber }) => regNumber,
                  },
              ]
            : [
                  {
                      title: "№",
                      getter: ({ regNumber }) => regNumber,
                      url: ({ id }) => () => {
                          EventBus.$emit("close-related-objects-modal");
                          router.push(url(id));
                      },
                  },
                  {
                      title: "Наименование закупки",
                      getter: ({ subject }) => subject,
                  },
                  {
                      title: "Статус",
                      getter: ({ status }) => ObjectStatusStrings[status],
                  },
              ];

    return {
        label,
        url,
        title,
        headers,
    };
};

const lotLimits = (lot: Lot) => {
    const flat = new Map<string, PurchaseObject>();

    lot.purchaseObjectDetails!.forEach(object => flat.set(object.detail.object.id!, object.detail.object));

    return [...flat.values()].flatMap(purchaseObjectLimits);
};

const purchaseObjectDetailLimits = (object: PurchaseObjectDetail) => {
    return purchaseObjectLimits(object.object);
};

const purchaseObjectLimits = (object: PurchaseObject): IRelatedObject[] => {
    return object.financialSources.map(
        (x: UsedSource): IRelatedObject => ({
            type: "LIMIT",
            id: x.source.id.toString(),
            subject: x.source.format(false),
            status: x.amount.toString() as ObjectStatus,
            regNumber: x.source.year.toString(),
        }),
    );
};

const getLimits = (object: HasRelated) => {
    if (object instanceof Lot) return lotLimits(object);
    if (object instanceof PurchaseObjectDetail) return purchaseObjectDetailLimits(object);
    if (object instanceof PurchaseObject) return purchaseObjectLimits(object);

    return [];
};

export const createRelatedObjects = (object: HasRelated, filter?: (r: IRelatedObject) => boolean) => {
    if (!object.relatedObjects) return [];
    const sorted = [...object.relatedObjects, ...getLimits(object)].sort((a, b) => {
        if (a.type === b.type) return 0;
        if (a.type === "LIMIT") return -1;
        if (a.type === "PURCHASE_OBJECT") return -1;
        if (a.type === "PROCEDURE") return 1;
        if (a.type === "TRADE") return 1;
        if (a.type === "PROCEDURE_2020") return 1;

        if (a.type === "PURCHASE_OBJECT_DETAIL") {
            if (b.type === "PURCHASE_OBJECT") return 1;

            return -1;
        }

        return 1;
    });

    const filtered = filter ? sorted.filter(filter) : sorted;

    const groups = new Map<RelatedObjectType, IRelatedObject[]>();

    for (const obj of filtered) {
        const group = groups.get(obj.type) || [];

        group.push(obj);
        groups.set(obj.type, group);
    }

    const items: RelatedObjectsItem[] = [];

    for (const [type, dataset] of groups) {
        if (dataset.length === 0) continue; // Imo imposibru, but…

        const { label, url, title, headers } = relatedObjectProps(type);

        if (dataset.length === 1) {
            items.push({
                type: "inline",
                label,
                subject: dataset[0].subject,
                url: url(dataset[0].id),
            });

            continue;
        }

        items.push({ type: "modal", label, title, headers, dataset });
    }

    return items;
};
