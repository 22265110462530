






































































import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { PurchaseObjectDetail, Specification, SupplyStage } from '@/models';
import { SelectOption, createYearSelection, SupplyStagePeriodType, DayType } from '@/models/enums';
import {makeFormHelpers, pathfactory} from '@/form-declarations/helpers';
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';
import Decimal from 'decimal.js';
import { IRow } from '@/components/vue/form-table/FormFields';
import { IDtoSpecification } from '@/models/json';
import EventBus from '@/EventBus';
import ImportResultModal from './ImportResultModal.vue';

const h = makeFormHelpers<ContractSpecificationSupplyStage>();
const p = pathfactory<ContractSpecificationSupplyStage>();

type SupplyStageImportType = "KEEP" | "SET_FOR_EACH" | "SET_FOR_ALL";

@Component({ components: { FormTable, CardModal } })
export default class ImportSpecsModal extends Vue {
    @Prop() public prop!: PurchaseObjectDetail;

    public stage: ContractSpecificationSupplyStage = new ContractSpecificationSupplyStage(
        null!,
        null!,
        new Decimal(0),
        SupplyStagePeriodType.RELATIVE,
        {
            startOffset: null,
            startOffsetType: DayType.NORMAL,
            endOffset: null,
            endOffsetType: DayType.NORMAL,
            finishYear: null,
        },
        {
            startDate: null,
            endDate: null,
        },
        "",
        null
    );

    public supplyStageImportTypes: SelectOption<SupplyStageImportType>[] = [
        { key: "KEEP", desc: "Использовать график поставок из файла" },
        { key: "SET_FOR_EACH", desc: "Заполнение графиков поставки в ручном режиме после загрузки" },
        { key: "SET_FOR_ALL", desc: "Задать новый график поставки" }
    ];

    public selectedImportType: SupplyStageImportType = "KEEP";

    public selectSupplyTermsGroup(v: SupplyStagePeriodType) {
        this.stage.type = v;
        switch (v) {
            case SupplyStagePeriodType.ABSOLUTE:
                this.stage.relativePeriod.startOffset = null;
                this.stage.relativePeriod.startOffsetType = DayType.NORMAL;
                this.stage.relativePeriod.endOffset = null;
                this.stage.relativePeriod.endOffsetType = DayType.NORMAL;
                this.stage.relativePeriod.finishYear = null;
                break;
            case SupplyStagePeriodType.RELATIVE:
                this.stage.absolutePeriod.endDate = null;
                this.stage.absolutePeriod.startDate = null;
                break;
        }
    }
    public relative: IRow<ContractSpecificationSupplyStage>[] = [
        h.integer("Начало поставки", p("relativePeriod", "startOffset"), {
            fieldDescription: "дней с момента заключения контракта"
        }),
        h.inlineRadio("", p("relativePeriod", "startOffsetType"), [
            { key: DayType.WORKDAY, desc: "Рабочие дни" },
            { key: DayType.NORMAL, desc: "Календарные дни" },
        ], "startDayType"),
        h.integer("Окончание поставки", p("relativePeriod", "endOffset"), {
            fieldDescription: "дней с момента заключения контракта"
        }),
        h.inlineRadio("", p("relativePeriod", "endOffsetType"), [
            { key: DayType.WORKDAY, desc: "Рабочие дни" },
            { key: DayType.NORMAL, desc: "Календарные дни" },
        ], "endDayType"),
        h.select("Год окончания поставки", p("relativePeriod", "finishYear"), createYearSelection(true))
    ];

    public absolute: IRow<ContractSpecificationSupplyStage>[] = [
        h.date("Дата начала поставки", p("absolutePeriod", "startDate"), { style: "SHORT" }),
        h.date("Дата окончания поставки", p("absolutePeriod", "endDate"), { style: "SHORT" })
    ];

    public conditions: IRow<ContractSpecificationSupplyStage>[] = [
        h.text("Условия поставки", ["conditions"])
    ];

    public specs: Specification[] | null = null;
    public openError = false;

    public async openFile(file: File | null) {
        this.specs = null;
        this.openError = false;

        if (!file) return;

        try {
            const json = await new Response(file).json();
            this.specs = (json as IDtoSpecification[]).map(x => Specification.fromJson(x));
        } catch (e) {
            console.log(e);
            this.openError = true;
        }
    }

    public get isStageOk() {
        return (this.stage.type === SupplyStagePeriodType.ABSOLUTE
                ? this.stage.absolutePeriod.startDate !== null &&
                  this.stage.absolutePeriod.endDate !== null &&
                  +this.stage.absolutePeriod.endDate >= +this.stage.absolutePeriod.startDate
                : this.stage.relativePeriod.endOffset !== null &&
                  this.stage.relativePeriod.endOffset > 0 &&
                  this.stage.relativePeriod.startOffset !== null &&
                  this.stage.relativePeriod.startOffset > 0 &&
                  this.stage.relativePeriod.endOffset >= this.stage.relativePeriod.startOffset &&
                  this.stage.relativePeriod.finishYear !== null) &&
            !!this.stage.conditions &&
            this.stage.conditions.trim().length > 0;
    }

    public get isOk() {
        return this.specs && (
            this.selectedImportType !== "SET_FOR_ALL" || this.isStageOk
        );
    }

    public loading = false;

    public async saveAndClose() {
        const specs = this.specs;
        if (!specs) return;

        switch (this.selectedImportType) {
            case "SET_FOR_EACH":
                for (const spec of specs) spec.supplyStages = [];
                break;
            case "SET_FOR_ALL":
                for (const spec of specs) spec.supplyStages = [
                    SupplyStage.fromJson({...this.stage.toJson(), volume: spec.volume.toString() })
                ];
                break;
        }

        for (const spec of specs) spec.startPrice = null;

        if ((await EventBus.callModal(ImportResultModal, { detail: this.prop, specs })).isOk) this.$emit("close-modal");
    }
}
