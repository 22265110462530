import { http, IFilterObject } from "./http";
import { User, UserCreate, UserExcelResponse, UserUpdate } from '@/models/User';
import Vue from 'vue';
import { IDtoUser } from '@/models/json/IDtoUser';
import Auth from './Auth';
import { RegistrationRequest } from "@/views/Register/requests/RegistrationRequestsSection";
import { convertPaged, IPagedItems, mapPage } from "@/api/Pagination";
import { Lot } from "@/models/ComposedLots";
import { uuid } from "@/models/parsing";

export default class {
    public static async getUsers(): Promise<User[]> {
        return ((await http.get("/api/admin/users/all")) as IDtoUser[]).map(User.fromJson);
    }

    public static async deleteUser(user: User): Promise<void> {
        await http.delete(`/api/admin/users/${user.id}`, undefined);
    }

    public static async lockUser(user: User): Promise<void> {
        await http.post(`/api/admin/users/${user.id}/lock`, undefined);
    }

    public static async unlockUser(user: User): Promise<void> {
        await http.post(`/api/admin/users/${user.id}/unlock`, undefined);
    }

    public static async createUser(user: UserCreate, password: string | null): Promise<User> {
        return User.fromJson(await http.post(`/api/admin/users/create`, { info: user, password }));
    }

    public static async editUser(user: UserUpdate, password: string | null): Promise<User> {
        return User.fromJson(await http.put(`/api/admin/users/${user.id}`, { info: user, password }));
    }

    public static async impersonateUser(user: User): Promise<void> {
        const r = await http.post(`/api/admin/users/${user.id}/impersonate`, undefined) as { accessToken: string };

        const oldToken = http.token;

        try {
            http.token = r.accessToken;
            Auth.currentUserInfo = await User.fromJson(await http.get("/api/users/info"));
        } catch (e) {
            http.token = oldToken;
            throw e;
        }
    }

    public static async depersonate(): Promise<void> {
        const r = await http.post(`/api/admin/users/depersonate`, undefined) as { accessToken: string };

        const oldToken = http.token;

        try {
            http.token = r.accessToken;
            Auth.currentUserInfo = await User.fromJson(await http.get("/api/users/info"));
        } catch (e) {
            http.token = oldToken;
            throw e;
        }
    }
}

export const getUsers = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<User>> => {
    return convertPaged(
        await http.getPaged("/api/v2/admin/users", {
            from,
            count,
            sortBy: "createdAt",
            sortDescending: true,
            filters
        }),
        User
    );
};

export const getArchivedUsers = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<User>> => {
    return convertPaged(
        await http.getPaged("/api/v2/admin/users/archived", {
            from: from,
            count: count,
            filters: filters
        }),
        User,
    );
};

export const unarchiveUser = async (id: uuid): Promise<void> => {
    return await http.post(`/api/v2/admin/users/${id}/unarchive`, undefined);
};

export const getRegistrationRequests = async (): Promise<RegistrationRequest[]> => {
    const result = await http.get("/api/v2/admin/users/regRequests");
    return result;
};

export const approveRegistrationRequest = async (id: string): Promise<void> => {
    const result = await http.post(`/api/v2/admin/users/regRequests/approve?requestId=${id}`, undefined);
};

export const denyRegistrationRequest = async (id: string): Promise<void> => {
    const result = await http.post(`/api/v2/admin/users/regRequests/deny?requestId=${id}`, undefined);
};

export const createUsersFromExcel = async (body: FormData): Promise<UserExcelResponse> => {
    console.log('in creates');
    const expPtId = Auth.currentUserInfo?.selectedParticipant?.id;
    //todo: add formdata requests to http.ts
    const result = await fetch("/api/v2/admin/users/createUsersFromExcel", {
        method: "POST",
        body,
        headers: {
            "Authorization": "Bearer " + http.token,
            "X-Expected-Participant-Id": expPtId?.toString() ?? "null"
        }
    }).then(r => r.json());
    // const result = await http.post(`/api/v2/admin/users/createUsersFromExcel`, body);
    console.log('after create', result);

    return result;
};
