var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-card',{attrs:{"title":"Информация для расчёта НМЦ","isLargeHeader":true,"padding":30}},[_c('div',{staticClass:"calc-info-grid"},[(_vm.calculationError)?_c('div',{staticClass:"error-hint"},[_vm._v(" "+_vm._s(_vm.calculationError)+" ")]):_vm._e(),_c('div',{staticClass:"field-label"},[_vm._v("СПГЗ:")]),_c('x-textbox',{staticStyle:{"grid-column":"span 5"},attrs:{"value":_vm.specification.subjectDeclaration.subject,"readonly":""}}),_c('div',{staticClass:"field-label"},[_vm._v("Количество:")]),_c('x-textbox',{attrs:{"value":_vm.specification.volume,"readonly":""}}),_c('div',{staticClass:"field-label"},[_vm._v("Валюта:")]),_c('x-textbox',{attrs:{"value":_vm.calculatedCurrency,"readonly":""}}),_c('div',{staticClass:"field-label"},[_vm._v("Ед. изм.:")]),_c('x-textbox',{attrs:{"value":_vm.specification.measurementUnit.name,"readonly":""}}),_c('div',{staticClass:"calculate-button"},[_c('x-button',{attrs:{"title":"Рассчитать НМЦ","color":"green"},on:{"click":_vm.calculate}})],1),_c('div',{staticClass:"field-label"},[_vm._v("НМЦ.:")]),_c('x-textbox',{attrs:{"value":_vm.calculatedValueStr,"readonly":""}}),_c('div',{staticClass:"save-button"},[_c('x-button',{attrs:{"icon":{ type: 'CLASS', name: 'save' },"disabled":!_vm.calculatedValue || _vm.isCalculationErrorCritical},on:{"click":_vm.save}})],1)],1)]),_c('x-card',{ref:"marketPricesCard",attrs:{"title":"Рыночные цены","isLargeHeader":true,"padding":30,"buttons":[
            {
                id: 'FILTER',
                icon: {
                    type: 'CLASS',
                    name: 'filter',
                },
                eventName: 'toggle-filters',
            },
            {
                id: 'SHOW_ALL',
                title: 'отобразить все/не все',
                eventName: 'show-all',
            } ]},on:{"show-all":function($event){return _vm.showAll('MARKET_PRICE')},"toggle-filters":function($event){_vm.showFilters['MARKET_PRICE'] = !_vm.showFilters['MARKET_PRICE']}}},[_c('d-paged-table',{attrs:{"headers":_vm.marketPricesHeaders,"paginationContext":_vm.marketPrices,"selection":_vm.pricesSelection,"showFilters":_vm.showFilters['MARKET_PRICE'],"config":_vm.marketPricesTableConfig}}),(!_vm.marketPrices || !_vm.marketPrices.items.length)?_c('div',{staticClass:"no-info"}):_vm._e(),_c('d-pager',{attrs:{"paginationContext":_vm.marketPrices}})],1),_c('x-card',{attrs:{"title":"Портал поставщиков","padding":30,"isLargeHeader":true,"buttons":[
            {
                id: 'FILTER',
                icon: {
                    type: 'CLASS',
                    name: 'filter',
                },
                eventName: 'toggle-filters',
            },
            {
                id: 'SHOW_ALL',
                title: 'отобразить все/не все',
                eventName: 'show-all',
            } ]},on:{"show-all":function($event){return _vm.showAll('PROVIDER_PORTAL')},"toggle-filters":function($event){_vm.showFilters['PROVIDER_PORTAL'] = !_vm.showFilters['PROVIDER_PORTAL']}}},[_c('d-paged-table',{attrs:{"headers":_vm.marketPricesHeaders,"paginationContext":_vm.providerPortals,"selection":_vm.pricesSelection,"showFilters":_vm.showFilters['PROVIDER_PORTAL'],"config":_vm.marketPricesTableConfig}}),(!_vm.providerPortals || !_vm.providerPortals.items.length)?_c('div',{staticClass:"no-info"}):_vm._e(),_c('d-pager',{attrs:{"paginationContext":_vm.providerPortals}})],1),_c('x-card',{attrs:{"title":"Предложения поставщиков","buttons":[
            {
                id: 'FILTER',
                icon: {
                    type: 'CLASS',
                    name: 'filter',
                },
                eventName: 'toggle-filters',
            },
            {
                id: 'ADD',
                icon: {
                    type: 'CLASS',
                    name: 'add',
                },
                eventName: 'add-market-price',
            },
            {
                id: 'SHOW_ALL',
                title: 'отобразить все/не все',
                eventName: 'show-all',
            } ],"padding":30,"isLargeHeader":true},on:{"add-market-price":_vm.addMarketPrice,"show-all":function($event){return _vm.showAll('PROVIDER_OFFER')},"toggle-filters":function($event){_vm.showFilters['PROVIDER_OFFER'] = !_vm.showFilters['PROVIDER_OFFER']}}},[_c('d-paged-table',{attrs:{"headers":_vm.marketPricesHeaders,"paginationContext":_vm.providerOffers,"selection":_vm.pricesSelection,"showFilters":_vm.showFilters['PROVIDER_OFFER'],"config":_vm.marketPricesTableConfig}}),(!_vm.providerOffers || !_vm.providerOffers.items.length)?_c('div',{staticClass:"no-info"}):_vm._e(),_c('d-pager',{attrs:{"paginationContext":_vm.providerOffers}})],1),_c('div',{ref:"addOfferCard",class:_vm.isAddOfferCardVisible ? 'add-offer-card-visible' : 'add-offer-card-hidden'},[_c('NewPriceSection',{attrs:{"subjectDeclaration":_vm.specification.subjectDeclaration},on:{"close":_vm.hideOfferForm,"new-offer":_vm.saveMarketPrice}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }