
































import { Component, Vue, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Subcriteria } from '@/models';

@Component({ components: { CardModal } })
export default class AddCriteria extends Vue {
    public subcriteria: Subcriteria = {
        name: null!,
        value: null,
        content: null,
        assessmentOrder: null
    };

    public get isOk() {
        return !!this.subcriteria.name && !!this.subcriteria.value && !!this.subcriteria.content && !!this.subcriteria.assessmentOrder;
    } 

    public saveAndClose() {
        if (this.isOk) {
            this.$emit("close-modal", ModalResult.ok(this.subcriteria));
        }
    }
}
