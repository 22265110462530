import { VueClass } from "vue-class-component/lib/declarations";
import { Vue } from "vue-property-decorator";
import { ValidationResult } from "../../TableAbstractions";
import { SelectOption } from '@/models/enums';

export interface Hint {
    text: string
    link?: {
        title: string
        href: string
    }
}

export type IHint = Hint;

export const enum TextAlignment {
    LEFT = "left",
    RIGHT = "right",
}

export declare type RowType =
    | "TEXT"
    | "DECIMAL"
    | "INTEGER"
    | "MULTILINE_TEXT"
    | "CHECKBOX"
    | "SELECT"
    | "RADIO_SELECT"
    | "PICK"
    | "DATE_RANGE"
    | "TERMS" // TODO: move to RAW
    | "FILE"
    | "INLINE_RADIO_SELECT"
    | "DATE_FIELD"
    | "MONTH_FIELD"
    | "AMOUNT_PERCENT"
    | "RAW"
    | "EMPTY";

export declare interface RowBase<T> {
    type: RowType

    visible?: (obj: T) => boolean
}

export declare interface GenericRow<T> extends RowBase<T> {
    titleWhitespace?: string
    title: string | ((obj: T) => string)

    editable?: boolean | ((obj: T) => boolean)
    required?: boolean
    hint?: IHint

    fieldDescription?: string | ((obj: T) => string)
    validate?(obj: T): ValidationResult
}

export declare interface ValueRow<T> {
    getter(obj: T): unknown
}

export declare interface MutatingRow<T> extends ValueRow<T> {
    readonly?: boolean | ((obj: T) => boolean)
    setter(obj: T, value: never): void
}

export declare type RowStyle = "NORMAL" | "SHORT";
export declare type PickRowStyle = "NORMAL" | "SHORT" | "RC" | "RC_SHORT";

export declare interface PickRow<T> extends GenericRow<T>, ValueRow<T> {
    type: "PICK"
    id: string
    style?: PickRowStyle
    add?: boolean
}

export declare interface MutatingTextInputRowBase<T> extends GenericRow<T>, MutatingRow<T> {
    alignment?: TextAlignment
    style?: RowStyle
}

export declare interface ReadOnlyTextInputRowBase<T> extends GenericRow<T>, ValueRow<T> {
    alignment?: TextAlignment
    style?: RowStyle
}

export declare type TextRow<T> = (MutatingTextInputRowBase<T> | ReadOnlyTextInputRowBase<T>) & {
    type: "TEXT"
    readonlyStyle?: 'disable' | 'nodisable'
};

export declare type MultilineTextRow<T> = (MutatingTextInputRowBase<T> | ReadOnlyTextInputRowBase<T>) & {
    type: "MULTILINE_TEXT"
    lines?: number
};

export declare type IntegerRow<T> = (MutatingTextInputRowBase<T> | ReadOnlyTextInputRowBase<T>) & {
    type: "INTEGER"
};

export declare type DecimalRow<T> = (MutatingTextInputRowBase<T> | ReadOnlyTextInputRowBase<T>) & {
    type: "DECIMAL"
    precision?: number
    readonlyStyle?: 'disable' | 'nodisable'
};

export declare interface SelectionRow<T> extends GenericRow<T>, MutatingRow<T> {
    type: "SELECT"
    style?: RowStyle
    selectOptions: SelectOption<unknown>[] | Promise<SelectOption<unknown>[]>
}

export declare interface RadioRow<T> extends GenericRow<T>, MutatingRow<T> {
    type: "RADIO_SELECT" | "INLINE_RADIO_SELECT"
    selectOptions: SelectOption<unknown>[] | Promise<SelectOption<unknown>[]>
    groupName: string
}

export declare interface DateRow<T> extends GenericRow<T>, MutatingRow<T> {
    type:
        | "DATE_FIELD"
        | "MONTH_FIELD"
    style?: RowStyle
}

export declare interface CheckboxRow<T> extends GenericRow<T>, MutatingRow<T> {
    type:
        | "CHECKBOX"
    style?: "RADIO" | "CHECKBOX"
}

export declare interface AmountPercentRow<T> extends GenericRow<T>, MutatingRow<T> {
    type: "AMOUNT_PERCENT"
    description: string
}

export declare interface DateRangeRow<T> extends GenericRow<T>, MutatingRow<T> {
    type: "DATE_RANGE"
    rangeStart?: (_: T) => Date | null
    rangeEnd?: (_: T) => Date | null
}

export declare interface SimpleRow<T> extends GenericRow<T>, MutatingRow<T> {
    type:
        | "TERMS"
        | "FILE"
}

export declare interface EmptyRow<T> extends GenericRow<T> {
    type: "EMPTY"
}

export declare interface RawComponentRow<T> extends RowBase<T>, MutatingRow<T> {
    type: "RAW"
    component: VueClass<Vue>
    full: boolean
}

// TODO: remove I prefix
export declare type IRow<T> =
    | PickRow<T>
    | SelectionRow<T>
    | RadioRow<T>
    | TextRow<T>
    | MultilineTextRow<T>
    | IntegerRow<T>
    | DecimalRow<T>
    | DateRow<T>
    | SimpleRow<T>
    | RawComponentRow<T>
    | CheckboxRow<T>
    | AmountPercentRow<T>
    | DateRangeRow<T>
    | EmptyRow<T>;

export declare interface IColumn<T> {
    header?: string
    columnSpan?: number
    rows: Array<IRow<T>>
}

export declare interface ISetValueEvent<T> {
    row: IRow<T>
    value: unknown
}
