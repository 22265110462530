import { Component, Prop, Vue } from "vue-property-decorator";
import SingleTableView from "@/components/SingleTableView.vue";
import router from "@/router";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import { Procedure } from '@/models/Procedure';
import { LawTypeStrings, ProviderSelectionTypeStrings, ObjectStatusStrings, ObjectStatus, LawType, ContractStatusStrings } from '@/models/enums';
import Decimal from 'decimal.js';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';

import { FilterValueType, FilterConditionType } from '@/api/http';
import EventBus, {showModal} from '@/EventBus';
import LawSelectionModal from '@/views/modals/LawSelectionModal';
import Api from '@/api/Contracts';
import { Contract } from '@/models/Contracts';
import { formatDate } from '@/DateFormatting';
import { formatNumber } from '@/NumberFormatting';
import { dateFilter, containsFilter, selectFilter, decimalFilter } from '@/components/TableHelpers';
import {ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";

@Component({ components: { SingleTableView, DataTable } })
export default class Contracts extends Vue {
    private isAddObjectModalVisible = false;
    private selectedLaw = "44";

    public onPageHeaderAction(id: string) {
        switch (id) {
            case "export":
                alert("asd");
                break;
            case "add":
                showModal(ErrorModal, { text: "Создание контракта доступно на странице лота", title: "Ошибка" });
                break;
            default: return;
        }
    }

    private headers: ITableColumn<Contract>[] = [
        {
            title: "Реестровый номер",
            getter(v) {
                return v.regNumber;
            },
            url(v) {
                return `/contracts/${v.id}`;
            },
            filter: containsFilter("regNumber")
        },
        {
            title: "Закон-основание",
            getter(v) {
                return v.law === "F44" ? "44-ФЗ" : "223-ФЗ";
            },
            filter: selectFilter("law", LawTypeStrings, false)
        },
        {
            title: "ИКЗ",
            getter(v) {
                return "";
            },
        },
        {
            title: "Номер контракта / договора",
            getter(v) {
                return v.contractNumber;
            },
            filter: containsFilter("contractNumber")
        },
        {
            title: "Реестровый номер ЕИС",
            getter(v) {
                return v.purchaseCode;
            },
            filter: containsFilter("purchaseCode")
        },
        {
            title: "Поставщик",
            getter(v) {
                return v.supplier.participant.shortName;
            },
            filter: containsFilter("participant")
        },
        {
            title: "Предмет контракта / договора",
            getter(v) {
                return v.subject;
            },
            filter: containsFilter("subject")
        },
        {
            title: "Дата заключения",
            getter(v) {
                return v.conclusionDate && formatDate(v.conclusionDate, true);
            },
            filter: dateFilter("conclusionDate",true)
        },
        {
            title: "Сумма, руб.",
            getter(v) {
                return formatNumber(v.cost);
            },
        },
        {
            title: "Статус",
            getter(v) {
                return ContractStatusStrings[v.status];
            },
            filter: selectFilter("status", ContractStatusStrings, false)
        },
        {
            title: "Малый объём",
            getter(v) {
                return v.isEshop ? "Да" : "Нет";
            },
        },
    ];

    public context: PaginationContext<Contract> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) => Api.get(n, sz, f), 20);
    }

    public async addContract() {
        const r = await EventBus.callModal<null, LawType>(LawSelectionModal, null);
        if (r.isOk) {
            router.push(`/contracts/create${r.getResult() === LawType.F223 ? '223' : '44'}`);
        }
    }
}
