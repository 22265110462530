import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, EditType, validationResult, IRowConfiguration } from "@/components/TableAbstractions";
import { FieldState } from "@/components/vue/form-table/FieldBase";

import { formatNumber } from "@/NumberFormatting";
import Decimal from "decimal.js";
import { FinancialSourceType } from "@/models/enums";
import { UsedSource } from "@/models/UsedSource";
import { PurchaseObject } from "@/models";

@Component({ components: { DataTable } })
export default class ProvisionSection extends Vue {
    @Prop() private source!: PurchaseObject;
    @Prop() private state!: FieldState;

    private headers: ITableColumn<UsedSource>[] = [
        {
            title: "ГРБС",
            visible: () => this.state !== FieldState.ENABLED,
            getter(src) { return "Нет" }
        },
        {
            title: "Тип",
            getter(src) {
                switch (src.source.type) {
                    case FinancialSourceType.OWN_FUNDS:
                        return "Собственные средства";
                    case FinancialSourceType.LIMIT:
                        return "???";
                    case FinancialSourceType.FIN_ACTIONS:
                        return "ПФХД";
                }
            },
        },
        {
            title: "Код",
            getter(src: UsedSource) {
                return src.source.format(true);
            },
        },
        {
            title: "Лимит, руб. Всего / Остаток",
            visible: () => this.state === FieldState.ENABLED,
            getter(src) {
                return formatNumber(src.source.total) + " / " + formatNumber(src.source.total.sub(src.source.used));
            },
        },
        {
            title: "Сумма, руб.",
            editable: true,
            type: EditType.DECIMAL,

            getter(src) {
                return src.amount;
            },
            setter(src, v: Decimal) {
                src.amount = v;
            },
            validate(src) {
                return validationResult(src.amount.isFinite());
            },
        },
    ];

    public config: IRowConfiguration<UsedSource> = {
        group(items) {
            const groupMap = new Map<number, UsedSource[]>();

            for (const item of items) {
                if (!groupMap.has(item.source.year)) groupMap.set(item.source.year, []);

                const arr = groupMap.get(item.source.year)!;
                arr.push(item);
            }

            return [...groupMap.keys()].sort().map(x => ({ header: x + " год", items: groupMap.get(x)! }));
        },
        showEditColumn: this.state !== FieldState.READONLY
    };

    private editItem(item: UsedSource) {
        this.$emit("form-pick", "provision", item);
    }

    private deleteItem(item: UsedSource) {
        this.source.financialSources = this.source.financialSources.filter(x => x !== item);
    }
}
