


















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Auth from "@/api/Auth";

@Component
export default class Sidebar extends Vue {
    @Prop({ default: false })
    public collapsed!: boolean;

    private hash: string = window.location.hash;
    @Watch('$route', { immediate: true })
    onHashChanged() {
        this.hash = window.location.hash;
    }

    updated() {
        console.log({ router: this.$router.currentRoute });
    }

    public get selectedYear() { return Auth.selectedYear }

    public get isExactActive() {
        if (!this.hash)
            return false;
        return !!this.hash.match(/2020\/.+\/trade/g)?.length;
    }

    public get isRequestsActive() {
        if(!this.hash) return false;
        return this.hash.includes('plan-objects/requests-for-conclusion/');
    }

    public toggle() {
        this.collapsed = !this.collapsed;
        this.$emit("toggle", this.collapsed);
    }
}
