import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import { FormStyle } from "@/views/FormAbstractions";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import { PurchaseObjectDetail } from "@/models/PurchaseObjectDetail";
import { AddressType } from "@/models/enums";

function debounced<FN extends (...args: any[]) => void>(delay: number, fn: FN) {
    let timerId: number | undefined;
    return ((...args) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = undefined;
        }, delay);
    }) as FN;
}

interface AddressSearchResult {
    aoguid: string
    aolevel: string
    formalname: string
    shortname: string
    parent?: AddressSearchResult
    directHouseCnt: number
}

interface HouseSearchResult {
    houseguid: string
    aoguid: string
    housenum: string
    buildnum: string
    strucnum: string
    postalcode: string
}

interface SearchResult {
    aoguid: string
    houseguid: string | null
    formalname: string
    fullname: string

    original?: AddressSearchResult
    parent?: AddressSearchResult
    isStreet: boolean
}

@Component({ components: { FormTable } })
export default class AddressSection extends Vue {
    @Prop() private source!: PurchaseObjectDetail;
    @Prop() private bus!: Vue;
    @Prop() private state!: FieldState;
    @Prop() private formStyle!: FormStyle;

    private nonFiasSearchType = "BTI";

    private fiasResults: SearchResult[] = [];
    private fiasInput = "";
    private selectedFiasResult: SearchResult | null = null;

    private get addrType(): AddressType {
        return this.source.address.type;
    }
    private set addrType(v: AddressType) {
        this.source.address.type = v;
    }

    public get nonFiasInput(): string {
        switch (this.addrType) {
            case AddressType.OUTSIDE_OF_RF:
                return this.outsideOfRfInput;
            case AddressType.LANDMARK:
                return this.landmarkInput;
            default:
                return "";
        }
    }

    public mounted() {
        switch (this.addrType) {
            case AddressType.OUTSIDE_OF_RF:
                this.outsideOfRfInput = this.source.address.addressLine;
                break;
            case AddressType.LANDMARK:
                this.landmarkInput = this.source.address.addressLine;
                break;
            case AddressType.FIAS:
                this.fiasInput = this.source.address.addressLine;
                break;
        }
    }

    public toggleFias(ev: Event) {
        this.addrType = this.addrType === AddressType.FIAS ? AddressType.BTI : AddressType.FIAS;
    }

    private outsideOfRfInput = "";
    private landmarkInput = "";

    @Watch("outsideOfRfInput") private updateOutsideRf() {
        this.source.address.addressLine = this.outsideOfRfInput;
    }

    public get landmarkAdditional(): string {
        return this.source.address.additionalInfo;
    }
    public set landmarkAdditional(l: string) {
        this.source.address.additionalInfo = l;
    }

    private matchAllDigits(s: string) {
        const re = /(\d+)/g;
        let m: RegExpExecArray | null = null;
        const result: string[] = [];

        do {
            m = re.exec(s);
            if (m) {
                result.push(m[1]);
            }
        } while (m);

        return result;
    }

    private sendFiasRequest(address: string, parent: AddressSearchResult | null) {
        const isStreet = parent ? parent.directHouseCnt > 0 : false;
        if (isStreet) {
            fetch(`/fias/api/search/address/${parent!.aoguid}/houses`, {
                method: "GET",
                mode: "cors",
            })
                .then(response => response.json())
                .then(response => {
                    const result = response as HouseSearchResult[];
                    this.fiasResults = result.filter(x => {
                        const digitGroups = this.matchAllDigits(address);

                        for (const dg of digitGroups) {
                            if (x.buildnum === dg || x.housenum === dg || x.strucnum === dg)
                                return true;
                        }

                        return false;
                    }).map(x => ({
                        aoguid: x.aoguid,
                        houseguid: x.houseguid,
                        formalname: x.housenum,
                        fullname: `д. ${x.housenum}${x.buildnum ? ', корп. ' + x.buildnum : ''}${x.strucnum ? ', стр. ' + x.strucnum : ''}`,
                        parent: parent!,
                        isStreet: false
                    }));
                });
        } else {
            fetch(`/fias/api/search/address`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    text: address,
                    aoguid: parent ? parent.aoguid : null
                }),
            })
                .then(response => response.json())
                .then(response => {
                    const result = response.searchResults as AddressSearchResult[];
                    this.fiasResults = result.map(x => ({
                        aoguid: x.aoguid,
                        houseguid: null,
                        formalname: x.formalname,
                        fullname: x.shortname + " " + x.formalname,
                        parent: x.parent,
                        isStreet: x.directHouseCnt > 0,
                        original: x
                    }));
                });
        }
    }

    private sendFiasRequestDebounced = debounced(200, this.sendFiasRequest);

    private fiasAddressLastResult: SearchResult | null = null;

    private parseInput(val: string) {
        if (!this.fiasAddressLastResult) return { address: val, parent: null };

        const nodeArr = [];

        let parent = this.fiasAddressLastResult.parent;
        while (parent) {
            nodeArr.push(parent);
            parent = parent.parent;
        }

        nodeArr.reverse();
        if (this.fiasAddressLastResult.original)
            nodeArr.push(this.fiasAddressLastResult.original);

        let prevParent: AddressSearchResult | null = null;
        let tailIx = 0;
        for (const node of nodeArr) {
            const ixOf = val.indexOf(node.formalname, tailIx);
            if (ixOf === -1) {
                break;
            }

            prevParent = node;
            tailIx = ixOf + node.formalname.length;
        }

        return { address: val.substring(tailIx), parent: prevParent };
    }

    private onAddressInput(val: string) {
        if (this.source.address.type === AddressType.FIAS) this.fiasInput = val;
        else this.landmarkInput = val;
        this.selectedFiasResult = null;

        const { address, parent } = this.parseInput(val);
        this.sendFiasRequestDebounced(address, parent);
    }

    private formatResult(result: SearchResult) {
        const resultArr = [];

        let parent = result.parent;
        while (parent) {
            resultArr.push(`${parent.shortname} ${parent.formalname}`);
            parent = parent.parent;
        }

        resultArr.reverse();
        resultArr.push(`${result.fullname}`);

        return resultArr.join(", ");
    }

    public selectFiasResult(result: SearchResult) {
        this.fiasAddressLastResult = result;
        const line = this.formatResult(result) + " "; // extra space for easy input

        if (this.source.address.type === AddressType.FIAS) {
            this.fiasInput = line;
            const el = this.$refs.fiasInput as
                unknown as {focus(): void; setCaretPosition(_: number): void; value: string};
            el.focus();
            el.setCaretPosition(el.value.length);
        } else {
            this.landmarkInput = line;
            const el = this.$refs.landmarkInput as
                unknown as {focus(): void; setCaretPosition(_: number): void; value: string};
            el.focus();
            el.setCaretPosition(el.value.length);
        }
        this.source.address.addressLine = line;
        this.selectedFiasResult = result;
    }
}
