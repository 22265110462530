import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { PurchaseObject } from "@/models";
import { ObjectStatus } from '@/models/enums';

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<PurchaseObject>> {
        return convertPaged(
            await http.getPaged("/api/purchaseObjects", {
                from: from,
                count: count,
                filters: filters
            }),
            PurchaseObject,
        );
    }

    public static async getById(id: string): Promise<PurchaseObject> {
        return PurchaseObject.fromJson(await http.get(`/api/purchaseObjects/${id}`));
    }

    public static async create(obj: PurchaseObject): Promise<PurchaseObject> {
        return PurchaseObject.fromJson(await http.post(`/api/purchaseObjects/create?law=${obj.law}`, obj.toJson()));
    }

    public static async update(obj: PurchaseObject): Promise<PurchaseObject> {
        return PurchaseObject.fromJson(await http.put(`/api/purchaseObjects/${obj.id}`, obj.toJson()));
    }

    public static async delete(id: string): Promise<void> {
        await http.delete(`/api/purchaseObjects/${id}`);
    }

    public static async setStatus(id: string, status: ObjectStatus): Promise<PurchaseObject> {
        return PurchaseObject.fromJson(await http.put(`/api/purchaseObjects/${id}/Status/${status}`, null));
    }
}
