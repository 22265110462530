import { EnumStrings } from "@/models/enums/EnumStrings";

export const enum SmallVolumeType {
    NO = "NO",
    UNDER_400 = "UNDER_400",
    UNDER_100 = "UNDER_100",
}

export const SmallVolumeTypeStrings: EnumStrings = {
    [SmallVolumeType.NO]: "Нет",
    [SmallVolumeType.UNDER_100]: "п.4 ч.1 ст.93 (до 300 тыс. рублей)",
    [SmallVolumeType.UNDER_400]: "п.5 ч.1 ст.93 (до 600 тыс. рублей)",
};
