import {FormStyle, IForm, TableSectionData} from "@/views/FormAbstractions";
import {actionL, actionR, classIcon, commonReadonlyActionsR, Converter, makeFormHelpers, pathfactory} from './helpers';
import {Procedure} from '@/models/Procedure';
import {
    LawType,
    LawTypeStrings,
    ObjectStatus,
    ProviderSelectionType,
    ProviderSelectionTypeStrings,
    toSelectOptions
} from '@/models/enums';
import FileTableRow from './raw-rows/FileTableRow/FileTableRow.vue';
import {FileTableInfo} from './raw-rows/FileTableRow/FileTableRow';
import ProcedureLotsSection from '@/views/form-renderers/ProcedureLotsSection/ProcedureLotsSection.vue';
import {TextAlignment} from '@/components/vue/form-table/FormFields';
import {CurrencyStrings} from '@/models/enums/Currency';
import {Lot, Participant} from '@/models';
import TableSection from '@/views/form-renderers/TableSection';
import {procDocsToFileTableInfo} from './converters';
import {DateTimeRange} from '@/models/DateTimeRange';
import {formatDate} from '@/DateFormatting';
import {LotDocumentType, LotDocumentTypeStrings} from '@/models/enums/Documents';
import {LotDocument} from '@/models/Documents';
import Decimal from 'decimal.js';
import Auth from '@/api/Auth';

const h = makeFormHelpers<Procedure>();
const p = pathfactory<Procedure>();

export declare interface IProcedureFormData {
    specialParticipants: Promise<Participant[]>
    creating?: boolean
}

export function FormDeclarationBuilder(readonly: boolean, data: IProcedureFormData): IForm<Procedure> {
    function formatTime(time?: Date | null) {
        if (!time) return  "";
        return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
    }
    function range(withTime: boolean): Converter<DateTimeRange, string> {
        return {
            convert(t) {
                return withTime
                    ? `с ${formatDate(t.from, true)} ${formatTime(t.from)} по ${formatDate(t.to, true)} ${formatTime(t.from)}`
                    : `с ${formatDate(t.from, true)} по ${formatDate(t.to, true)}`;
            },
            convertBack(_) { return null! }
        };
    }

    const participantSelection = data.specialParticipants.then(ps => {
        const mapped = ps.map(x => ({ key: x.id, desc: x.shortName }));
        mapped.splice(0, 0, { key: null!, desc: "Выберите организацию"});
        return mapped;
    });

    const containing = [
        ProviderSelectionType.OPENED_EXAM,
        ProviderSelectionType.QUATATIONS_REQUEST,
        ProviderSelectionType.OFFERS_REQUEST,
        ProviderSelectionType.TWOSTAGE_EXAM,
        ProviderSelectionType.LIMITED_EXAM,
    ];

    function isOrderSectionVisible(pp: Procedure) {
        return containing.includes(pp.providerSelectionType);
    }

    return {
        header: "Процедура",
        pages: [{
            id: "main",
            showNavigationHeader: true,
            sections: [
                h.fields("Общие сведения", readonly ? FormStyle.VERTICAL : FormStyle.HORIZONTAL, readonly ? 2 : 1, {
                    rows: [
                        h.enum("Закон-основание", "law", LawTypeStrings),
                        h.required().const().explicit().text("Организатор закупки",
                            () => Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.shortName, () => {}),
                        h.required().multiline("Описание закупки", "subject"),
                        h.required().readonly(x => x.lots.length > 0)
                            .select("Способ определения поставщика", "providerSelectionType",
                                toSelectOptions(ProviderSelectionTypeStrings, true, 'Выберите способ определения поставщика'))
                    ]
                }, {
                    rows: [
                        h.visible(v => !readonly || !!v.marketplace).required().select("Электронная торговая площадка", "marketplace", [
                            {key: null, desc: "Выберите торговую площадку"},
                            {key: 0, desc: "ЗАО \"Сбербанк - Автоматизированная система торгов\""},
                            {key: 1, desc: "ООО \"Единая электронная торговая площадка\""},
                            {key: 2, desc: "ООО \"РТС тендер\""},
                        ]),
                        h.visible(v => !readonly || !!v.documentationPreparer)
                            .select("Специализированная организация по подготовке документации", "documentationPreparer", participantSelection),
                        h.visible(() => !readonly).explicit().checkbox("Специализированная организация по проведению процедуры закупки",
                            x => x.procedurePerformer.equalToDocPreparer,
                            (x, v) => x.procedurePerformer = v ? { equalToDocPreparer: true } : { equalToDocPreparer: false, organization: null! }, {
                                fieldDescription: "Совпадает с организацией, разрабатывающей документацию"
                            }),
                        h.visible(() => !readonly).readonly(x => x.procedurePerformer.equalToDocPreparer).explicit().select("",
                            x => !x.procedurePerformer.equalToDocPreparer ? x.procedurePerformer.organization : null,
                            (x, v) => {
                                if (!x.procedurePerformer.equalToDocPreparer) x.procedurePerformer.organization = v as never;
                            }, participantSelection),
                        h.visible(x => readonly && !!(x.procedurePerformer.equalToDocPreparer ? x.documentationPreparer : x.procedurePerformer.organization))
                            .explicit().select("Специализированная организация по проведению процедуры закупки",
                                x => x.procedurePerformer.equalToDocPreparer ? x.documentationPreparer : x.procedurePerformer.organization,
                                () => {}, participantSelection),
                    ]
                }, {
                    rows: readonly ? [
                        h.text("Номер решения о проведении закупки", "decisionNumber"),
                        h.date("Дата решения о проведении закупки", "decisionDate"),
                    ] : [],
                    columnSpan: readonly ? 2 : 1
                }, {
                    header: "Документы лотов",
                    rows: [
                        h.readonly().explicit().raw<FileTableInfo<Lot, LotDocumentType, LotDocument>>(FileTableRow, x => ({
                            groups: x.initialLots.map(l => ({
                                source: l,
                                documents: l.lotDocuments
                            })),
                            additionalHeadersBefore: [{
                                title: "№ лота",
                                getter(l) { return l.source.regNumber },
                                size: "auto"
                            }, {
                                title: "Наименование лота",
                                getter(l) { return l.source.subject }
                            }],
                            strings: LotDocumentTypeStrings
                        }), () => {})
                    ],
                    columnSpan: readonly ? 2 : 1
                }, {
                    header: "Документы закупки",
                    rows: !readonly ? [
                        h.text("Номер решения о проведении закупки", "decisionNumber"),
                        h.date("Дата решения о проведении закупки", "decisionDate", {
                            style: "SHORT"
                        }),
                        h.converted(procDocsToFileTableInfo(readonly)).explicit().raw(FileTableRow, x => x, () => {})
                    ] : [h.converted(procDocsToFileTableInfo(readonly)).explicit().raw(FileTableRow, x => x, () => {})],
                    columnSpan: readonly ? 2 : 1
                }, {
                    header: "Ответственное должностное лицо",
                    rows: [
                        h.const().required().explicit().text("ФИО", () => "Матерова Елена Викторовна", () => {}),
                        h.const().required().explicit().text("Электронная почта", () => "sp11@zdrav.mos.ru", () => {}),
                        h.const().required().explicit().text("Номер телефона", () => "+7 (495) 685-38-4", () => {})
                    ],
                    columnSpan: readonly ? 2 : 1
                }), h.visible(isOrderSectionVisible).fields("Документация и приём заявок", FormStyle.HORIZONTAL, 1, {
                    header: "Место и порядок предо/ставления документации о процедуре закупки",
                    rows: [
                        h.required().text("Адрес", p("documentationOrder", "address")),
                        h.text("Кабинет", p("documentationOrder", "room")),
                        h.required().text("Электронная почта", p("documentationOrder", "email")),
                        h.required().text("Телефон", p("documentationOrder", "phone")),
                        h.required().text("Факс", p("documentationOrder", "fax")),
                        h.required().text("Контактное лицо", p("documentationOrder", "contactPerson")),
                        h.required().checkbox("Стандартный порядок", p("documentationOrder", "orderDescription", "default")),
                        h.required().readonly(x => x.documentationOrder.orderDescription.default)
                            .multiline("Порядок проведения", p("documentationOrder", "orderDescription", "text")),
                    ]
                }, {
                    header: "Место и порядок приема заявок на участие в процедуре закупки",
                    rows: [
                        h.required().text("Адрес", p("requestAcceptanceOrder", "address")),
                        h.text("Кабинет", p("requestAcceptanceOrder", "room")),
                        h.required().text("Электронная почта", p("requestAcceptanceOrder", "email")),
                        h.required().text("Телефон", p("requestAcceptanceOrder", "phone")),
                        h.required().text("Факс", p("requestAcceptanceOrder", "fax")),
                        h.required().text("Контактное лицо", p("requestAcceptanceOrder", "contactPerson")),
                        h.required().integer("Количество копий заявки", p("requestAcceptanceOrder", "copies"), {
                            alignment: TextAlignment.LEFT
                        }),
                        h.required().checkbox("Стандартный порядок", p("requestAcceptanceOrder", "orderDescription", "default")),
                        h.required().readonly(x => x.documentationOrder.orderDescription.default)
                            .multiline("Порядок подачи", p("requestAcceptanceOrder", "orderDescription", "text")),
                    ]
                }, {
                    header: "Размер и порядок внесения платы, взимаемой за предоставление документации о закупке",
                    rows: [
                        h.checkbox("Взимается плата", p("paymentOrder", "paymentRequired"), {
                            style: "RADIO"
                        }),
                        h.visible(x => x.paymentOrder.paymentRequired).required().text("Размер оплаты", p("paymentOrder", "volume")),
                        h.visible(x => x.paymentOrder.paymentRequired).required()
                            .select("Валюта", p("paymentOrder", "currency"), toSelectOptions(CurrencyStrings, true, "Выберите валюту")),
                        h.visible(x => x.paymentOrder.paymentRequired).checkbox("Облагается НДС", p("paymentOrder", "taxRequired")),
                        h.visible(x => x.paymentOrder.paymentRequired && x.paymentOrder.taxRequired)
                            .multiline("", p("paymentOrder", "taxDescription")),
                        h.visible(x => x.paymentOrder.paymentRequired).required()
                            .select("Лицевой счет", p("paymentOrder", "account"), [
                                { key: null, desc: "Выберите лицевой счет" },
                                { key: { bankId: "4537654", bankName: "Сбербанк", bankCity: "Москва" }, desc: "456123123" },
                                { key: { bankId: "4865465", bankName: "ВТБ", bankCity: "Санкт-Петербург" }, desc: "678432023" },
                                { key: { bankId: "5465743", bankName: "Промсвязьбанк", bankCity: "Екатеринбург" }, desc: "766459022" },
                            ]),
                        h.visible(x => x.paymentOrder.paymentRequired).readonly().required()
                            .text("БИК", p("paymentOrder", "account", "bankId")),
                        h.visible(x => x.paymentOrder.paymentRequired).readonly().required()
                            .text("Наименование банка", p("paymentOrder", "account", "bankName")),
                        h.visible(x => x.paymentOrder.paymentRequired).readonly().required()
                            .text("Город", p("paymentOrder", "account", "bankCity")),
                        h.visible(x => x.paymentOrder.paymentRequired).readonly().required()
                            .explicit().text("Корр. счет", () => "", () => {}),
                        h.visible(x => x.paymentOrder.paymentRequired).multiline("Порядок внесения платы", p("paymentOrder", "orderDescription")),
                    ]
                }), h.visible(x => readonly && x.publishTerms !== null).fields("Сроки проведения", FormStyle.VERTICAL, 2, {
                    rows: [
                        h.readonly().date("Дата публикации извещения на официальном сайте", p("publishTerms", "publishDate")),
                        h.readonly().visible(x => !!x.publishTerms!.documentExplainationRange)
                            .converted(range(false))
                            .text("Период предоставления разъяснений положений документации",
                                p("publishTerms", "documentExplainationRange")),
                        h.readonly()
                            .visible(x => !!x.publishTerms!.executionDate)
                            .date("Дата проведения электронного аукциона", p("publishTerms", "executionDate")),
                    ]
                }, {
                    rows: [
                        h.readonly().converted(range(true)).text("Прием заявок", p("publishTerms", "requestAcceptRange")),
                        h.readonly().visible(x => !!x.publishTerms!.firstRequestConsiderationFinishDate)
                            .date("Дата окончания рассмотрения первых частей заявок",
                                p("publishTerms", "firstRequestConsiderationFinishDate")),
                        h.readonly().visible(x => !!x.publishTerms!.finalOffersDate)
                            .date("Дата подачи окончательных предложений о цене контракта",
                                p("publishTerms", "finalOffersDate")),
                        h.readonly().visible(x => !!x.publishTerms!.secondRequestConsiderationFinishDate)
                            .date("Дата окончания рассмотрения и оценки первых частей заявок",
                                p("publishTerms", "secondRequestConsiderationFinishDate")),
                    ]
                }), {
                    type: "UNTYPED",
                    name: "Лоты",
                    component: ProcedureLotsSection,
                    data: undefined,
                }, {
                    type: "TABLE",
                    name: "Обмен с АСУ ГФ",
                    component: TableSection,
                    visible: () => !data.creating,
                    data: {
                        headers: [
                            { title: '№', getter: () => '', size: 'auto' },
                            { title: 'Исходящий файл', getter: () => '' },
                            { title: 'Дата отправки', getter: () => '' },
                            { title: 'Подтверждение получения', getter: () => '' },
                            { title: 'Дата приема подтверждения', getter: () => '' },
                            { title: 'Сведения о согласовании', getter: () => '' },
                            { title: 'Дата получения сведений', getter: () => '' },
                        ],
                        getter(v) { return [] }
                    }
                }, {
                    type: "TABLE",
                    name: "Запросы в МКР",
                    component: TableSection,
                    visible: () => readonly,
                    data: {
                        headers: [{
                            title: "№",
                            getter: () => "1",
                            size: "auto"
                        }, {
                            title: "Статус по запросу",
                            getter: () => "Request.xml",
                            url: () => () => {}
                        }, {
                            title: "Комментарий",
                            getter: () => ""
                        }, {
                            title: "Дата запроса",
                            getter: () => "Ticket.xml"
                        }, {
                            title: "Пользователь",
                            getter: () => ""
                        }],
                        getter(v) { return [] }
                    }
                }, {
                    type: "TABLE",
                    name: "Обмен с ЕИС",
                    component: TableSection,
                    visible: () => !data.creating,
                    data: {
                        headers: [{
                            title: "Номер",
                            getter: () => "1",
                            size: "auto"
                        }, {
                            title: "Исходящий файл",
                            getter: () => "Request.xml",
                            url: () => () => {}
                        }, {
                            title: "Дата отправки",
                            getter: () => ""
                        }, {
                            title: "Подтверждение получения",
                            getter: () => "Ticket.xml"
                        }, {
                            title: "Дата приема подтверждения",
                            getter: () => ""
                        },
                        {
                            title: "Сведения о публикации",
                            getter: () => ""
                        },
                        {
                            title: "Дата получения сведений",
                            getter: () => ""
                        }],
                        getter(v) { return [] }
                    }
                }, {
                    type: "TABLE",
                    name: "История изменений",
                    component: TableSection,
                    visible: () => !data.creating,
                    data: {
                        headers: [
                            { title: 'Пользователь', getter: src => src.user },
                            { title: 'Дата и время изменения', getter: src => src.date },
                            { title: 'Статус', getter: src => src.status },
                        ],
                        getter(v) { return [
                            {
                                user: 'Иванов Иван Иванович',
                                date: '25.07.2019 12:47',
                                status: 'Внесение изменений',
                            }
                        ]; }
                    } as TableSectionData<Procedure, {user: string; date: string; status: string}> as never
                },
            ],
        }],
        actions(pp: Procedure) {
            if (readonly) {
                const procsWithoutDocs = [
                    ProviderSelectionType.E_QUOTATIONS_REQUEST
                ];
                const docsBtn = pp.law === LawType.F223 || procsWithoutDocs.includes(pp.providerSelectionType)
                    ? []
                    : [actionL("download-docs", "green", "Документация")];

                switch (pp.status) {
                    case ObjectStatus.CREATING:
                        const canImmediatelyPublish =
                            pp.law === LawType.F223 &&
                            pp.lots.reduce((r, v) => r.add(v.totalStartPrice), new Decimal(0)).lt(3000000);
                        return [
                            ...docsBtn,
                            canImmediatelyPublish
                                ? actionL("pre-publish", "green", "Отправить на публикацию")
                                : actionL("send-to-grbs", "green", "На согласование в РГ ГРБС"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close")),
                            actionR("delete", "red", classIcon("delete"))
                        ];
                    case ObjectStatus.GRBS_AGREEMENT_PENDING:
                        return [
                            ...docsBtn,
                            actionL("cancel-grbs-agreement", "green", "Отозвать с рассмотрения ГРБС"),
                            actionL("grbs-agree", "orange", "Согласовать в ГРБС (тест)"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close"))
                        ];
                    case ObjectStatus.GRBS_AGREED: case ObjectStatus.FO_AGREED:
                        return [
                            ...docsBtn,
                            actionL("pre-publish", "green", "Отправить на публикацию"),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close")),
                            actionR("delete", "red", classIcon("delete"))
                        ];
                    case ObjectStatus.PUBLISH_PENDING:
                    case ObjectStatus.PUBLISH_DATE_SET:
                        return [
                            ...docsBtn,
                            actionL("set-publish-date", "green", "Установить сроки публикации"),
                            actionL("publish", "green", "Опубликовать",
                                x => x.status === ObjectStatus.PUBLISH_DATE_SET &&
                                    x.lots.every(lot => lot.status === ObjectStatus.PUBLISHED)),
                            actionR("edit", "blue", classIcon("edit")),
                            actionR("close", "red", classIcon("close"))
                        ];
                    case ObjectStatus.PUBLISHED:
                        return [
                            ...docsBtn,
                            actionL("additional-info", "green", "Внести изменения"),
                            actionL("complete-sales", "orange", "Завершить торги (тест)"),
                            actionR("close", "red", "Сформировать информацию об отмене"),
                            actionR("close", "red", classIcon("close"))
                        ];
                    default:
                        return commonReadonlyActionsR;
                }
            } else {
                return [
                    actionL("save", "blue", classIcon("save")),
                    actionR("close", "red", classIcon("close"))
                ];
            }
        },
        showFooterActions: !readonly,
    };
}
