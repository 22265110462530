import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import EventBus from '@/EventBus';

import LotSelectionModal from '@/views/modals/LotSelectionModal';
import { Lot } from '@/models';
import { MultiSelection, ITableColumn } from '@/components/TableAbstractions';
import { LawTypeStrings, SmpTypeStrings, ProviderSelectionType } from '@/models/enums';
import { formatNumber } from '@/NumberFormatting';
import { FieldState } from '@/components/vue/form-table/FieldBase';
import { IRow } from '@/components/vue/form-table/FormFields';
import { Procedure } from '@/models/Procedure';

@Component({ components: { DataTable } })
export default class ProcedureLotsSection extends Vue {
    @Prop() private state!: FieldState;
    @Prop() private row!: IRow<Procedure>;
    @Prop() private source!: Procedure;

    public selected: MultiSelection<Lot> = { tag: "MULTI", items: [] };
    public get dataset() { return this.source.lots }
    public set dataset(v) { this.source.lots = v }

    public headers: ITableColumn<Lot>[] = [
        {title: 'Номер лота', getter(n) { return n.regNumber } },
        {title: 'Закон-основание', getter(n) { return LawTypeStrings[n.law] } },
        {title: 'Наименование лота', getter(n) { return n.subject }, size: '4fr' },
        {title: 'НМЦ', getter(n) { return formatNumber(n.totalStartPrice) } },
        {title: 'Размещение среди СМП/МСП', getter(n) { return SmpTypeStrings[n.smpType] } }
    ];

    public removeLots() {
        this.dataset = this.dataset.filter(x => !this.selected.items.find(y => y.id === x.id));
    }

    public async addLot() {
        const r = await EventBus.callModal<ProviderSelectionType, Lot[]>(LotSelectionModal, this.source.providerSelectionType);
        if (r.isOk) {
            const result = r.getResult();

            const total = this.dataset.concat(result.filter(x => !this.dataset.find(y => y.id === x.id)));
            
            if (total.length > 1) {
                switch (this.source.providerSelectionType) {
                    case ProviderSelectionType.E_AUC:
                    case ProviderSelectionType.QUATATIONS_REQUEST:
                        EventBus.raiseError("Для электронного аукциона и запроса котировок может быть прикреплен только 1 лот");
                        return;
                    case ProviderSelectionType.E_EXAM:
                    case ProviderSelectionType.E_LIMITED_EXAM:
                    case ProviderSelectionType.E_OFFERS_REQUEST:
                    case ProviderSelectionType.E_QUOTATIONS_REQUEST:
                    case ProviderSelectionType.E_TWOSTAGE_EXAM:
                        EventBus.raiseError("Для электронного конкурса, электронного конкурса " +
                            "с ограниченным участием, электронного двухэтапного конкурса, электронного " +
                            "запроса котировок и электронного запроса предложений может быть прикреплен только 1 лот");
                        return;
                }
            }

            this.dataset = total;
        }
    }
}
