import { EnumStrings } from "@/models/enums/EnumStrings";

/* @deprecated Use only for compatibility 2019-version */
export const enum SpecialPurchaseType {
    NO = "NO",
    PT_7 = "PT_7",
    PT_23 = "PT_23",
    PT_26 = "PT_26",
    PT_33 = "PT_33",
}

/* @deprecated Use only for compatibility 2019-version */
export const SpecialPurchaseTypeStrings: EnumStrings = {
    [SpecialPurchaseType.NO]: "Нет",
    [SpecialPurchaseType.PT_7]: "п.7 ч.2 ст.83 (лекарственные препараты)",
    [SpecialPurchaseType.PT_23]: "п.23 ч.1 ст.93 (содержание и ремонт помещений)",
    [SpecialPurchaseType.PT_26]: "п.26 ч.1 ст.93 (направление работника в служебную командировку)",
    [SpecialPurchaseType.PT_33]: "п.33 ч.1 ст.93 (преподавательские услуги, услуги экскурсовода (гида))",
};
