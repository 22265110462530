export type CharacteristicValueType =
    | "NUMBER"
    | "SIGN"
    | "DATE"
    | "TEXT"
    | "DICTIONARY";

export const CharacteristicValueType: { [key in CharacteristicValueType]: CharacteristicValueType } = {
    NUMBER: "NUMBER",
    SIGN: "SIGN",
    DATE: "DATE",
    TEXT: "TEXT",
    DICTIONARY: "DICTIONARY",
};