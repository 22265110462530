import {CompatEnum, EnumStrings, UnionStrings} from '../EnumStrings';
import { ProviderSelectionType } from '../ProviderSelectionType';

export type ProcedureDocumentType44 =
    | "SALE_ORDER"
    | "OTHER"
    | "COMPARATIVE_TABLE"
    | "REQUEST_FORM";

export type ProcedureDocumentType223 =
    | "SALE_ORDER"
    | "OTHER"
    | "PROCEDURE_DOCS"
    | "COMPARATIVE_TABLE"
    | "REQUEST_FORM";

export type ProcedureDocumentType = ProcedureDocumentType44 | ProcedureDocumentType223;

export const ProcedureDocumentType: CompatEnum<ProcedureDocumentType> = {
    SALE_ORDER: "SALE_ORDER",
    OTHER: "OTHER",
    PROCEDURE_DOCS: "PROCEDURE_DOCS",
    COMPARATIVE_TABLE: "COMPARATIVE_TABLE",
    REQUEST_FORM: "REQUEST_FORM"
};

export const Procedure223DocumentTypeStrings: UnionStrings<ProcedureDocumentType223> = {
    SALE_ORDER: "Приказ о проведении закупки",
    OTHER: "Прочее",
    PROCEDURE_DOCS: "Документация о закупке",
    COMPARATIVE_TABLE: "Сравнительная таблица внесённых изменений",
    REQUEST_FORM: "Форма заявки"
};

export const Procedure44DocumentTypeStrings: UnionStrings<ProcedureDocumentType44> = {
    SALE_ORDER: "Приказ о проведении закупки",
    OTHER: "Прочее",
    COMPARATIVE_TABLE: "Сравнительная таблица внесённых изменений",
    REQUEST_FORM: "Форма заявки"
};

export const ProcedureDocumentProvStrings: EnumStrings = {
    [ProviderSelectionType.E_AUC]: "Документация об электронном аукционе",
    [ProviderSelectionType.E_EXAM]: "Документация об электронном конкурсе",
    [ProviderSelectionType.E_TWOSTAGE_EXAM]: "Документация об электронном двухэтапном конкурсе",
    [ProviderSelectionType.E_LIMITED_EXAM]: "Документация об электронном конкурсе с ограниченным участием",
    [ProviderSelectionType.E_OFFERS_REQUEST]: "Документация об электронном запросе предложений",
    [ProviderSelectionType.E_QUOTATIONS_REQUEST]: "Документация об электронном запрос котировок",
    [ProviderSelectionType.OPENED_EXAM]: "Документация об открытом конкурсе",
    [ProviderSelectionType.QUATATIONS_REQUEST]: "Документация о запросе котировок",
    [ProviderSelectionType.ONE_PROVID_PURCHASE]: "Документация о закупке у единственного поставщика",
    [ProviderSelectionType.OFFERS_REQUEST]: "Документация о запросе предложений",
    [ProviderSelectionType.TWOSTAGE_EXAM]: "Документация о двухэтапном конкурсе",
    [ProviderSelectionType.LIMITED_EXAM]: "Документация о конкурсе с ограниченным участием",
};
