



























import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { IObjectDeletionModalArgs } from "@/views/modals/ModalArgs";
import { PurchaseObject, StartPrice } from '../../../models';
import { ObjectStatusStrings } from '../../../models/enums';
import { StartPriceMethod } from '../../../models/enums/StartPriceMethod';
import Decimal from 'decimal.js';

export declare interface CalcImbProps {
    price: StartPrice
    volume: Decimal
}

@Component({ components: { CardModal } })
export default class CalculationImbalanceModal extends ModalVue<CalcImbProps, boolean> {
    @Prop() public prop!: CalcImbProps;

    public confirm() {
        this.$emit("close-modal", ModalResult.ok(true));
    }

    // todo
    public priceChangeAllowed = 
        this.prop.price.method !== StartPriceMethod.MARKET_PRICES &&
        this.prop.price.method !== StartPriceMethod.TARIFF;

    public suggestedRatio = 
        this.prop.volume.mul(this.prop.price.perItem).div(this.prop.price.total).toNearest(0.00000000001, Decimal.ROUND_UP);

    public lowerPrice = 
        this.prop.volume.mul(this.prop.price.perItem);

    public higherPrice = 
        this.prop.price.multiplied.div(this.prop.volume).toNearest(0.01, Decimal.ROUND_UP).mul(this.prop.volume);

    public selectPrice(d: Decimal) {
        this.prop.price.total = d;
        this.prop.price.ratio = new Decimal(1);
        this.prop.price.perItem = d.div(this.prop.volume);
        this.$emit("close-modal", ModalResult.ok(false));
    }

    public setRatio() {
        this.prop.price.ratio = this.suggestedRatio;
        this.$emit("close-modal", ModalResult.ok(false));
    }

    public forceApply() {
        this.$emit("close-modal", ModalResult.ok(true));
    }
}
