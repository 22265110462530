import { ISection, IconType, IForm, FormStyle, IAction, TableSectionData } from "@/views/FormAbstractions";
import FieldSection from "@/views/form-renderers/FieldSection/FieldSection.vue";
import DocumentationSection from "@/views/form-renderers/DocumentationSection/DocumentationSection.vue";
import StartPriceSection from "@/views/form-renderers/StartPriceSection";
import SpecificationsSection from "@/views/form-renderers/SpecificationsSection/SpecificationsSection.vue";
import {
    LawType,
    JointLotType,
    toSelectOptions,
    LotContractTypeStrings,
    ProviderSelectionTypeStrings,
    ProviderSelectionType,
    LotContractType,
    ObjectStatus,
} from "@/models/enums";
import { Lot } from "@/models/Lot";
import Decimal from "decimal.js";
import { JointLotOrganizerType, JointLotOrganizerTypeStrings } from "@/models/enums/JointLotOrganizerType";
import DictionarySection from "@/views/form-renderers/DictionarySection/DictionarySection";
import { formatNumber } from "@/NumberFormatting";
import { IRow } from "@/components/vue/form-table/FormFields";
import { nonEmpty, nonNull } from './validators';
import ChangeHistorySection from '@/views/form-renderers/ChangeHistorySection/ChangeHistorySection';
import { merge, commonReadonlyActionsL, commonReadonlyActionsR, actionR, actionL, classIcon, commonReadonlyNoEditActionsL } from './helpers';
import { SingleProviderReason } from '@/models/SingleProviderReason';
import TableSection from '@/views/form-renderers/TableSection/TableSection';
import { Subcriteria } from '@/models';
import Auth from '@/api/Auth';

export default function(readonly: boolean, singleProviderReasons: Promise<SingleProviderReason[]>): IForm<Lot> {
    async function singleProviderReasonOptions() {
        const reasons = await singleProviderReasons;
        return reasons.map(x => ({ key: x, desc: x.name }));
    }

    function normalize(n: Decimal, min: number, max: number) {
        return n.isFinite() ? n.gte(min) ? n.lte(max) ? n : new Decimal(max) : new Decimal(min) : new Decimal(min);
    }

    const multiLotFields: { org: IRow<Lot>; orgType: IRow<Lot> } = {
        orgType: {
            title: "Тип организатора",
            type: "RADIO_SELECT",
            visible: p => p.jointLotType === JointLotType.JOINT_LOT,
            groupName: "organizerType",

            selectOptions: toSelectOptions(JointLotOrganizerTypeStrings),
            getter: p => p.jointLotOrganizerType,
            setter: (p, v: JointLotOrganizerType) => (p.jointLotOrganizerType = v),
        },
        org: {
            title: "Организатор",
            type: "PICK",
            id: "org",

            visible: p => p.jointLotType === JointLotType.JOINT_LOT,
            getter: () => null
        },
    };

    const requestProvisionDisabledOn = [
        ProviderSelectionType.QUATATIONS_REQUEST,
        ProviderSelectionType.ONE_PROVID_PURCHASE,
    ];

    const requestProvisionUnavailablePlaceholder: IRow<Lot> = {
        title: "Нет",
        type: "EMPTY",
        visible: p => !p.requestProvision.used
    };

    const requestProvisionField: IRow<Lot> = readonly
        ? {
            title: "Размер обеспечения",
            type: "TEXT",
            style: "SHORT",
            visible: p => p.requestProvision.used,

            getter: p =>
                p.requestProvision.used
                    ? p.requestProvision.percent!.toString() + " %"
                    : ""
        }
        : {
            title: "Размер обеспечения заявки (%)",
            type: "DECIMAL",
            style: "SHORT",
            fieldDescription: p =>
                p.requestProvision.used
                    ? "= " +
                    formatNumber(p.requestProvision.percent!.div(100).mul(p.totalStartPrice)) +
                    " Российский рубль"
                    : "",
            visible: p => p.requestProvision.used,

            getter: p =>
                p.requestProvision.used
                    ? p.requestProvision.percent
                    : new Decimal(0),
            setter: (p, v: Decimal) => p.requestProvision.used ? (p.requestProvision.percent = normalize(v, 0, 100)) : null,
        };

    const requestProvisionSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Обеспечение заявки",
        component: FieldSection,
        visible: p => !requestProvisionDisabledOn.includes(p.providerSelectionType!),

        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Взимается обеспечение заявки",
                            type: "CHECKBOX",

                            getter: p => p.requestProvision.used,
                            setter: (p, v: boolean) => {
                                p.requestProvision.used = v;
                            },
                        },
                        ...(readonly ? [] : [requestProvisionUnavailablePlaceholder]),
                        requestProvisionField,
                    ],
                },
            ],
        },
    };

    const contractProvisionUnavailablePlaceholder: IRow<Lot> = {
        title: "Обеспечение исполнения контракта не указывается",
        type: "EMPTY",
        visible: p => !p.contractProvision.used
    };

    const guaranteeProvisionUnavailablePlaceholder: IRow<Lot> = {
        title: "Обеспечение исполнения гарантийных обязательств не указывается",
        type: "EMPTY",
        visible: p => !p.guaranteeProvision.used
    };

    const contractProvisionField: IRow<Lot> = readonly
        ? {
            title: "Размер обеспечения",
            type: "TEXT",
            style: "SHORT",
            visible: p => p.contractProvision.used,

            getter: p =>
                p.contractProvision.used
                    ? p.contractProvision.percent!.toString() + " %"
                    : ""
        }
        : {
            title: p => `Размер обеспечения исполнения ${p.law === LawType.F223 ? 'договора' : 'контракта'} (%)`,
            type: "DECIMAL",
            style: "SHORT",
            fieldDescription: p =>
                p.contractProvision.used
                    ? "= " +
                    formatNumber(p.contractProvision.percent!.div(100).mul(p.totalStartPrice)) +
                    " Российский рубль"
                    : "",
            visible: p => p.contractProvision.used,

            getter: p =>
                p.contractProvision.used
                    ? p.contractProvision.percent
                    : new Decimal(0),
            setter: (p, v: Decimal) => p.contractProvision.used ? (p.contractProvision.percent = normalize(v, 0, 100)) : null,
            // validate: p => validationResult(!p.contractProvision.used ||
            //     (p.contractProvision.percent.gte(1) && p.contractProvision.percent.lte(100)),
            //     "Размер обеспечения контракта не может быть меньше 1% или превышать 100%")
        };

    const guaranteeProvisionField: IRow<Lot> = readonly
        ? {
            title: "Размер обеспечения",
            type: "TEXT",
            style: "SHORT",
            visible: p => p.guaranteeProvision.used,
            getter: p =>
                p.guaranteeProvision.used
                    ? p.guaranteeProvision.percent!.toString() + " %"
                    : ""
        }
        : {
            title: p => `Размер обеспечения исполнения гарантийных обязательств'} (%)`,
            type: "DECIMAL",
            style: "SHORT",
            fieldDescription: p =>
                p.guaranteeProvision.used
                    ? "= " +
                    formatNumber(p.guaranteeProvision.percent!.div(100).mul(p.totalStartPrice)) +
                    " Российский рубль"
                    : "",
            visible: p => p.guaranteeProvision.used,

            getter: p =>
                p.guaranteeProvision.used
                    ? p.guaranteeProvision.percent
                    : new Decimal(0),
            setter: (p, v: Decimal) => p.guaranteeProvision.used ? (p.guaranteeProvision.percent = normalize(v, 0, 100)) : null,
        };

    const contractProvisionSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Обеспечение контракта",
        component: FieldSection,
        visible: p => p.jointLotType !== JointLotType.JOINT_PURCHASE,

        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Взимается обеспечение исполнения контракта",
                            type: "CHECKBOX",

                            getter: p => p.contractProvision.used,
                            setter: (p, v: boolean) => {
                                p.contractProvision.used = v;
                            },
                        },
                        ...(readonly ? [] : [contractProvisionUnavailablePlaceholder]),
                        contractProvisionField,
                    ],
                },
            ],
        },
    };

    const guaranteeProvisionSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Обеспечение гарантии",
        component: FieldSection,
        visible: p => p.jointLotType !== JointLotType.JOINT_PURCHASE,

        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Взимается обеспечение исполнения гарантийных обязательств",
                            type: "CHECKBOX",

                            getter: p => p.guaranteeProvision.used,
                            setter: (p, v: boolean) => {
                                p.guaranteeProvision.used = v;
                            },
                        },
                        ...(readonly ? [] : [guaranteeProvisionUnavailablePlaceholder]),
                        guaranteeProvisionField,
                    ],
                },
            ],
        },
    };

    const criteriaDisabledOn = [
        ProviderSelectionType.E_AUC,
        ProviderSelectionType.E_QUOTATIONS_REQUEST,
        ProviderSelectionType.QUATATIONS_REQUEST,
        ProviderSelectionType.ONE_PROVID_PURCHASE,
    ];

    const criteriaSection: ISection<Lot> = {
        type: "TABLE",
        name: "Критерии оценки",
        component: TableSection,
        visible: p =>
            !criteriaDisabledOn.includes(p.providerSelectionType!) && p.jointLotType !== JointLotType.JOINT_LOT,
        required: true,

        data: {
            headers: [
                {
                    title: "Название",
                    getter(v) { return v.name },
                },
                {
                    title: "Значимость",
                    getter(v) { return v.value },
                    size: "auto"
                },
                {
                    title: "Содержание",
                    getter(v) { return v.content ? "Задано" : "Не задано" },
                    size: "auto"
                },
                {
                    title: "Порядок оценки",
                    getter(v) { return v.assessmentOrder ? "Задано" : "Не задано" },
                    size: "auto"
                }
            ],
            getter(p) { return (p.criteria || []).flatMap(x => [x, ...x.subcriteria]) }
        } as TableSectionData<Lot, Subcriteria> as never,
    };

    const multiLotCheckField: IRow<Lot> = {
        title: "Данный лот должен быть включен в совместный лот",
        type: "CHECKBOX",
        readonly: true,
        visible: p => p.jointLotType !== JointLotType.JOINT_PURCHASE,

        getter: p => p.jointLotType === JointLotType.JOINT_LOT,
        setter: (p, v: boolean) => (p.jointLotType = v ? JointLotType.JOINT_LOT : JointLotType.NONE),
    };

    const isSvcProductRequired: IRow<Lot> = {
        title: "Для оказания услуг используется товар",
        type: "CHECKBOX",
        visible: (p) => p.contractType === LotContractType.DO_SERVICES,

        getter: p => p.isProductUsed,
        setter: (p, v: boolean) => (p.isProductUsed = v),
    };

    const isJobProductRequired: IRow<Lot> = {
        title: "Для выполнения работ используется товар",
        type: "CHECKBOX",
        visible: (p) => p.contractType === LotContractType.WORK_COMPLETES,

        getter: p => p.isProductUsed,
        setter: (p, v: boolean) => (p.isProductUsed = v),
    };

    const generalSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Общие сведения",
        component: FieldSection,
        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Закон-основание",
                            type: "TEXT",
                            editable: false,

                            getter: p => (p.law === LawType.F44 ? "44-ФЗ" : "223-ФЗ"),
                            setter: (p, v: LawType) => (p.law = v),
                        },
                        {
                            title: "ИКЗ",
                            type: "TEXT",
                            editable: false,
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.purchaseId,
                        },
                        {
                            title: "Заказчик",
                            type: "TEXT",
                            editable: false,
                            required: true,

                            getter: () => Auth.currentUserInfo && Auth.currentUserInfo.selectedParticipant.fullName
                        },
                        {
                            title: "Тип контракта",
                            type: "SELECT",
                            required: true,

                            selectOptions: toSelectOptions(LotContractTypeStrings, true, "Выберите тип контракта"),

                            getter: p => p.contractType,
                            setter: (p, v) => p.contractType = v,
                            validate: p => nonNull(p.contractType)
                        },
                        isJobProductRequired, isSvcProductRequired,
                        {
                            title: "Способ определения поставщика",
                            type: "SELECT",
                            required: true,

                            selectOptions: toSelectOptions(ProviderSelectionTypeStrings, true, "Выберите способ определения поставщика"),

                            getter: p => p.providerSelectionType,
                            setter: (p, v: ProviderSelectionType) => (p.providerSelectionType = v),
                            validate: p => nonNull(p.providerSelectionType)
                        },
                        {
                            title: "Основание заключения договора с единственным поставщиком",
                            type: "SELECT",
                            required: true,
                            selectOptions: singleProviderReasonOptions(),

                            visible(p) { return p.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE },

                            getter: p => p.singleProviderReason && p.singleProviderReason.name,
                            setter: (p, v: string) => {}
                        },
                        {
                            title: "Обоснование способа определения поставщика",
                            type: "MULTILINE_TEXT",
                            required: true,
                            visible(p) { return p.law === LawType.F44 || p.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE },

                            getter: p => p.providerSelectionTypeExplanation,
                            setter: (p, v: string) => (p.providerSelectionTypeExplanation = v),
                            validate: p => nonEmpty(p.providerSelectionTypeExplanation)
                        },
                        {
                            visible: p => !readonly && p.law === LawType.F44,
                            title: "Файл-обоснование способа определения поставщика",
                            type: "FILE",
                            required: true,
                            fieldDescription:
                                "Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, " +
                                "jpg, jpeg, zip, rar.\nМаксимальный размер файла - 10 МБ",

                            getter: () => "",
                            setter: () => {},
                        },
                        {
                            title: "Предмет закупки",
                            type: "MULTILINE_TEXT",
                            required: true,
                            hint: {
                                text:
                                    "Поле должно содержать развернутое описание закупаемых работ/товаров/услуг Пример: - Поставка бумаги для нужд Департамента градостроительной политики города Москвы на первое полугодие 2016 года - Услуги почты России на 2016 год - Системное обслуживание информационно-вычислительных комплексов",
                            },
                            getter: p => p.subject,
                            setter: (p, v: string) => (p.subject = v),
                            validate: p => nonEmpty(p.subject)
                        },
                        {
                            title: p => p.law === LawType.F44
                                ? "Планируемая дата публикации"
                                : "Планируемый срок публикации",
                            type: "MONTH_FIELD",
                            style: "SHORT",
                            required: true,
                            visible(p) {
                                return p.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE &&
                                    p.law === LawType.F44 || !p.isPublishDatePrecise;
                            },

                            getter: p => p.plannedPublishDate,
                            setter: (p, v: Date) => p.plannedPublishDate = v,
                            validate: p => nonNull(p.plannedPublishDate)
                        },
                        {
                            title: "Планируемая дата публикации",
                            type: "DATE_FIELD",
                            style: "SHORT",
                            required: true,
                            visible(p) { return p.providerSelectionType !== ProviderSelectionType.ONE_PROVID_PURCHASE && p.isPublishDatePrecise },

                            getter: p => p.plannedPublishDate,
                            setter: (p, v: Date) => p.plannedPublishDate = v,
                            validate: p => nonNull(p.plannedPublishDate)
                        },
                        {
                            title: "Планируемая дата заключения контракта",
                            type: "MONTH_FIELD",
                            style: "SHORT",
                            required: true,
                            visible(p) { return p.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE },

                            getter: p => p.plannedPublishDate,
                            setter: (p, v: Date) => p.plannedPublishDate = v,
                            validate: p => nonNull(p.plannedPublishDate)
                        },
                        {
                            title: "Поставщик",
                            type: "TEXT",
                            editable: false,

                            getter: p => p.provider && p.provider.shortName,
                            setter: () => {}
                        },
                        {
                            title: "Срок исполнения контракта",
                            type: "MONTH_FIELD",
                            style: "SHORT",
                            required: true,

                            getter: p => p.contractTerms,
                            setter: (p, v: Date) => p.contractTerms = v,
                            validate: p => nonNull(p.contractTerms)
                        },
                        multiLotCheckField,
                        multiLotFields.orgType,
                        multiLotFields.org,
                        {
                            title: "Совместная закупка",
                            type: "CHECKBOX",
                            readonly: true,

                            getter: p => p.jointLotType === JointLotType.JOINT_PURCHASE,
                            setter: (p, v: boolean) =>
                                (p.jointLotType = v ? JointLotType.JOINT_PURCHASE : JointLotType.NONE),
                        },
                        {
                            title: "Закупка относится к капитальному ремонту объектов капитального строительства",
                            type: "CHECKBOX",

                            getter: p => p.isRepairRelated,
                            setter: (p, v: boolean) => (p.isRepairRelated = v),
                        },
                        {
                            title: "Закупка услуг по проведению обязательного аудита в соответствии с 307-ФЗ",
                            type: "CHECKBOX",

                            getter: p => p.isServicePurchaseAccordingToF233,
                            setter: (p, v: boolean) => (p.isServicePurchaseAccordingToF233 = v),
                        },
                    ],
                },
            ],
        },
    };

    const additSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Дополнительные сведения",
        component: FieldSection,
        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Преимущества учреждениям УИС (%)",
                            type: "DECIMAL",
                            editable: true,
                            style: "SHORT",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.advantagesForUis,
                            setter: (p, v: Decimal) => (p.additionalInfo.advantagesForUis = v),
                        },
                        {
                            title: "Преимущества организациям инвалидов (%)",
                            type: "DECIMAL",
                            editable: true,
                            style: "SHORT",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.advantagesForDisabledPeopleOrganizations,
                            setter: (p, v: Decimal) => (p.additionalInfo.advantagesForDisabledPeopleOrganizations = v),
                        },
                        {
                            title: "Размер аванса (%)",
                            type: "DECIMAL",
                            editable: true,
                            style: "SHORT",

                            getter: p => p.additionalInfo.prepaidExpense,
                            setter: (p, v: Decimal) => (p.additionalInfo.prepaidExpense = v),
                        },
                        {
                            title: "Условия запрета допуска иностранных товаров",
                            type: "MULTILINE_TEXT",
                            editable: true,
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.foreignProductsForbidConditions,
                            setter: (p, v: string) => (p.additionalInfo.foreignProductsForbidConditions = v),
                        },
                        {
                            title: "Объём иностранных товаров",
                            type: "DECIMAL",
                            editable: true,
                            style: "SHORT",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.foreignProductsAmount,
                            setter: (p, v: Decimal) => (p.additionalInfo.foreignProductsAmount = v),
                        },
                        {
                            title:
                                "Товары, произведенные на территории государств - членов Евразийского экономического союза; преимущества предоставляются при условии соответствия пунктам 3, 4, 5, 6, 7 приказа Минэкономразвития от 25 марта 2014 г. № 155",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.isEeuProduction,
                            setter: (p, v: boolean) => (p.additionalInfo.isEeuProduction = v),
                        },
                        {
                            title:
                                "Применение запрета для товаров, входящих в Перечень сельскохозяйственной продукции, сырья и продовольствия, страной происхождения которых является Турецкая Республика и которые запрещены с 1 января 2016 г. к ввозу в Российскую Федерацию (утверждено постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1296)",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banTurkishProduction,
                            setter: (p, v: boolean) => (p.additionalInfo.banTurkishProduction = v),
                        },
                        {
                            title:
                                "Применение запрета для товаров, входящих в Перечень отдельных видов товаров машиностроения, происходящих из иностранных государств, в соответствии с постановлением Правительства Российской Федерации от 14 июля 2014 г. № 656",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignMachines,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignMachines = v),
                        },
                        {
                            title:
                                "Применение запрета на допуск отдельных видов товаров легкой промышленности, происходящих из иностранных государств, в соответствии с постановлением Правительства Российской Федерации от 11 августа 2014 г. N 791",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignLightProduction,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignLightProduction = v),
                        },
                        {
                            title:
                                "Применение запрета на допуск программ для электронных вычислительных машин и баз данных, реализуемых независимо от вида договора на материальном носителе и (или) в электронном виде по каналам связи, происходящих из иностранных государств, а также исключительных прав на такое программное обеспечение и прав использования такого программного обеспечения, в соответствии с положениями постановления Правительства Российской Федерации от 16 ноября 2015 г. № 1236",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignComputingProduction,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignComputingProduction = v),
                        },
                        {
                            title:
                                "Применение ограничений допуска отдельных видов пищевых продуктов, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 22 августа 2016 г. № 832",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignFood,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignFood = v),
                        },
                        {
                            title:
                                "Применение ограничений допуска отдельных видов медицинских изделий, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 05 февраля 2015 г. № 102",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignMedicines,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignMedicines = v),
                        },
                        {
                            title:
                                "Применение ограничений допуска отдельных видов лекарственных препаратов, включенных в Перечень жизненно необходимых и важнейших лекарственных препаратов и происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1289",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignImportantMedicines,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignImportantMedicines = v),
                        },
                        {
                            title:
                                "Об ограничениях и условиях допуска отдельных видов радиоэлектронной продукции, происходящих из иностранных государств, для целей осуществления закупок для обеспечения государственных и муниципальных нужд",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignElectronics,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignElectronics = v),
                        },
                        {
                            title:
                                "Применение запрета на допуск отдельных видов товаров мебельной и деревообрабатывающей промышленности, происходящих из иностранных государств, в соответствии с постановлением Правительства Российской Федерации от 5 сентября 2017 г. № 1072",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.banForeignFurniture,
                            setter: (p, v: boolean) => (p.additionalInfo.banForeignFurniture = v),
                        },
                        {
                            title:
                                "Предметом контракта является поставка товара, необходимого для нормального жизнеобеспечения в случаях, указанных в ч. 9 ст. 37 44-ФЗ",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.isLifeSupportProduction,
                            setter: (p, v: boolean) => (p.additionalInfo.isLifeSupportProduction = v),
                        },
                        {
                            title:
                                "Выполнение научно-исследовательских, опытно-конструкторских, технологических работ или оказание консультационных услуг",
                            type: "CHECKBOX",
                            visible(p) { return p.law === LawType.F44 },

                            getter: p => p.additionalInfo.isResearchServices,
                            setter: (p, v: boolean) => (p.additionalInfo.isResearchServices = v),
                        },
                    ],
                },
            ],
        },
    };

    const additProvidReqFields: { additProvidReq: IRow<Lot>; additProvidReqInfo: IRow<Lot> } = {
        additProvidReq: {
            title: "",
            type: "MULTILINE_TEXT",
            visible: (p) => p.additionalProviderRequirements.used,
            getter: p => (p.additionalProviderRequirements.used ? p.additionalProviderRequirements.requirements : null),
            setter: (p, v: string) =>
                p.additionalProviderRequirements.used ? (p.additionalProviderRequirements.requirements = v) : null,
        },
        additProvidReqInfo: {
            title: "Обоснование дополнительного требования",
            type: "MULTILINE_TEXT",
            visible: (p) => p.additionalProviderRequirements.used || p.additionalGovRequirements.used,

            getter: p => p.additionalRequirementExplanation,
            setter: (p, v: string) => (p.additionalRequirementExplanation = v),
        },
    };

    const additReqSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Дополнительные требования",
        component: FieldSection,
        data: {
            columnCount: 1,
            columns: [
                {
                    rows: [
                        {
                            title: "Дополнительные требования к поставщику",
                            type: "CHECKBOX",

                            getter: p => p.additionalProviderRequirements.used,
                            setter: (p, v: boolean) => {
                                p.additionalProviderRequirements.used = v;
                            },
                        },
                        additProvidReqFields.additProvidReq,
                        {
                            title:
                                "Дополнительные требования согласно Постановлению Правительства РФ от 4.02.2015 № 99",
                            type: "CHECKBOX",

                            getter: p => p.additionalGovRequirements.used,
                            setter: (p, v: boolean) => {
                                p.additionalGovRequirements.used = v;
                            },
                        },
                        additProvidReqFields.additProvidReqInfo,
                    ],
                },
            ],
        },
    };

    const productsInfoSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Сведения о товарах",
        component: FieldSection,
        visible: () => false, // (p) => p.providerSelectionType === ProviderSelectionType.E_OFFERS_REQUEST,

        data: {
            columnCount: 1,
            columns: [],
        },
    };

    const executiveInfoSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Информация об ответственном должностном лице и контрактной службе",
        component: FieldSection,

        data: {
            columnCount: 1,
            formStyle: FormStyle.VERTICAL,
            columns: [
                {
                    header: "Информация об ответственном должостном лице заказчика",
                    // todo: pick
                    rows: [
                        {
                            title: "Контактное лицо",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "Матерова Елена Викторовна",
                            setter: (p, v: boolean) => {},
                        },
                        {
                            title: "Телефон",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "+7 (495) 685-38-04",
                            setter: (p, v: string) => {},
                        },
                        {
                            title: "Электронная почта",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "sp11@zdrav.mos.ru",
                            setter: (p, v: string) => {},
                        },
                    ],
                },
                {
                    header: "Информация о контрактной службе/управляющем, ответственных за заключение контракта",
                    rows: [
                        {
                            title: "Контактное лицо",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "Матерова Елена Викторовна",
                            setter: (p, v: boolean) => {},
                        },
                        {
                            title: "Телефон",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "+7 (495) 685-38-04",
                            setter: (p, v: string) => {},
                        },
                        {
                            title: "Электронная почта",
                            type: "TEXT",
                            required: true,
                            editable: false,

                            getter: p => "sp11@zdrav.mos.ru",
                            setter: (p, v: string) => {},
                        },
                    ],
                },
            ],
        },
    };

    const termListSection: ISection<Lot> = {
        type: "UNTYPED",
        name: "Справочник терминов и определений",
        component: DictionarySection, // todo: не 223-ФЗ агрегированная
        isRawComponent: true,
        data: undefined,
    };

    const docsSection: ISection<Lot> = {
        type: "UNTYPED",
        name: "Документация",
        component: DocumentationSection,
        data: undefined,
    };

    const minimalRequirementsSection: ISection<Lot> = {
        type: "FIELDS",
        name: "Минимальные требования",
        component: FieldSection,
        visible: p => p.law === LawType.F223,

        data: {
            formStyle: FormStyle.VERTICAL,
            columnCount: 1,
            columns: [{
                rows: [
                    {
                        title: "Минимально необходимые требования, предъявляемые к закупаемым товарам, работам, услугам",
                        type: "MULTILINE_TEXT",
                        lines: 5,

                        getter(p) { return p.minimalProductRequirements },
                        setter(p, v) { return p.minimalProductRequirements = v }
                    }
                ]
            }]
        }
    };

    const form: IForm<Lot> = {
        header: "Новый лот",
        stats: undefined,
        formStyle: readonly ? FormStyle.VERTICAL : FormStyle.HORIZONTAL,
        actions(l: Lot) {
            if (readonly) {
                const createSubprocAction =
                    l.providerSelectionType === ProviderSelectionType.ONE_PROVID_PURCHASE
                        ? actionR("create-contract", "green", "Создать контракт")
                        : actionR("create-procedure", "green", "Создать процедуру");

                switch (l.status) {
                    case ObjectStatus.CREATING:
                        if (l.law === LawType.F44)
                            return merge(commonReadonlyActionsL, commonReadonlyActionsR,
                                actionR("send-to-grbs", "green", "Отправить на согласование в ГРБС"));
                        else
                            return merge(commonReadonlyActionsL, commonReadonlyActionsR,
                                actionR("approve", "green", "Утвердить"));
                    case ObjectStatus.GRBS_AGREEMENT_PENDING:
                        return merge(commonReadonlyNoEditActionsL, commonReadonlyActionsR,
                            actionR("grbs-agree", "orange", "Согласовать в ГРБС (тест)"),
                            actionR("cancel-grbs-agreement", "green", "Отозвать с согласования"));
                    case ObjectStatus.GRBS_AGREED:
                        return merge(commonReadonlyNoEditActionsL, commonReadonlyActionsR,
                            actionR("approve", "green", "Утвердить"));
                    case ObjectStatus.PUBLISHED:
                    case ObjectStatus.INCLUDED_IN_PLAN:
                        if (l.procedureId === null) {
                            return merge(
                                commonReadonlyNoEditActionsL,
                                commonReadonlyActionsR,
                                actionL("edit", "blue", classIcon("edit")),
                                createSubprocAction,
                                actionR("cancel", "red", "Отмена"));
                        } else {
                            return merge(
                                commonReadonlyNoEditActionsL,
                                commonReadonlyActionsR,
                                actionL("edit", "blue", classIcon("edit")));
                        }
                    case ObjectStatus.APPROVED:
                        if (l.procedureId === null) {
                            return merge(
                                commonReadonlyActionsL,
                                commonReadonlyActionsR,
                                createSubprocAction);
                        } else {
                            return merge(
                                commonReadonlyNoEditActionsL,
                                commonReadonlyActionsR,
                                actionL("edit", "blue", classIcon("edit")));
                        }
                    case ObjectStatus.CANCELED:
                        return merge(commonReadonlyNoEditActionsL, commonReadonlyActionsR,
                            actionL("edit", "blue", classIcon("edit")));
                    default:
                        return merge(commonReadonlyActionsL, commonReadonlyActionsR);
                }
            } else {
                return [
                    actionL("save", "blue", classIcon("save"), x => x.isFilled),
                    actionR("close", "red", classIcon("close"))
                ];
            }
        },
        pages: [{
            id: "main",
            showNavigationHeader: true,
            sections: [
                generalSection,
                {
                    type: "UNTYPED",
                    name: "НМЦ",
                    component: StartPriceSection,
                    data: undefined,
                },
                {
                    type: "UNTYPED",
                    name: "Спецификации",
                    component: SpecificationsSection,
                    data: undefined,
                },
                minimalRequirementsSection,
                requestProvisionSection,
                contractProvisionSection,
                guaranteeProvisionSection,
                criteriaSection,
                additSection,
                productsInfoSection,
                termListSection,
                additReqSection,
                docsSection,
                executiveInfoSection, {
                    type: "UNTYPED",
                    name: "История изменений",
                    component: ChangeHistorySection,
                    data: undefined,
                    visibleInNavPanel: false
                }
            ]
        }],
    };

    return form;
}
