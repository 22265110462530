











































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import NewPriceSection from "@/views/form-renderers/NewPriceSection";
import { Specification, MarketPrice } from '../../../models';
import { PaginationContext, createPaginationContext } from '../../../api/Pagination';
import { MarketPriceDataSourceStrings, MarketPriceDataSource } from '../../../models/enums';
import MarketPrices from '../../../api/MarketPrices';
import { formatDate } from '../../../DateFormatting';
import { formatNumber } from '../../../NumberFormatting';
import smoothScroll from '../../../components/SmoothScroll';
import Decimal from 'decimal.js';
import { ITableColumn, IRowConfiguration, MultiSelection } from '../../../components/TableAbstractions';
import { containsFilter, dateFilter, decimalFilter } from '../../../components/TableHelpers';

@Component({ components: { NewPriceSection } })
export default class MarketPricesPage extends Vue {
    @Prop() public specification!: Specification;
    @Prop() public scroll!: Element;

    public isAddOfferCardVisible = false;

    public calculatedCurrency: string | null = null;
    public calculatedValue: Decimal | null = null;
    public calculationError: string | null = null;
    public isCalculationErrorCritical = false;

    public showFilters = {
        PROVIDER_OFFER: false,
        PROVIDER_PORTAL: false,
        MARKET_PRICE: false
    };

    public get calculatedValueStr(): string | null {
        return this.calculatedValue ? formatNumber(this.calculatedValue) : null;
    }

    public calculate() {
        if (this.pricesSelection.items.length < 3) {
            this.calculationError = "Должно быть выбрано не менее трех цен!";
            this.isCalculationErrorCritical = true;
            return;
        }

        this.calculationError = null;
        this.isCalculationErrorCritical = false;

        const len = this.pricesSelection.items.length;
        for (let i = 0; i < len; i++) {
            const lhs = this.pricesSelection.items[i];

            for (let j = i + 1; j < len; j++) {
                const rhs = this.pricesSelection.items[j];

                const div = lhs.priceWithTax.div(rhs.priceWithTax);
                if (div.gt(1.25) || div.lt(0.8)) {
                    this.calculationError = "Выбранные для расчета цены отличаются более чем на 25%";
                }
            }
        }

        this.calculatedCurrency = "Российский рубль";
        this.calculatedValue = this.pricesSelection.items
            .reduce((p, x) => p.add(x.priceWithTax), new Decimal(0))
            .div(this.pricesSelection.items.length)
            .toNearest(0.01, Decimal.ROUND_DOWN)
            .mul(this.specification!.volume);
    }

    public providerOffers: PaginationContext<MarketPrice> | null = null;
    public marketPrices: PaginationContext<MarketPrice> | null = null;
    public providerPortals: PaginationContext<MarketPrice> | null = null;

    public pricesSelection: MultiSelection<MarketPrice> = { tag: "MULTI", items: [] };

    public created() {
        if (!this.specification.subjectDeclaration) {
            return;
        }

        createPaginationContext((n, sz, f) =>
            MarketPrices.get(n, sz, MarketPriceDataSource.PROVIDER_OFFER, this.specification.subjectDeclaration!.id, f), 10)
            .then(x => this.providerOffers = x);


        createPaginationContext((n, sz, f) =>
            MarketPrices.get(n, sz, MarketPriceDataSource.MARKET_PRICE, this.specification.subjectDeclaration!.id, f))
            .then(x => this.marketPrices = x);

        createPaginationContext((n, sz, f) =>
            MarketPrices.get(n, sz, MarketPriceDataSource.PROVIDER_PORTAL, this.specification.subjectDeclaration!.id, f))
            .then(x => this.providerPortals = x);
    }

    public marketPricesTableConfig: IRowConfiguration<MarketPrice> = {
        selection: {
            showSelectionColumn: true
        }
    };

    public marketPricesHeaders: ITableColumn<MarketPrice>[] = [
        {
            title: "Наименование позиции",
            getter: v => v.name,
            filter: containsFilter("name")
        },
        {
            title: "Цена за единицу с НДС",
            getter: v => formatNumber(v.priceWithTax),
            filter: decimalFilter("price")
        },
        {
            title: "Источник данных",
            getter: v => MarketPriceDataSourceStrings[v.dataSource]
        },
        {
            title: "Поставщик",
            getter: v => v.producer ? v.producer.fullName : v.producerName,
            filter: containsFilter("producerName")
        },
        {
            title: "Дата начала действия",
            getter: v => formatDate(v.startDate, true),
            filter: dateFilter("startDate")
        },
        {
            title: "Дата окончания действия",
            getter: v => formatDate(v.expirationDate, true),
            filter: dateFilter("expirationDate")
        },
    ];

    public addMarketPrice() {
        this.isAddOfferCardVisible = true;

        smoothScroll((this.$refs.addOfferCard as HTMLElement).offsetTop - 100, this.scroll);
    }

    public saveMarketPrice(marketPrice: MarketPrice) {
        if (this.providerOffers) {
            this.providerOffers.items.splice(0, 0, marketPrice);
            if (this.providerOffers.items.length > this.providerOffers.pageSize) {
                this.providerOffers.items.splice(this.providerOffers.pageSize - 1, 1);
            }

            this.providerOffers.totalCount = this.providerOffers.totalCount + 1;
        }
    }

    public hideOfferForm() {
        this.isAddOfferCardVisible = false;
    }

    public save() {
        this.$emit("set-market-prices-result", this.calculatedValue);
        this.$emit("switch-page", "COMMON");
    }

    public showAll(type: MarketPriceDataSource) {
        const pageSize = 1000000;
        let alreadyEnabled;

        switch (type) {
            case MarketPriceDataSource.PROVIDER_PORTAL: alreadyEnabled = this.providerPortals!.pageSize === pageSize; break;
            case MarketPriceDataSource.PROVIDER_OFFER: alreadyEnabled = this.providerOffers!.pageSize === pageSize; break;
            case MarketPriceDataSource.MARKET_PRICE: alreadyEnabled = this.marketPrices!.pageSize === pageSize; break;
        }

        this.specification.subjectDeclaration && createPaginationContext((n, sz, f) =>
            MarketPrices.get(n, sz, type, this.specification.subjectDeclaration!.id, f), alreadyEnabled ? undefined : pageSize)
            .then(x => {
                switch (type) {
                    case MarketPriceDataSource.PROVIDER_PORTAL: this.providerPortals = x; break;
                    case MarketPriceDataSource.PROVIDER_OFFER: this.providerOffers = x; break;
                    case MarketPriceDataSource.MARKET_PRICE: this.marketPrices = x; break;
                }
            });
    }
}
