import { Component, Prop, Vue } from "vue-property-decorator";
import HeadTitle from "@/components/HeadTitle.vue";
import SingleTableView from "@/components/SingleTableView.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import router from "@/router";
import LawSelectionModal from "@/views/modals/LawSelectionModal";
import EventBus from "@/EventBus";
import {
    LawType,
    LotStatusStrings,
    ProviderSelectionTypeStrings,
    LawTypeStrings,
    SmpTypeStrings,
} from "@/models/enums";
import { Lot } from "@/models/Lot";
import {
    PaginationContext,
    createPaginationContext,
} from "@/api/Pagination";
import ApiLots from "@/api/Lots";
import { formatDate } from "@/DateFormatting";
import { formatNumber } from '@/NumberFormatting';
import { containsFilter, selectFilter } from '@/components/TableHelpers';

@Component({ components: { SingleTableView, HeadTitle } })
export default class Lots extends Vue {
    public itemActions: unknown[] = [
        {
            id: "edit",
            title: "Редактировать",
        },
    ];

    public headers: ITableColumn<Lot>[] = [
        {
            title: "Реестровый номер",
            getter: x => x.regNumber,
            url: x => `/plan-objects/lots/${x.id}`,
            filter: containsFilter("regNumber")
        },
        {
            title: "Закон-основание",
            getter: x => (x.law === "F44" ? "44-ФЗ" : "223-ФЗ"),
            filter: selectFilter("law", LawTypeStrings, false)
        },
        {
            title: "ИКЗ",
            getter: x => x.purchaseId,
        },
        {
            title: "Предмет закупки",
            getter: x => x.subject,
            filter: containsFilter("subject")
        },
        {
            title: "НМЦ",
            getter: x => formatNumber(x.totalStartPrice),
        },
        {
            title: "Способ определения поставщика",
            getter: x => ProviderSelectionTypeStrings[x.providerSelectionType!],
            filter: selectFilter("providerSelectionType", ProviderSelectionTypeStrings, false)
        },
        {
            title: "Дата начала закупки",
            getter: x => formatDate(x.plannedPublishDate!),
        },
        {
            title: "Размещение среди СМП/МСП",
            getter: x => SmpTypeStrings[x.smpType],
        },
        {
            title: "Статус",
            getter: x => LotStatusStrings[x.status],
            filter: selectFilter("status", LotStatusStrings, true)
        },
        {
            title: "Номер повторной закупки",
            getter: x => "0",
        },
    ];

    public async addObject() {
        const result = await EventBus.callModal(LawSelectionModal, null);
        if (result.isOk) {
            switch (result.getResult()) {
                case LawType.F44:
                    router.push("/plan-objects/create44Lot");
                    break;
                case LawType.F223:
                    router.push("/plan-objects/create223Lot");
                    break;
            }
        }
    }

    public context: PaginationContext<Lot> | null = null;

    public async mounted() {
        this.context = await createPaginationContext((n, sz, f) => ApiLots.get(n, sz, f), 20);
    }

    public async onItemAction(item: {id: string}, action: string) {
        switch (action) {
            case "edit":
                router.push(`/plan-objects/lots/${item.id}/edit`);
                break;
            /*case 'delete':
                const result = await EventBus.callModal<IObjectDeletionModalArgs, boolean>(ObjectDeletionModal, {
                    title: "Удаление",
                    description: "При удалении объекта закупки будут удалены все связанные с ним детализированные объекты закупок и лоты, продолжить?"
                })
                if (result.isOk && result.getResult()) {
                    ApiLots.delete(item.id)
                        .then(() => this.load(0, true))
                }
                break*/
        }
    }
}
